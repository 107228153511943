import React from 'react';
import styled from 'styled-components';
import { Error } from '@progress/kendo-react-labels';
import { Typography } from '@progress/kendo-react-common';
import { Skeleton } from '@progress/kendo-react-indicators';
import { Icon } from '../../components/layout';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type textFieldFn = (item: any) => string;

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any[];
  dataItemKey: string;
  textField: string | textFieldFn;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value?: any;
  error?: string;
  loading?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange: (selectedItem: any) => void;
}

const Card = styled.div`
  display: flex;
  background: var(--geosap-card-bg-color);
  border-radius: 0.6rem;
  border: 1px solid var(--geosap-card-bg-color);
  cursor: pointer;
  height: 12em;

  &:hover,
  &.selected {
    border-color: var(--geosap-primary-color);
  }
`;

const IconBadge = styled.div`
  background: var(--geosap-light);
  border-radius: 50%;
`;

const CardSelector: React.FC<Props> = ({ data, dataItemKey, textField, value, error, loading, onChange }) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const selectCard = (selectedItem: any) => {
    onChange(selectedItem);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const isCardSelected = (item: any) => value && value[dataItemKey] === item[dataItemKey];

  if (loading) {
    return (
      <div className="row g-3 mt-4">
        {Array(4)
          .fill(<Skeleton shape="rectangle" style={{ width: '100%', height: '10rem' }} />)
          .map((el, index) => (
            <div key={index} className="col-xs-12 col-lg-3">
              {el}
            </div>
          ))}
      </div>
    );
  }

  if (!data.length) {
    return (
      <div className="row g-3 mt-4">
        <Card className="col-xs-12 py-5 justify-content-center">
          <Typography.h4>No data available</Typography.h4>
        </Card>
      </div>
    );
  }

  return (
    <div className="row g-3 mt-4">
      {data.map((item) => (
        <div className="col-xs-12 col-lg-3" key={item[dataItemKey]}>
          <Card onClick={() => selectCard(item)} className={isCardSelected(item) ? 'selected' : undefined}>
            <Typography.h3 className="p-4 pb-5 pe-0 flex-grow-1 text-break">
              {typeof textField === 'string' ? item[textField] : textField(item)}
            </Typography.h3>
            <div className="text-end p-3 ps-0 d-flex flex-column justify-content-end">
              {isCardSelected(item) ? (
                <Icon iconClass="k-i-plus-circle" size="40px" color="var(--geosap-primary-color)" />
              ) : (
                <IconBadge>
                  <Icon iconClass="k-i-plus" size="40px" color="var(--geosap-primary-color)" />
                </IconBadge>
              )}
            </div>
          </Card>
        </div>
      ))}
      {error && (
        <div className="col-xs-12">
          <Error className="text-center">{error}</Error>
        </div>
      )}
    </div>
  );
};

export default CardSelector;
