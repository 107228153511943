import { SapFlowConfigProcessNode, SapFlowConfigItemNode } from './SapFlowConfig';

export interface SapFlowConfigType {
  id: string;
  name: string;
}

export interface SapFlowTransaction {
  id: string;
  sapflowname: string;
  status: string;
  createdDate: string;
  project: {
    id: string;
    name: string;
  };
  types: SapFlowConfigType[];
  processnodes: SapFlowConfigProcessNode[];
  itemnodes: SapFlowConfigItemNode[];
  statusHistory: SapFlowTransactionStatus[];
}

export enum SapFlowTransactionItemStatus {
  TRANSACTION_CREATED = 'TRANSACTION_CREATED',
  TRANSACTION_READY = 'TRANSACTION_READY',
  TRANSACTION_STARTED = 'TRANSACTION_STARTED',
  TRANSACTION_IN_PROCESS = 'TRANSACTION_IN_PROCESS',
  TRANSACTION_FINISHED = 'TRANSACTION_FINISHED',
  TRANSACTION_ERRORED = 'TRANSACTION_ERRORED',
  TRANSACTION_TIMED_OUT = 'TRANSACTION_TIMED_OUT',
}

export enum SapFlowProcessStatus {
  CREATED,
  STARTED,
  COMPLETED,
  ERRORED,
  IN_PROGRESS,
}

export interface SapFlowTransactionItem {
  id: string;
  name: string;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  status: SapFlowTransactionItemStatus;
  fileformat: string;
  group: boolean;
  type: string;
}
export interface SapFlowTransactionStatus {
  id: string;
  statusTime: string;
  statusMsg: string;
  transactionStatus: SapFlowTransactionItemStatus;
  transactionPercentComplete: number;
  transactionStartTime: string;
  currentProcessCount: number;
  currentProcessPercentComplete: number;
  currentProcessRuntime: number;
  currentProcessStatus: SapFlowProcessStatus;
  totalProcessCount: number;
  totalTransactionRuntime: number;
}

export interface RequiredFileStatus {
  ProvisionType: 'Upload' | 'Reuse';
  Uploaded: boolean;
  FileToReuse: any;
}
