import { useQuery } from 'react-query';
import { SapFlowViewConfig, LayerType } from '../../types';
import { useAxiosInstance } from '../common';

const endpoint = 'project/viewer_config';

const dummyData: SapFlowViewConfig = {
  createdBy: '',
  createdDate: '',
  lastModifiedBy: '',
  lastModifiedDate: '',
  id: '123',
  pageName: 'Burke Mountain Snow Depth',
  pageDescription: 'Burke Mountain Training Hill. Change Detection across multiple LiDAR collections',
  pointBudget: 10000000,
  visiblePanels: null,
  layers: [
    {
      id: 'd8d338ee-dd13-4afa-a21d-032528dc29ce',
      displayName: 'December 1 2021',
      active: true,
      layerType: LayerType.OctreeBin,
      uri: 'https://whiteoutsolutions.s3.amazonaws.com/octree-data/BurkeMountainTrainingHill/pointclouds/12012021/metadata.json',
    },
  ],
};

const dummyData2: SapFlowViewConfig = {
  createdBy: '',
  createdDate: '',
  lastModifiedBy: '',
  lastModifiedDate: '',
  id: '123',
  pageName: 'K42',
  pageDescription: 'K42 Segmentation with tree classification.',
  pointBudget: 10000000,
  visiblePanels: null,
  cameraPosition: [1496000.8666, 884142.8473, 209.3778],
  cameraTarget: [1495814.4215099278, 883608.7099333499, 161.01398975590354],
  project: {
    id: '123',
    name: 'DUMMY DATA',
  },
  layers: [
    {
      id: 'd8d338ee-dd13-4afa-a21d-032528dc29ce',
      uri: 'https://whiteoutsolutions.s3.amazonaws.com/octree-data/K42/metadata.json',
      layerType: LayerType.OctreeBin,
      displayName: 'K42 Side Classification',
      active: true,
    },
    {
      id: '1232444442',
      uri: 'https://whiteoutsolutions.s3.amazonaws.com/octree-data/K42/shapefiles/K42_clip_segmented.shp',
      layerType: LayerType.Shapefile,
      displayName: 'K42 Segmentation',
      active: false,
    },
    {
      id: '1232444442',
      uri: 'https://whiteoutsolutions.s3.amazonaws.com/octree-data/K42/shapefiles/section127.shp',
      layerType: LayerType.Shapefile,
      displayName: 'K42 Power Line 1',
      active: true,
    },
    {
      id: '1232444442',
      uri: 'https://whiteoutsolutions.s3.amazonaws.com/octree-data/K42/shapefiles/section128.shp',
      layerType: LayerType.Shapefile,
      displayName: 'K42 Power Line 2',
      active: true,
    },
    {
      id: '1232444443',
      uri: 'https://whiteoutsolutions.s3.amazonaws.com/octree-data/K42/shapefiles/mvcd_poly.shp',
      layerType: LayerType.Shapefile,
      displayName: 'K42 Bounding',
      active: false,
    },
  ],
  classificationSchemas: [
    {
      createdBy: '00000000-0000-0000-0000-000000000000',
      createdDate: '2022-10-19 22:24:55',
      lastModifiedBy: '00000000-0000-0000-0000-000000000000',
      lastModifiedDate: '2022-10-25 17:27:40',
      id: 'f5156ca9-7152-4776-a0fe-5d24eba20114',
      name: 'NewGrowInTreeClassification',
      description: 'New GrowIn Tree Classification',
      classifications: [
        {
          code: 5,
          name: 'High Vegetation',
          description: 'High vegetation object',
          color: [0.7529312, 0.7529312, 0.7529312, 1.0],
        },
        {
          code: 53,
          name: 'Grow In MVCD',
          description: 'Vegetation object that has a transmission line Grow In potential at MVCD',
          color: [1.0, 0.5686275, 0.0, 1.0],
        },
        {
          code: 54,
          name: 'Grow In MVCD+5',
          description: 'Vegetation object that has a transmission line Grow In potential at MVCD+5',
          color: [1.0, 0.9019608, 0.0, 1.0],
        },
        {
          code: 55,
          name: 'Grow In MVCD+10',
          description: 'Vegetation object that has a transmission line Grow In potential at MVCD+10',
          color: [0.8039216, 1.0, 0.0, 1.0],
        },
      ],
    },
    {
      createdBy: '00000000-0000-0000-0000-000000000000',
      createdDate: '2022-10-19 22:24:55',
      lastModifiedBy: '00000000-0000-0000-0000-000000000000',
      lastModifiedDate: '2022-10-25 17:27:40',
      id: '40b57c8d-0ac3-434a-8f2b-a0d1efcc9fe7',
      name: 'SevereFallInTreeClassification',
      description: 'Severe FallIn Tree Classification',
      classifications: [
        {
          code: 5,
          name: 'High Vegetation',
          description: 'High vegetation object',
          color: [0.7529312, 0.7529312, 0.7529312, 1.0],
        },
        {
          code: 49,
          name: 'Fall In Overshoot',
          description: 'Vegetation object that has a transmission line Overshoot potential',
          color: [1.0, 0.0, 0.0, 1.0],
        },
        {
          code: 50,
          name: 'Fall In MVCD',
          description: 'Vegetation object that has a transmission line Fall In potential at MVCD',
          color: [1.0, 0.5686275, 0.0, 1.0],
        },
        {
          code: 51,
          name: 'Fall In MVCD+5',
          description: 'Vegetation object that has a transmission line Fall In potential at MVCD+5',
          color: [1.0, 0.9019608, 0.0, 1.0],
        },
        {
          code: 52,
          name: 'Fall In MVCD+10',
          description: 'Vegetation object that has a transmission line Fall In potential at MVCD+10',
          color: [0.8039216, 1.0, 0.0, 1.0],
        },
      ],
    },
    {
      createdBy: '00000000-0000-0000-0000-000000000000',
      createdDate: '2022-10-19 22:24:55',
      lastModifiedBy: '00000000-0000-0000-0000-000000000000',
      lastModifiedDate: '2022-10-25 17:27:40',
      id: '8c334bdf-246e-4042-8557-4a7b63e211b5',
      name: 'FallInTreeClassification',
      description: 'FallIn Tree Classification',
      classifications: [
        {
          code: 5,
          name: 'High Vegetation',
          description: 'High vegetation object',
          color: [0.7529312, 0.7529312, 0.7529312, 1.0],
        },
        {
          code: 49,
          name: 'Fall In Overshoot',
          description: 'Vegetation object that has a transmission line Overshoot potential',
          color: [1.0, 0.0, 0.0, 1.0],
        },
        {
          code: 50,
          name: 'Fall In MVCD',
          description: 'Vegetation object that has a transmission line Fall In potential at MVCD',
          color: [1.0, 0.5686275, 0.0, 1.0],
        },
        {
          code: 51,
          name: 'Fall In MVCD+5',
          description: 'Vegetation object that has a transmission line Fall In potential at MVCD+5',
          color: [1.0, 0.9019608, 0.0, 1.0],
        },
        {
          code: 52,
          name: 'Fall In MVCD+10',
          description: 'Vegetation object that has a transmission line Fall In potential at MVCD+10',
          color: [0.8039216, 1.0, 0.0, 1.0],
        },
      ],
    },
    {
      createdBy: '00000000-0000-0000-0000-000000000000',
      createdDate: '2022-10-19 22:24:55',
      lastModifiedBy: '00000000-0000-0000-0000-000000000000',
      lastModifiedDate: '2022-10-25 17:27:40',
      id: '30a64fee-9237-44ed-9949-d10c1875170f',
      name: 'NewFallInTreeClassification',
      description: 'New FallIn Tree Classification',
      classifications: [
        {
          code: 5,
          name: 'High Vegetation',
          description: 'High vegetation object',
          color: [0.7529312, 0.7529312, 0.7529312, 1.0],
        },
        {
          code: 49,
          name: 'Fall In Overshoot',
          description: 'Vegetation object that has a transmission line Overshoot potential',
          color: [1.0, 0.0, 0.0, 1.0],
        },
        {
          code: 50,
          name: 'Fall In MVCD',
          description: 'Vegetation object that has a transmission line Fall In potential at MVCD',
          color: [1.0, 0.5686275, 0.0, 1.0],
        },
        {
          code: 51,
          name: 'Fall In MVCD+5',
          description: 'Vegetation object that has a transmission line Fall In potential at MVCD+5',
          color: [1.0, 0.9019608, 0.0, 1.0],
        },
        {
          code: 52,
          name: 'Fall In MVCD+10',
          description: 'Vegetation object that has a transmission line Fall In potential at MVCD+10',
          color: [0.8039216, 1.0, 0.0, 1.0],
        },
      ],
    },
    {
      createdBy: '00000000-0000-0000-0000-000000000000',
      createdDate: '2022-10-19 22:24:55',
      lastModifiedBy: '00000000-0000-0000-0000-000000000000',
      lastModifiedDate: '2022-10-25 17:27:40',
      id: '4272ce47-9c27-4198-b6e7-26f4ed68d1c0',
      name: 'SevereGrowInTreeClassification',
      description: 'Severe GrowIn Tree Classification',
      classifications: [
        {
          code: 5,
          name: 'High Vegetation',
          description: 'High vegetation object',
          color: [0.7529312, 0.7529312, 0.7529312, 1.0],
        },
        {
          code: 53,
          name: 'Grow In MVCD',
          description: 'Vegetation object that has a transmission line Grow In potential at MVCD',
          color: [1.0, 0.5686275, 0.0, 1.0],
        },
        {
          code: 54,
          name: 'Grow In MVCD+5',
          description: 'Vegetation object that has a transmission line Grow In potential at MVCD+5',
          color: [1.0, 0.9019608, 0.0, 1.0],
        },
        {
          code: 55,
          name: 'Grow In MVCD+10',
          description: 'Vegetation object that has a transmission line Grow In potential at MVCD+10',
          color: [0.8039216, 1.0, 0.0, 1.0],
        },
      ],
    },
    {
      createdBy: '00000000-0000-0000-0000-000000000000',
      createdDate: '2022-10-19 22:24:55',
      lastModifiedBy: '00000000-0000-0000-0000-000000000000',
      lastModifiedDate: '2022-10-25 17:27:40',
      id: '9761248b-28f4-4fbe-b760-32c9714ee1ad',
      name: 'FallInPointClassification',
      description: 'FallIn Point Classification',
      classifications: [
        {
          code: 5,
          name: 'High Vegetation',
          description: 'High vegetation object',
          color: [0.7529312, 0.7529312, 0.7529312, 1.0],
        },
        {
          code: 49,
          name: 'Fall In Overshoot',
          description: 'Vegetation object that has a transmission line Overshoot potential',
          color: [1.0, 0.0, 0.0, 1.0],
        },
        {
          code: 50,
          name: 'Fall In MVCD',
          description: 'Vegetation object that has a transmission line Fall In potential at MVCD',
          color: [1.0, 0.5686275, 0.0, 1.0],
        },
        {
          code: 51,
          name: 'Fall In MVCD+5',
          description: 'Vegetation object that has a transmission line Fall In potential at MVCD+5',
          color: [1.0, 0.9019608, 0.0, 1.0],
        },
        {
          code: 52,
          name: 'Fall In MVCD+10',
          description: 'Vegetation object that has a transmission line Fall In potential at MVCD+10',
          color: [0.8039216, 1.0, 0.0, 1.0],
        },
      ],
    },
    {
      createdBy: '00000000-0000-0000-0000-000000000000',
      createdDate: '2022-10-19 22:24:55',
      lastModifiedBy: '00000000-0000-0000-0000-000000000000',
      lastModifiedDate: '2022-10-25 17:27:40',
      id: 'f3414d93-77bf-41dd-bddb-b88262db1ba8',
      name: 'GrowInPointClassification',
      description: 'GrowIn Point Classification',
      classifications: [
        {
          code: 5,
          name: 'High Vegetation',
          description: 'High vegetation object',
          color: [0.7529312, 0.7529312, 0.7529312, 1.0],
        },
        {
          code: 53,
          name: 'Grow In MVCD',
          description: 'Vegetation object that has a transmission line Grow In potential at MVCD',
          color: [1.0, 0.5686275, 0.0, 1.0],
        },
        {
          code: 54,
          name: 'Grow In MVCD+5',
          description: 'Vegetation object that has a transmission line Grow In potential at MVCD+5',
          color: [1.0, 0.9019608, 0.0, 1.0],
        },
        {
          code: 55,
          name: 'Grow In MVCD+10',
          description: 'Vegetation object that has a transmission line Grow In potential at MVCD+10',
          color: [0.8039216, 1.0, 0.0, 1.0],
        },
      ],
    },
    {
      createdBy: '00000000-0000-0000-0000-000000000000',
      createdDate: '2022-10-19 22:24:55',
      lastModifiedBy: '00000000-0000-0000-0000-000000000000',
      lastModifiedDate: '2022-10-25 17:27:40',
      id: 'b6df30e8-ae89-454b-992f-fefc3385eebb',
      name: 'sampleClassification',
      description: 'Default Sample Classification values',
      classifications: [
        {
          code: 0,
          name: 'Never Classified',
          description: null,
          color: [0.2578456, 0.37797868, 0.43274269, 1.0],
        },
        {
          code: 1,
          name: 'Unassigned',
          description: null,
          color: [0.57972336, 0.37942448, 0.3503563, 1.0],
        },
        {
          code: 2,
          name: 'Ground',
          description: null,
          color: [0.018150011, 0.19106634, 0.16997874, 1.0],
        },
        {
          code: 3,
          name: 'Low Vegetation',
          description: null,
          color: [0.7878234, 0.3072748, 0.8345181, 1.0],
        },
        {
          code: 4,
          name: 'Medium Vegetation',
          description: null,
          color: [0.018403428, 0.8966467, 0.8008385, 1.0],
        },
        {
          code: 5,
          name: 'High Vegetation',
          description: null,
          color: [0.23821966, 0.08863684, 0.79979646, 1.0],
        },
      ],
    },
    {
      createdBy: '00000000-0000-0000-0000-000000000000',
      createdDate: '2022-10-19 22:24:56',
      lastModifiedBy: '00000000-0000-0000-0000-000000000000',
      lastModifiedDate: '2022-10-25 17:27:40',
      id: 'cdc15cc3-91dd-43e0-af93-83d33031ee16',
      name: 'GrowInTreeClassification',
      description: 'GrowIn Tree Classification',
      classifications: [
        {
          code: 5,
          name: 'High Vegetation',
          description: 'High vegetation object',
          color: [0.7529312, 0.7529312, 0.7529312, 1.0],
        },
        {
          code: 53,
          name: 'Grow In MVCD',
          description: 'Vegetation object that has a transmission line Grow In potential at MVCD',
          color: [1.0, 0.5686275, 0.0, 1.0],
        },
        {
          code: 54,
          name: 'Grow In MVCD+5',
          description: 'Vegetation object that has a transmission line Grow In potential at MVCD+5',
          color: [1.0, 0.9019608, 0.0, 1.0],
        },
        {
          code: 55,
          name: 'Grow In MVCD+10',
          description: 'Vegetation object that has a transmission line Grow In potential at MVCD+10',
          color: [0.8039216, 1.0, 0.0, 1.0],
        },
      ],
    },
  ],
};

const useSapFlowView = (userId: string, transactionId: string) => {
  const axios = useAxiosInstance();
  return useQuery(['sapFlowView', userId, transactionId], async () => {
    // return dummyData2;
    const params = {
      transaction_id: transactionId,
      user_id: userId,
      // project_id: 'a047dece-5e4e-42fc-a0fe-aa2aa5751465', // Remove this
    };
    const result = await axios.get<SapFlowViewConfig>(endpoint, { params });
    return result.data;
    //return dummyData2;
  });
};

export default useSapFlowView;
