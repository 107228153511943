import React from 'react';
import { Navigate, Routes, Route } from 'react-router-dom';
import { useUser } from '../../hooks/authentication';
import { BackgroundContainer } from '../../components/layout';
import {
  Home,
  SapFlowWizard,
  Messaging,
  Dashboard,
  Test,
  ProjectDetails,
  PotreeViewer,
  ProjectDownload,
  SapFlowDetails,
} from '../../pages';
import Navbar from './Navbar';
import Page from './Page';

const PrivateRoutes: React.FC = () => {
  const { isAuthenticated } = useUser();

  if (!isAuthenticated()) {
    return <Navigate to="/login" />;
  }

  return (
    <div className="d-flex flex-column flex-lg-row">
      <Navbar />
      <BackgroundContainer className="flex-grow-1 vh-100 overflow-auto">
        <Routes>
          <Route
            path="/"
            element={
              <Page title="Home">
                <Home />
              </Page>
            }
          />
          <Route path="/sapflow">
            <Route
              path="new"
              element={
                <Page title="SapFlow Creation">
                  <SapFlowWizard newProject />
                </Page>
              }
            />
            <Route
              path=":projectId"
              element={
                <Page title="Project Creation">
                  <SapFlowWizard />
                </Page>
              }
            />
            <Route
              path=""
              element={
                <Page title="SapFlow Wizard">
                  <SapFlowWizard />
                </Page>
              }
            />
          </Route>
          <Route path="/messaging" element={<Messaging />} />
          <Route
            path="/dashboard"
            element={
              <Page title="Dashboard">
                <Dashboard />
              </Page>
            }
          />
          <Route
            path="/viewer/:transactionId"
            element={
              <Page title="Viewer">
                <PotreeViewer />
              </Page>
            }
          />
          <Route path="/test" element={<Test />} />
          <Route
            path="/project/:projectId"
            element={
              <Page title="Project Details">
                <ProjectDetails />
              </Page>
            }
          />
          <Route path="/download-artifact/:transactionId" element={<ProjectDownload />} />
          {/*  (/:projectid)(/:userid)(/:transactionid)  /:projectid/:userid/:transactionid*/}
          <Route
            path="/details/:transactionId"
            element={
              <Page title="SapFlow Details">
                <SapFlowDetails />
              </Page>
            }
          />
        </Routes>
      </BackgroundContainer>
    </div>
  );
};

export default PrivateRoutes;
