import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { GridColumn, GridDetailRowProps } from '@progress/kendo-react-grid';
import { Typography } from '@progress/kendo-react-common';
import { Skeleton } from '@progress/kendo-react-indicators';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { DashboardTransaction, SapFlowTransactionItemStatus } from '../../types';
import { useUser } from '../../hooks/authentication';
import { useLogExceptions } from '../../hooks/logging';
import { StyledGrid, StyledTextButton } from '../../components/styled';
import { Icon } from '../../components/layout';
import { ProgressBar } from '../../components/feedback';
import { useTable } from '../../hooks/common';
import { useDeleteTransaction, useTransactions } from '../../hooks/sapflow';
import { REFETCH_INTERVAL } from '../../common/constants';
import { toDashboardTransactionSet, toSapFlow } from '../../converters/dashboardTransaction';
import { saveAs } from '@progress/kendo-file-saver';
import axios from 'axios';

const StyledErrorTextButton = styled(StyledTextButton)`
  color: var(--geosap-error);
  &:hover {
    color: var(--geosap-error-link-hover);
  }
`;

interface ActionsCellProps {
  dataItem: DashboardTransaction;
  onErrorClick: (dataItem: DashboardTransaction) => void;
  onDeleteClick: (transid: string) => void;
}

// function deleteTrans(id:string) {
//
//     try {
//         if (id !== ""){
//             console.log(id)
//             const deleteTransactionQuery = useDeleteTransaction(id);
//
//         };
//
//         // deleteTransactionQuery(dataItem.id);
//         // useDeleteTransaction(dataItem.id)
//         // if   (useDeleteTransaction(dataItem.id).isSuccess){
//         //     setDeletedTrans(dataItem.id)  ;
//         // }
// //             if (deleteTransactionQuery.isSuccess) {
// // console.log("Success in delete trans");
// //             }
//     } catch (err) {
//         // setGetResult(fortmatResponse(err));
//         console.log("Err deleting Trans: " + err)
//     }
// }

const StatusCell: React.FC<ActionsCellProps> = ({ dataItem, onErrorClick, onDeleteClick }) => {
  if (dataItem.status === SapFlowTransactionItemStatus.TRANSACTION_CREATED) {
    return (
      <td>
        <div className="d-flex gap-3" style={{ paddingRight: '1rem' }}>
          <span>Upload Required</span>
        </div>
      </td>
    );
  } else if (dataItem.status === SapFlowTransactionItemStatus.TRANSACTION_FINISHED) {
    return (
      <td>
        <div className="d-flex gap-3" style={{ paddingRight: '1rem' }}>
          <ProgressBar progress={100} />
        </div>
      </td>
    );
  } else if (dataItem.succeeded) {
    return (
      <td>
        <div className="d-flex gap-3" style={{ paddingRight: '1rem' }}>
          <ProgressBar progress={dataItem.progress} />
        </div>
      </td>
    );
  }
  return (
    <td>
      <div className="d-flex gap-3" style={{ paddingRight: '1rem' }}>
        <ProgressBar progress={dataItem.progress} />
      </div>
    </td>
  );
};

const ActionsCell: React.FC<ActionsCellProps> = ({ dataItem, onErrorClick, onDeleteClick }) => {
  const { getUser } = useUser();
  const filesIdToDownload =
    !dataItem.outputFiles || dataItem.outputFiles.length === 0
      ? null
      : dataItem.outputFiles
          .map((file) => {
            return file.id;
          })
          .join();

  const [deletedTrans, setDeletedTrans] = useState<string>('');

  const deleteTransaction = async (transId: string) => {
    const endpoint = '/sapflow/sapflow';
    const params = { id: transId };
    const result = await axios.delete(process.env.REACT_APP_GEOSAP_SERVERURL + endpoint, { params });
    const data = result.data;
    console.log('Headers: ', data.headers);

    setDeletedTrans(data);
  };

  return (
    <td>
      <div className="d-flex gap-3">
        {!dataItem.succeeded && (
          <div>
            <StyledErrorTextButton type="button" onClick={() => onErrorClick(dataItem)}>
              View Errors
            </StyledErrorTextButton>
            <button onClick={() => deleteTransaction(dataItem.id)}>Delete</button>
          </div>
        )}
        {/*Download URL Path file_manager/download_files   pass the userID, projectID, transactionID, itemID Array of items to be downloaded*/}
        {/*/api/sapflow/item_id_list userID, pprojectID, transactionID*/}
        {dataItem.succeeded && (
          <>
            {/*<a
              href="http://customer.whiteoutsolutions.com/tree-segmentation/tree-segmentation.html"
              target="_blank"
              rel="noreferrer"
            >
              View
            </a>*/}
            <Link
              to={`/viewer/${dataItem.id}`}
              className={
                dataItem.visualizableFiles.length > 0 &&
                dataItem.status !== SapFlowTransactionItemStatus.TRANSACTION_CREATED
                  ? ''
                  : 'link-disabled'
              }
            >
              View
            </Link>
            <Link
              to={`/details/${dataItem.id}`}
              className={dataItem.status !== SapFlowTransactionItemStatus.TRANSACTION_CREATED ? '' : 'link-disabled'}
            >
              Details
            </Link>
            <Link to={`/dashboard`} onClick={() => onDeleteClick(dataItem.id)}>
              Delete
            </Link>

            {/*<Link to={`/download-artifact/${dataItem.id}`}>
              <span className="k-icon k-i-download" style={{ fontSize: '1.2rem' }}></span>
          </Link>*/}
            <a
              className={`${filesIdToDownload ? '' : 'disabled'}`}
              download
              href={
                process.env.REACT_APP_GEOSAP_SERVERURL +
                '/file_manager/download_files?user_id=' +
                getUser().id +
                '&transaction_id=' +
                dataItem.id +
                '&item_id_list=' +
                filesIdToDownload
              }
            >
              <span className="k-icon k-i-download" style={{ fontSize: '1.2rem' }}></span>
            </a>
          </>
        )}
      </div>
    </td>
  );
};

const ErrorsLogViewer: React.FC<GridDetailRowProps> = () => {
  const [deletedTrans, setDeletedTrans] = useState<string>('');
  const divRef = useRef<HTMLDivElement>();

  const logExceptionsQuery = useLogExceptions();

  useEffect(() => {
    if (divRef.current?.parentElement?.tagName === 'TD') {
      const tdEl = divRef.current.parentElement as HTMLTableCellElement;
      tdEl.colSpan = tdEl.colSpan + 1;
      tdEl.ariaColIndex = '1';
    }
  }, []);

  return (
    <div ref={divRef}>
      <div className="px-4 py-2 mb-2 border-bottom">
        <Typography.p fontWeight="bold" fontSize="small" className="m-0">
          Latest error logs
        </Typography.p>
      </div>
      <div className="px-4 pb-1">
        {logExceptionsQuery.isLoading && (
          <div className="w-75">
            {Array(5)
              .fill(<Skeleton shape="text" />)
              .map((skeleton, i) => (
                <div key={i} className="py-1">
                  {skeleton}
                </div>
              ))}
          </div>
        )}
        {logExceptionsQuery.isSuccess && (
          <ul>
            {logExceptionsQuery.data.slice(0, 5).map((log) => (
              <li key={`${log.id.eventId}-${log.id.i}`} className="py-1">
                <Icon iconClass="k-i-stop" size=".3rem" />
                <span className="ps-1">{log.traceLine}</span>
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

interface Props {
  data: DashboardTransaction[];
  onExpand: (dataItem: DashboardTransaction) => void;
  onDelete: (transid: string) => void;
}

const TransactionGrid: React.FC<Props> = ({ data, onExpand, onDelete }) => {
  const tableProps = useTable({ pageSize: 10, data, sortDescriptor: { field: 'created', dir: 'desc' } });
  const navigate = useNavigate();

  const ActionsCellWithErrorHandler: React.FC<{ dataItem: DashboardTransaction }> = ({ dataItem }) => (
    <ActionsCell dataItem={dataItem} onErrorClick={onExpand} onDeleteClick={onDelete} />
  );

  const StatusCellWithErrorHandler: React.FC<{ dataItem: DashboardTransaction }> = ({ dataItem }) => (
    <StatusCell dataItem={dataItem} onErrorClick={onExpand} onDeleteClick={onDelete} />
  );

  // useEffect(() => {
  //
  //
  //     // if (deleteTransactionQuery.isSuccess) {
  //     // User has no project/transaction
  //
  //     // setDeletedTrans(deleteTransactionQuery.data);
  //     // }
  // }, [deletedTrans]);

  return (
    <StyledGrid
      {...tableProps}
      pageable
      sortable
      total={data.length}
      detail={ErrorsLogViewer}
      expandField="expanded"
      onRowClick={({ dataItem }) => {
        console.log('Clicking item: ' + JSON.stringify(dataItem));
        if (dataItem.status === SapFlowTransactionItemStatus.TRANSACTION_CREATED) {
          // Redirect to sapflow wizard step 5
          navigate(`/sapflow`, { state: { sapflow: toSapFlow(dataItem) } });
        } else {
          navigate(`/details/${dataItem.id}`);
        }
      }}
      rowRender={(trElement, props) => {
        const trProps: any = {
          style: { cursor: 'pointer' },
        };
        return React.cloneElement(
          trElement,
          {
            ...trProps,
          },
          trElement.props.children
        );
      }}
    >
      <GridColumn
        field="name"
        title="SAP Flow"
        cell={({ dataItem }: any) => <td className="fw-bold">{dataItem.name}</td>}
      />
      {/* 
        todo: we need to understand what data we want to bring in this column
        <GridColumn field="type" title="SAP Type" /> 
      */}
      <GridColumn field="projectName" title="Project Name" />
      <GridColumn field="created" title="Date" format="{0:h:mm a - M/d/yy}" />
      <GridColumn cell={StatusCellWithErrorHandler} title="Processing Status" />
      {/*<GridColumn field="status" title="Processing Status" />*/}
      <GridColumn cell={ActionsCellWithErrorHandler} title="Actions" />
    </StyledGrid>
  );
};

export default TransactionGrid;
