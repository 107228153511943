import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { Typography } from '@progress/kendo-react-common';
import { DropDownList } from '@progress/kendo-react-dropdowns'; //https://www.telerik.com/kendo-react-ui/components/dropdowns/#toc-customization
import { Button } from '@progress/kendo-react-buttons';

import { ReactComponent as EarthLogo } from '../../assets/icons/toolbar-earth.svg';
import { ReactComponent as OrbitLogo } from '../../assets/icons/toolbar-orbit.svg';
import { ReactComponent as MoveLogo } from '../../assets/icons/toolbar-move.svg';
import { ReactComponent as RotateLogo } from '../../assets/icons/toolbar-rotate.svg';
import { ReactComponent as PointLogo } from '../../assets/icons/toolbar-point.svg';
import { ReactComponent as DistanceLogo } from '../../assets/icons/toolbar-distance.svg';
import { ReactComponent as HeightLogo } from '../../assets/icons/toolbar-height.svg';
import { ReactComponent as PolygonLogo } from '../../assets/icons/toolbar-polygon.svg';
import { ReactComponent as AnnotateLogo } from '../../assets/icons/toolbar-annotate.svg';

const POINT_MEASUREMENT = 'point';
const DISTANCE_MEASUREMENT = 'distance';
const HEIGHT_MEASUREMENT = 'height';
const VOLUME_MEASUREMENT = 'volume';

const Container = styled.div`
  background: var(--geosap-navbar-color);
  z-index: 1;
`;

const BrandImage = styled.img`
  width: var(--geosap-viewer-toolbar-width);
`;
const ToolImage = styled.img`
  width: var(--geosap-viewer-toolbar-icon-width);
  height: var(--geosap-viewer-toolbar-icon-width);
`;

const ToolSeparator = styled.div`
  width: 2px;
  background: white;
  margin-top: 10px;
  margin-bottom: 10px;
`;

interface ViewerToolbarProps {
  viewer: any;
  projectName: string;
  currentPointcloudAttributes: any;
  currentAttributeValue: string;
  onAttributeChange(event: any): void;
}

const ViewerToolbar: React.FC<ViewerToolbarProps> = (props: ViewerToolbarProps) => {
  const [addingAnnotation, setAddingAnnotation] = useState<boolean>(false);
  const [editingAnnotation, setEditingAnnotation] = useState<any>(null);
  const [mapViewAvailable, setMapViewAvailable] = useState<boolean>(false);
  const [mapViewOpened, setMapViewOpened] = useState<boolean>(false);
  const [currentPotreeControls, setCurrentPotreeControls] = useState<any>(null);
  const [ongoingMeasurementType, setOngoingMeasurementType] = useState<string>('');

  const onAnnotationClicked = (e: any) => {
    console.log('Annotation clicked: ' + e.annotation);
    const annotation = e.annotation;
    setEditingAnnotation(annotation);
  };

  useEffect(() => {
    if (props.viewer) {
      props.viewer.setControls(props.viewer.orbitControls);
      setCurrentPotreeControls(props.viewer.orbitControls);
      props.viewer.addEventListener('update', onViewerUpdate);
    }
    return () => {
      if (props.viewer) {
        props.viewer.removeEventListener('update', onViewerUpdate);
      }
    };
  }, [props.viewer]);

  const onViewerUpdate = () => {
    setMapViewAvailable(props.viewer.mapView.sceneProjection ? true : false);
  };

  const clearMeasureState = () => {
    setOngoingMeasurementType('');
  };

  const clearAnnotationState = () => {
    setAddingAnnotation(false);
  };

  return (
    <Container className="d-flex">
      <ToolSeparator />
      {/* Navigate Tools */}
      <div className="px-3 py-1 flex-column d-flex">
        <Typography.p fontSize="small" themeColor="light" className="mb-0">
          Navigate
        </Typography.p>
        <ul className="flex-grow-1 d-flex">
          {mapViewAvailable && (
            <li
              role="button"
              onClick={() => {
                props.viewer.toggleMap();
                setMapViewOpened(props.viewer.mapView.enabled);
              }}
              className="px-1"
            >
              <EarthLogo
                fill={mapViewOpened ? '#fff' : '#aaa'}
                stroke={mapViewOpened ? '#fff' : '#aaa'}
                style={{
                  width: 'var(--geosap-viewer-toolbar-icon-width)',
                  height: 'var(--geosap-viewer-toolbar-icon-width)',
                }}
              />
            </li>
          )}
          <li
            role="button"
            onClick={() => {
              props.viewer.setControls(props.viewer.orbitControls);
              setCurrentPotreeControls(props.viewer.orbitControls);
            }}
            className="px-1"
          >
            <OrbitLogo
              fill={currentPotreeControls === props.viewer?.orbitControls ? '#fff' : '#aaa'}
              stroke={currentPotreeControls === props.viewer?.orbitControls ? '#fff' : '#aaa'}
              style={{
                width: 'var(--geosap-viewer-toolbar-icon-width)',
                height: 'var(--geosap-viewer-toolbar-icon-width)',
              }}
            />
          </li>
          <li
            role="button"
            onClick={() => {
              props.viewer.setControls(props.viewer.earthControls);
              setCurrentPotreeControls(props.viewer.earthControls);
            }}
            className="px-1"
          >
            <MoveLogo
              fill={currentPotreeControls === props.viewer?.earthControls ? '#fff' : '#aaa'}
              stroke={currentPotreeControls === props.viewer?.earthControls ? '#fff' : '#aaa'}
              style={{
                width: 'var(--geosap-viewer-toolbar-icon-width)',
                height: 'var(--geosap-viewer-toolbar-icon-width)',
              }}
            />
            {/*<ToolImage src="/assets/toolbar-move.svg" className="" />*/}
          </li>
          <li
            role="button"
            onClick={() => {
              props.viewer.setControls(props.viewer.fpControls);
              setCurrentPotreeControls(props.viewer.fpControls);
            }}
            className="px-1"
          >
            <RotateLogo
              fill={currentPotreeControls === props.viewer?.fpControls ? '#fff' : '#aaa'}
              stroke={currentPotreeControls === props.viewer?.fpControls ? '#fff' : '#aaa'}
              style={{
                width: 'var(--geosap-viewer-toolbar-icon-width)',
                height: 'var(--geosap-viewer-toolbar-icon-width)',
              }}
            />
          </li>
        </ul>
      </div>
      {/* Measure Tools */}
      <ToolSeparator />
      <div className="px-3 py-1 flex-column d-flex">
        <Typography.p fontSize="small" themeColor="light" className="mb-0">
          Measure
        </Typography.p>
        <ul className="flex-grow-1 d-flex">
          <li
            role="button"
            onClick={() => {
              setOngoingMeasurementType(POINT_MEASUREMENT);
              const measurement = props.viewer.measuringTool.startInsertion({
                showDistances: false,
                showAngles: false,
                showCoordinates: true,
                showArea: false,
                closed: true,
                maxMarkers: 1,
                name: 'Point',
                callback: clearMeasureState,
              });

              measurement.addEventListener('marker_dropped', clearMeasureState);
            }}
            className="px-1"
          >
            <PointLogo
              fill={ongoingMeasurementType === POINT_MEASUREMENT ? '#fff' : '#aaa'}
              stroke={ongoingMeasurementType === POINT_MEASUREMENT ? '#fff' : '#aaa'}
              style={{
                width: 'var(--geosap-viewer-toolbar-icon-width)',
                height: 'var(--geosap-viewer-toolbar-icon-width)',
              }}
            />
          </li>
          <li
            role="button"
            onClick={() => {
              setOngoingMeasurementType(DISTANCE_MEASUREMENT);
              const measurement = props.viewer.measuringTool.startInsertion({
                showDistances: true,
                showArea: false,
                closed: false,
                name: 'Distance',
                callback: clearMeasureState,
              });

              measurement.addEventListener('marker_dropped', clearMeasureState);
            }}
            className="px-1"
          >
            <DistanceLogo
              fill={ongoingMeasurementType === DISTANCE_MEASUREMENT ? '#fff' : '#aaa'}
              stroke={ongoingMeasurementType === DISTANCE_MEASUREMENT ? '#fff' : '#aaa'}
              style={{
                width: 'var(--geosap-viewer-toolbar-icon-width)',
                height: 'var(--geosap-viewer-toolbar-icon-width)',
              }}
            />
          </li>
          <li
            role="button"
            onClick={() => {
              setOngoingMeasurementType(HEIGHT_MEASUREMENT);
              const measurement = props.viewer.measuringTool.startInsertion({
                showDistances: false,
                showHeight: true,
                showArea: false,
                closed: false,
                maxMarkers: 2,
                name: 'Height',
                callback: clearMeasureState,
              });
              measurement.addEventListener('marker_dropped', clearMeasureState);
            }}
            className="px-1"
          >
            <HeightLogo
              fill={ongoingMeasurementType === HEIGHT_MEASUREMENT ? '#fff' : '#aaa'}
              stroke={ongoingMeasurementType === HEIGHT_MEASUREMENT ? '#fff' : '#aaa'}
              style={{
                width: 'var(--geosap-viewer-toolbar-icon-width)',
                height: 'var(--geosap-viewer-toolbar-icon-width)',
              }}
            />
          </li>
          <li
            role="button"
            onClick={() => {
              setOngoingMeasurementType(VOLUME_MEASUREMENT);
              const measurement = props.viewer.measuringTool.startInsertion({
                showDistances: true,
                showArea: true,
                closed: true,
                name: 'Area',
                callback: clearMeasureState,
              });
              measurement.addEventListener('marker_dropped', clearMeasureState);
            }}
            className="px-1"
          >
            <PolygonLogo
              fill={ongoingMeasurementType === VOLUME_MEASUREMENT ? '#fff' : '#aaa'}
              stroke={ongoingMeasurementType === VOLUME_MEASUREMENT ? '#fff' : '#aaa'}
              style={{
                width: 'var(--geosap-viewer-toolbar-icon-width)',
                height: 'var(--geosap-viewer-toolbar-icon-width)',
              }}
            />
          </li>
        </ul>
      </div>
      {/* Modify Tools */}
      <ToolSeparator />
      <div className="px-3 py-1 flex-column d-flex">
        <Typography.p fontSize="small" themeColor="light" className="mb-0">
          Modify
        </Typography.p>
        <ul className="flex-grow-1 d-flex">
          <li
            role="button"
            onClick={() => {
              setAddingAnnotation(true);
              const annotation = props.viewer.annotationTool.startInsertion({
                callback: clearAnnotationState,
              });
              annotation.addEventListener('click', (clickEvent: any) => {
                setEditingAnnotation(annotation);
              });
            }}
            className="px-1"
          >
            <AnnotateLogo
              fill={addingAnnotation ? '#fff' : '#aaa'}
              stroke={addingAnnotation ? '#fff' : '#aaa'}
              style={{
                width: 'var(--geosap-viewer-toolbar-icon-width)',
                height: 'var(--geosap-viewer-toolbar-icon-width)',
              }}
            />
          </li>
          {/*CLIPPING
          <li
            role="button"
            onClick={() => {
              console.log();
            }}
            className="px-1"
          >
            <ToolImage src="/assets/toolbar-clip.svg" className="" />
        </li>*/}
        </ul>
      </div>
      {/* Options Tools */}
      <ToolSeparator />
      <div className="px-3 py-1 flex-column d-flex">
        <Typography.p fontSize="small" themeColor="light" className="mb-0">
          Attribute View
        </Typography.p>

        <DropDownList
          style={{
            width: '200px',
          }}
          size="small"
          data={props.currentPointcloudAttributes}
          value={props.currentAttributeValue}
          dataItemKey="id"
          textField="text"
          onChange={props.onAttributeChange}
          popupSettings={{ width: 250 }}
        />
      </div>
      {/* Export Tools */}
      <ToolSeparator />
      <div className="px-3 py-1 flex-column d-flex">
        <Typography.p fontSize="small" themeColor="light" className="mb-0" style={{ color: 'var(--geosap-disabled)' }}>
          Export
        </Typography.p>

        <div className="py-1 d-flex">
          <Button
            fillMode="flat"
            size={'small'}
            themeColor={'light'}
            onClick={() => {
              props.viewer.takeScreenshot(props.projectName);
            }}
          >
            .jpg
          </Button>
          <Button fillMode="flat" size={'small'} themeColor={'light'} disabled={true}>
            .laz
          </Button>
          <Button fillMode="flat" size={'small'} themeColor={'light'} disabled={true}>
            .tif
          </Button>
          <Button fillMode="flat" size={'small'} themeColor={'light'} disabled={true}>
            .shp
          </Button>
          <Button fillMode="flat" size={'small'} themeColor={'light'} disabled={true}>
            .dxf
          </Button>
          <Button fillMode="flat" size={'small'} themeColor={'light'} disabled={true}>
            .tin
          </Button>
        </div>
      </div>
    </Container>
  );
};

export default ViewerToolbar;
