import { ComponentType } from 'react';
import styled from 'styled-components';
import { Grid, GridProps } from '@progress/kendo-react-grid';

const StyledGrid = styled(Grid as ComponentType<GridProps & { children: JSX.Element | JSX.Element[] }>)`
  // Border radius
  border-radius: 0.5rem;
  .k-grid-header {
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
  }
  .k-grid-container,
  .k-grid-pager {
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
  }

  // Cells paddings
  th,
  td {
    padding: 0.9rem 0.5rem;
  }
  th:first-child,
  td:first-child {
    padding-left: 1.5rem;
  }
  th:last-child,
  td:last-child {
    padding-right: 1.5rem;
  }

  // Border resetting
  th,
  td {
    border: none;
  }
  td {
    border-bottom: 1px solid var(--geosap-primary-color);
  }

  // Font
  font-size: 0.9rem;
  .k-grid-header {
    font-weight: 700;
  }
  a {
    color: var(--geosap-primary-color);
    text-decoration: underline;
  }

  // Detail Rows
  .k-detail-row {
    background: var(--geosap-grid-header-bg);
    .k-detail-cell {
      padding: 0;
    }
    .k-hierarchy-cell {
      display: none;
    }
  }
`;

export default StyledGrid;
