import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Typography } from '@progress/kendo-react-common';
import { TreeList, TreeListCellProps, TreeListExpandChangeEvent } from '@progress/kendo-react-treelist';
import { FileDetails } from '../../types';
import { Icon } from '../../components/layout';
import { formatSizeUnits } from '../../converters/formatSizeUnits';
import { v4 as uuidv4 } from 'uuid';

interface ProjectFileInfo {
  id: string;
  text: string;
  size: string;
  expanded?: boolean;
  isLoading?: boolean;
  inputFileData: FileDetails[];
  outputFileData: FileDetails[];
}

const StyledTreeList = styled(TreeList)`
  // Font size
  font-size: 0.9rem;

  // Border resetting
  th,
  td,
  tr[aria-level='1']:first-child > td {
    border: none;
  }
  th {
    border-bottom: 1px solid var(--geosap-primary-color);
  }
  tr[aria-level='1'] > td {
    border-top: 1px solid var(--geosap-primary-color);
  }

  // Border radius
  border-radius: 0.5rem;
  table {
    border-radius: 0.5rem;
  }

  // Background color reset
  .k-grid-header {
    background-color: unset;
  }
`;

const projectFiles: ProjectFileInfo[] = [
  {
    id: uuidv4(),
    text: 'Uploads',
    size: '2.5GB',
    inputFileData: [
      {
        id: uuidv4(),
        name: 'Point Clouds',
        size: 109940440,
      },
      {
        id: uuidv4(),
        name: 'Imagery',
        size: 64940440,
      },
    ],
    outputFileData: [
      {
        id: uuidv4(),
        name: 'Point Clouds',
        size: 53240440,
      },
      {
        id: uuidv4(),
        name: 'Imagery',
        size: 12040440,
      },
    ],
  },
];

const ActionsCell: React.FC<TreeListCellProps> = () => {
  return (
    <td>
      <span className="pe-2">
        <Icon iconClass="k-i-download" size="1.1rem" color="green" />
      </span>
      <Icon iconClass="k-i-delete" size="1.1rem" color="red" />
    </td>
  );
};

interface Props {
  isLoading?: boolean;
  isSuccess?: boolean;
  inputFileData: FileDetails[];
  outputFileData: FileDetails[];
}

const ProjectFilesSection: React.FC<Props> = ({ isLoading, isSuccess, inputFileData, outputFileData }) => {
  const [treeListData, setTreeListData] = useState(projectFiles);

  useEffect(() => {
    if (isSuccess) {
      const newTreeData: any = [
        {
          id: uuidv4(),
          text: 'Uploads',
          size: formatSizeUnits(
            inputFileData.reduce((accumulator, currentValue) => accumulator + currentValue.size, 0)
          ),
          files: inputFileData.map((fileData) => {
            return { id: uuidv4(), text: fileData.name, size: formatSizeUnits(fileData.size) };
          }),
        },
        {
          id: uuidv4(),
          text: 'Outputs',
          size: formatSizeUnits(
            outputFileData.reduce((accumulator, currentValue) => accumulator + currentValue.size, 0)
          ),
          files: outputFileData.map((fileData) => {
            return { id: uuidv4(), text: fileData.name, size: formatSizeUnits(fileData.size) };
          }),
        },
      ];
      setTreeListData(newTreeData);
    }
  }, [isSuccess, inputFileData, outputFileData]);

  const handleExpandChange = (e: TreeListExpandChangeEvent): void => {
    setTreeListData((prevState) =>
      prevState.map((file) => ({
        ...file,
        expanded: e.dataItem.id === file.id ? !file.expanded : file.expanded,
      }))
    );
  };

  return (
    <div>
      <Typography.h4 fontWeight="normal" className="text-uppercase">
        Project Files
      </Typography.h4>
      <StyledTreeList
        className="mt-3"
        data={treeListData}
        expandField="expanded"
        subItemsField="files"
        columns={[
          { field: 'text', title: 'Name', expandable: true, width: '50%' },
          { field: 'size', title: 'Size', width: '25%' },
          { title: 'Actions', cell: ActionsCell, width: '25%' },
        ]}
        onExpandChange={handleExpandChange}
      />
    </div>
  );
};

export default ProjectFilesSection;
