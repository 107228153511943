export interface WSMessage {
  msgHeader: WSMessageHeader;
  payoad: any;
}

export interface WSMessageHeader {
  msgFrom: string;
  fromType: EMsgDestinationType;
  msgTo: string;
  toType: EMsgDestinationType;
  msgType: EMsgType;
  sendTime: string;
}

export enum EMsgDestinationType {
  GS_SERVER,
  FOLIAGE_SERVER,
  ROOTS_SERVER,
  USER,
  ORGANIZATION,
  SYSTEM,
}

export enum EMsgType {
  SERVER_ERROR,
  SERVER_INFO,
  SERVER_NEW_SAPS_AVAILABLE,
  TRANSACTION_STARTED,
  TRANSACTION_FINISHED,
  TRANSACTION_ERROR,
  TRANSACTION_UPDATE,
  ORGANIZATION_PEER_2_PEER_MSG,
  ORGANIZATION_NEW_DATA_AVAILABLE,
  HEARTBEAT,
}
