import { User, AppNotification, Organization } from '../../types';

export interface AppState {
  user: User | null;
  selectedOrganization: Organization | null;
  availableOrganizations: Organization[] | null;
  notification: AppNotification | null;
  recentlyCreatedUser: string | null;
}

export const initialState: AppState = {
  user: null,
  selectedOrganization: null,
  availableOrganizations: [],
  notification: null,
  recentlyCreatedUser: null,
};
