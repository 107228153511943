import { useMutation } from 'react-query';
import { LOCAL_STORAGE_AUTH_KEY, LOCAL_STORAGE_CURRENT_ORG } from '../../common/constants';
import { Organization } from '../../types';
import { useAppContext } from '../../context/app';

const endpoint = '/authentication/signin';

const useSelectOrganization = () => {
  const { dispatch } = useAppContext();

  return useMutation((selectedOrganization: Organization) => {
    return new Promise<void>((resolve, reject) => {
      localStorage.setItem(LOCAL_STORAGE_CURRENT_ORG, JSON.stringify(selectedOrganization));
      dispatch({ type: 'SELECT_ORGANIZATION', payload: { organization: selectedOrganization } });
      resolve();
    });
  });
};

export default useSelectOrganization;
