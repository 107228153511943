import React, { useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useNavigate, Link } from 'react-router-dom';
import { Typography } from '@progress/kendo-react-common';
import { CentralizedContent } from '../components/layout';
import { Input, SubmitButton } from '../components/form';
import { useSignin, useUser } from '../hooks/authentication';
import { useAppContext } from '../context/app';

interface LoginFormValues {
  email: string;
  password: string;
}

const Login: React.FC = () => {
  const { recentlyCreatedUser } = useAppContext();
  const navigate = useNavigate();
  const { isAuthenticated, organizationSelected } = useUser();
  const { handleSubmit, reset, control } = useForm<LoginFormValues>();

  const signInMutation = useSignin(() => {
    if (organizationSelected()) {
      navigate('/', { replace: true });
    } else {
      navigate('/selectorganization');
    }
  });

  const signIn = (data: LoginFormValues) => {
    signInMutation.mutate(data);
  };

  useEffect(() => {
    if (isAuthenticated()) {
      if (organizationSelected()) {
        navigate('/', { replace: true });
      } else {
        navigate('/selectorganization');
      }
    }
  }, []);

  useEffect(() => {
    if (recentlyCreatedUser) {
      reset({ email: recentlyCreatedUser, password: '' });
    }
  }, [recentlyCreatedUser]);

  return (
    <CentralizedContent>
      <div className="col-xs-12 col-lg-6 col-xl-4">
        <Typography.h2 textAlign="center" fontWeight="light">
          Log in to GeoSAP
        </Typography.h2>
        <Typography.p textAlign="center" className="py-2">
          {"Don't have an account? "}
          <Link to="/register">Sign up</Link>
        </Typography.p>
        <form onSubmit={handleSubmit(signIn)}>
          <Controller
            name="email"
            control={control}
            render={({ field }) => <Input {...field} type="text" placeholder="Email" />}
          />
          <Controller
            name="password"
            control={control}
            render={({ field }) => <Input {...field} type="password" placeholder="Password" />}
          />
          <div className="pt-3">
            <SubmitButton label="Log in" loading={signInMutation.isLoading} />
          </div>
        </form>
      </div>
    </CentralizedContent>
  );
};

export default Login;
