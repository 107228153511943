import { useMutation } from 'react-query';
import { LOCAL_STORAGE_AUTH_KEY, LOCAL_STORAGE_CURRENT_ORG } from '../../common/constants';
import { SigninRequest, SigninResponse } from '../../types';
import { useAppContext } from '../../context/app';
import { useAxiosInstance } from '../common';

const endpoint = '/authentication/signin';

const useSignin = (onSuccess: VoidFunction) => {
  const { dispatch } = useAppContext();
  const axios = useAxiosInstance();

  return useMutation(async (newUser: SigninRequest) => {
    const response = await axios.post<SigninResponse>(endpoint, newUser);
    if (response) {
      if (response.data && response.data.accessToken) {
        localStorage.setItem(LOCAL_STORAGE_AUTH_KEY, JSON.stringify(response.data));
        let selectedOrganization = null;
        if (response.data.organizations && response.data.organizations?.length === 1) {
          selectedOrganization = response.data.organizations[0];
        }
        if (!response.data.organizations || response.data.organizations?.length === 0) {
          selectedOrganization = {
            description: "Default GeoSap Organization",
            id: "unknown",
            name: "Default"
          }
        }

        if(selectedOrganization) {
          localStorage.setItem(LOCAL_STORAGE_CURRENT_ORG, JSON.stringify(selectedOrganization));
        }

        dispatch({ type: 'LOGIN', payload: { user: response.data, selectedOrganization: selectedOrganization } });
      }
      onSuccess();
    }
  });
};

export default useSignin;
