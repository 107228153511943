import {useMutation, useQuery, useQueryClient} from 'react-query';
import {SapFlowProjectWithSapFlows, SapFlowTransactionItem} from '../../types';
import { useAxiosInstance } from '../common/';
import { useUser } from '../authentication';

const endpoint = '/sapflow/sapflow';

interface Params {
  transid: string;
}

const useDeleteTransaction = () => {
  const { getUser } = useUser();
  const axios = useAxiosInstance();
  const queryClient = useQueryClient();
  return useMutation(
    async (params: Params) => {
      const queryParams = { id: params.transid };
      const result = await axios.delete<string>(endpoint, { params: queryParams });
      return result.data;
    },
    {
      onSuccess: (_data, variables) => {
        queryClient.invalidateQueries(['transactions', getUser().id], { refetchInactive: true });
      },
    }
  );
};

export default useDeleteTransaction;
