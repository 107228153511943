import { User } from '../../types';

const useAuthorization = () => {
  const addAuthorizationHeader = (header: Record<string, unknown>, user: User): void => {
    const { tokenType, accessToken } = user;
    header.Authorization = `${tokenType} ${accessToken}`;
  };

  return { addAuthorizationHeader };
};

export default useAuthorization;
