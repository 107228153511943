import React from 'react';
import { Typography } from '@progress/kendo-react-common';
import { SapFlowInfo } from '../../types';

const InfoRow: React.FC<{ label: string; value: string | number }> = ({ label, value }) => (
  <li>
    <strong>{label}: </strong>
    <span>{value}</span>
  </li>
);

const SummaryOverview: React.FC<SapFlowInfo> = ({ credits, processingTime, sapFlow }) => {
  const timeElapsed = (timeInMin: number): string => {
    const hours = Math.floor(timeInMin / 60);
    const minutes = timeInMin % 60;
    const hoursText = hours ? `${hours.toString(10).padStart(2, '0')}h` : '';
    const minutesText = minutes ? `${minutes.toString(10).padStart(2, '0')}m` : '';
    if (hoursText && minutesText) {
      return `${hoursText} ${minutesText}`;
    }
    return hoursText ? hoursText : minutesText;
  };

  return (
    <div>
      <div className="container-fluid pb-4">
        <div className="row">
          <div className="col-6 text-center" style={{ borderRight: '1px solid var(--bs-body-color)' }}>
            <Typography.h4>{credits}</Typography.h4>
            <Typography.h5>
              <div>Credits</div>
              <div>Used</div>
            </Typography.h5>
          </div>
          <div className="col-6 text-center" style={{ borderLeft: '1px solid var(--bs-body-color)' }}>
            <Typography.h4>{timeElapsed(processingTime)}</Typography.h4>
            <Typography.h5>
              <div>Processing</div>
              <div>Time</div>
            </Typography.h5>
          </div>
        </div>
      </div>
      <ul className="pb-4" style={{ fontSize: '0.8rem' }}>
        <InfoRow label="Date Executed" value={sapFlow.executed} />
        <InfoRow label="Input Files Processed" value={sapFlow.files.inputCount} />
        <InfoRow label="Total Input File Size" value={sapFlow.files.inputSize} />
        <InfoRow label="Total Output File Size" value={sapFlow.files.outputSize} />
      </ul>
      <div style={{ background: 'var(--geosap-kendo-button-bg)', fontSize: '0.8rem' }} className="p-3 rounded-3">
        <Typography.h6>{sapFlow.name}</Typography.h6>
        <p>{sapFlow.description}</p>
        <ul className="ps-3">
          <InfoRow label="Number of SAP in flow" value={sapFlow.sapCount} />
          <InfoRow label="Last Edited" value={sapFlow.updated} />
          <InfoRow label="Process Types" value={sapFlow.processTypes.join(', ')} />
          <InfoRow label="Required Inputs" value={sapFlow.files.inputTypes.join(', ')} />
        </ul>
      </div>
    </div>
  );
};

export default SummaryOverview;
