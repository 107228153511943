import React, { useEffect, useState, useRef } from 'react';
import { Checkbox } from '@progress/kendo-react-inputs';
import { SapFlowViewConfig, LayerType } from '../../types';
import { SketchPicker } from 'react-color';

interface ClassificationToggleProps {
  index: number;
  classificationData: any;
  onVisibilityChange: any;
  onColorChange: any;
}

const ClassificationToggle: React.FC<ClassificationToggleProps> = (props: ClassificationToggleProps) => {
  const [displayColorPicker, setDisplayColorPicker] = React.useState(false);
  const [color, setColor] = React.useState<any>({ r: 241, g: 112, b: 19, a: 1 });

  useEffect(() => {
    if (props.classificationData && props.classificationData.color) {
      setColor({
        r: Math.round(props.classificationData.color[0] * 255),
        g: Math.round(props.classificationData.color[1] * 255),
        b: Math.round(props.classificationData.color[2] * 255),
        a: 1,
      });
    } else {
      setColor({
        r: Math.round(0.3 * 255),
        g: Math.round(0.6 * 255),
        b: Math.round(0.6 * 255),
        a: 1,
      });
    }
  }, [props.index, props.classificationData]);

  return (
    <div className="d-flex">
      <Checkbox
        className="layers-checkbox"
        defaultChecked={true}
        label={''}
        onChange={(e) => {
          props.onVisibilityChange(e.value);
        }}
      />
      <span style={{ fontWeight: 'bold', paddingRight: '0.5rem', paddingLeft: '1rem' }}>
        {props.index + ' - ' + props.classificationData?.name}
      </span>
      <div
        style={{
          padding: '4px',
          background: '#fff',
          borderRadius: '1px',
          boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
          display: 'inline-block',
          cursor: 'pointer',
          marginLeft: 'auto',
          height: 'fit-content',
        }}
        onClick={() => {
          setDisplayColorPicker(!displayColorPicker);
        }}
      >
        <div
          style={{
            width: '36px',
            height: '14px',
            borderRadius: '2px',
            background: `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`,
          }}
        />
      </div>
      {displayColorPicker ? (
        <div style={{ position: 'absolute', zIndex: 100 }}>
          <div
            style={{ position: 'fixed', top: '0px', right: '0px', bottom: '0px', left: '0px' }}
            onClick={() => {
              setDisplayColorPicker(false);
            }}
          />
          <SketchPicker
            color={color}
            onChange={(newColor) => {
              props.onColorChange(newColor.rgb);
              setColor(newColor.rgb);
            }}
          />
        </div>
      ) : null}
    </div>
  );
};

export default ClassificationToggle;
