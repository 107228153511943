import { useQuery, useMutation, useQueryClient } from 'react-query';
import { SapFlowViewConfig, LayerType } from '../../types';
import { useAxiosInstance } from '../common';

const endpoint = 'file_manager/reuse_item';

interface Params {
  item_id_old: string;
  item_id_new: string;
}

const useSapFlowParamsUpdate = () => {
  const axios = useAxiosInstance();
  //const queryClient = useQueryClient();
  return useMutation(
    async (params: any) => {
      const response = await axios.post<any>(endpoint, {}, { params });
      return response.data;
    },
    {
      onSuccess: (_data, variables) => {
        //queryClient.invalidateQueries(['sapFlowParams', variables.sapflowid], { refetchInactive: true });
      },
    }
  );
};

export default useSapFlowParamsUpdate;
