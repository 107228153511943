import { useQuery } from 'react-query';
import { FileDetails } from '../../types';
import { useAxiosInstance } from '../common';

const endpoint = 'file_manager/get_reusable_items';

const useReusableItems = (userId: string) => {
  const axios = useAxiosInstance();
  return useQuery(['reusableItems', userId], async () => {
    const params = {
      user_id: userId,
    };
    const result = await axios.get<FileDetails[]>(endpoint, { params });
    return result.data;
  });
};

export default useReusableItems;
