import React, { useEffect, useState } from 'react';
import {
  Upload,
  UploadOnBeforeUploadEvent,
  UploadOnStatusChangeEvent,
  UploadFileStatus,
} from '@progress/kendo-react-upload';
import { Typography } from '@progress/kendo-react-common';
import { Switch } from '@progress/kendo-react-inputs';
import { ComboBox } from '@progress/kendo-react-dropdowns';
import { useConsumeSapFlowWizardState } from '../../context/sapFlowWizard';
import { useSapFlow, useRunSapFlow, useFileFormat, useReusableItems } from '../../hooks/sapflow';
import { useUser } from '../../hooks/authentication';
import { useAuthorization } from '../../hooks/common';
import { formatSizeUnits } from '../../converters/formatSizeUnits';
import { SapFlowItemNode, RequiredFileStatus } from '../../types';

interface Props {
  itemnode: SapFlowItemNode;
  handleStatusChange?: (newFileStatus: RequiredFileStatus, itemId: string) => void;
}

const InputDetailSelection: React.FC<Props> = ({ itemnode, handleStatusChange }) => {
  const { getUser } = useUser();
  const [uploading, setUploading] = React.useState<boolean>(false);
  const [requiredFileStatus, setRequiredFileStatus] = useState<RequiredFileStatus>({
    ProvisionType: 'Upload',
    Uploaded: false,
    FileToReuse: null,
  });
  const { sapFlow, project } = useConsumeSapFlowWizardState();
  const { getSuppliedItems, getUploadUrl, getFileFormatNames, getExtensions } = useSapFlow();
  const fileFormatQuery = useFileFormat(getFileFormatNames(sapFlow));
  const { addAuthorizationHeader } = useAuthorization();
  const reusableItemsQuery = useReusableItems(getUser().id);

  const handleBeforeUpload = (event: UploadOnBeforeUploadEvent) => {
    addAuthorizationHeader(event.headers, getUser());
    setUploading(true);
  };

  const itemRender = (fileDetail: any) => {
    const itemChildren = (
      <div
        style={{
          display: 'flex',
          width: '100%',
          justifyContent: 'space-between',
          alignItems: 'center',
          paddingLeft: '1rem',
        }}
      >
        <Typography.h5 fontWeight="light" textAlign="center">
          {fileDetail.name}
          <Typography.h6 style={{}}>{formatSizeUnits(fileDetail.size)}</Typography.h6>
        </Typography.h5>
        <Typography.h6 style={{}}>{fileDetail.createdDate}</Typography.h6>
      </div>
    );
    return itemChildren;
  };

  const comboItemRender = (li: any, itemProps: any) => {
    const index = itemProps.index;
    const fileDetail = itemProps.dataItem;
    const itemChildren = itemRender(fileDetail);
    return React.cloneElement(li, li.props, itemChildren);
  };

  const handleUploadStatusChange = (event: UploadOnStatusChangeEvent, itemId: string) => {
    const newRequiredFileStatus = { ...requiredFileStatus };
    newRequiredFileStatus.FileToReuse = null;
    newRequiredFileStatus.Uploaded = event.newState[0].status === UploadFileStatus.Uploaded;
    setRequiredFileStatus(newRequiredFileStatus);
    handleStatusChange(newRequiredFileStatus, itemnode.id);
  };

  return (
    <div className="my-4" style={{ display: 'flex', flexDirection: 'column' }}>
      <div className="mb-2" style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
        <span style={{ marginRight: '8px' }}>Upload</span>
        <Switch
          className="neutral"
          onChange={(event) => {
            const newRequiredFileStatus = { ...requiredFileStatus };
            newRequiredFileStatus.ProvisionType = event.target.value ? 'Reuse' : 'Upload';
            newRequiredFileStatus.FileToReuse = null;
            setRequiredFileStatus(newRequiredFileStatus);
            handleStatusChange(newRequiredFileStatus, itemnode.id);
          }}
          checked={requiredFileStatus.ProvisionType === 'Reuse'}
          disabled={
            reusableItemsQuery.isLoading || !reusableItemsQuery.isSuccess || reusableItemsQuery.data.length === 0
          }
        />
        <span style={{ marginLeft: '8px' }}>Reuse</span>
      </div>
      {requiredFileStatus.ProvisionType === 'Reuse' && (
        <ComboBox
          style={{ height: '68px' }}
          data={reusableItemsQuery.data}
          itemRender={comboItemRender}
          valueRender={
            requiredFileStatus.FileToReuse
              ? () => itemRender(requiredFileStatus.FileToReuse)
              : () => (
                  <Typography.h6
                    style={{ width: '100%', marginTop: 'auto', marginBottom: 'auto', paddingLeft: '1rem' }}
                  >
                    Select a file to reuse
                  </Typography.h6>
                )
          }
          value={requiredFileStatus.FileToReuse}
          onChange={(event) => {
            const newRequiredFileStatus = { ...requiredFileStatus };
            newRequiredFileStatus.ProvisionType = 'Reuse';
            newRequiredFileStatus.FileToReuse = event.value;
            newRequiredFileStatus.Uploaded = false;
            setRequiredFileStatus(newRequiredFileStatus);
            handleStatusChange(newRequiredFileStatus, itemnode.id);
          }}
        />
      )}
      {requiredFileStatus.ProvisionType === 'Upload' && (
        <Upload
          key={itemnode.id}
          id={itemnode.id}
          batch={false}
          defaultFiles={[]}
          withCredentials={false}
          saveUrl={getUploadUrl(getUser().id, project.id, sapFlow.id, itemnode.id)}
          multiple
          accept={getExtensions(itemnode.fileformat, fileFormatQuery.data).join(', ')}
          restrictions={{
            allowedExtensions: getExtensions(itemnode.fileformat, fileFormatQuery.data),
          }}
          onBeforeUpload={handleBeforeUpload}
          onStatusChange={(e) => handleUploadStatusChange(e, itemnode.id)}
          selectMessageUI={() => <span>{`Upload your ${itemnode.fileformat.toUpperCase()} file`}</span>}
        />
      )}
    </div>
  );
};

export default InputDetailSelection;
