export enum PotreePanel {
  APPEARANCE,
  TOOLS,
  SCENE,
  FILTERS,
  ABOUT,
}

export enum LayerType {
  OctreeBin = 'OctreeBin',
  Pointcloud = 'Pointcloud',
  Shapefile = 'ShapeFile',
  Geotiff = 'Geotiff',
  CesiumViewer = 'CesiumViewer',
  PGPointCloud = 'PGPointCloud',
  WFS = 'WFS',
  WMS = 'WMS',
  GeoJSON = 'GeoJSON',
  LAS = 'LAS',
  LAZ = 'LAZ',
  UNKNOWN = 'UNKNOWN',
}

export interface ViewLayer {
  id: string;
  uri: string;
  layerType?: LayerType;
  params?: any;
  displayName: string;
  active?: boolean;
  node?: any;
}

export interface SapFlowViewConfig {
  id: string;
  createdBy: string;
  createdDate: string;
  lastModifiedBy: string;
  lastModifiedDate: string;
  pageName: string;
  project?: { id: string; name: string };
  pageDescription: string;
  pointBudget: number;
  visiblePanels: PotreePanel[];
  cameraPosition?: number[];
  cameraTarget?: number[];
  layers: ViewLayer[];
  classificationSchemas?: any; // Check the Dummy Data of useSapFlowView for example
}
