import React, { useReducer, useContext } from 'react';
import { LOCAL_STORAGE_AUTH_KEY, LOCAL_STORAGE_CURRENT_ORG } from '../../common/constants';
import { AppState, initialState } from './appState';
import { appReducer, AppAction } from './appReducer';

interface Props {
  children: JSX.Element;
}

interface AppProviderValue extends AppState {
  dispatch: (action: AppAction) => void;
}

const AppContext = React.createContext({
  ...initialState,
  dispatch: () => {
    // not implemented
  },
} as AppProviderValue);

export const AppProvider: React.FC<Props> = ({ children }) => {
  const user = JSON.parse(localStorage.getItem(LOCAL_STORAGE_AUTH_KEY));
  const currentOrg = JSON.parse(localStorage.getItem(LOCAL_STORAGE_CURRENT_ORG));
  const [state, dispatch] = useReducer(appReducer, {
    ...initialState,
    user,
    availableOrganizations: user?.organizations,
    selectedOrganization: currentOrg,
  });

  return (
    <AppContext.Provider
      value={{
        ...state,
        dispatch,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => {
  return useContext(AppContext);
};
