import { useQuery } from 'react-query';
import { SapFlowProjectWithSapFlows } from '../../types';
import { useAxiosInstance } from '../common';

const endpoint = 'project/project_by_id';

const useProject = (projectId: string) => {
  const axios = useAxiosInstance();
  return useQuery(['project', projectId], async () => {
    const params = { project_id: projectId };
    const result = await axios.get<SapFlowProjectWithSapFlows>(endpoint, { params });
    return result.data;
  });
};

export default useProject;
