import { useQuery, useMutation, useQueryClient } from 'react-query';
import { SapFlowViewConfig, LayerType } from '../../types';
import { useAxiosInstance } from '../common';

const endpoint = 'project/viewer_config';

interface Params {
  configid: string;
  userid: string;
  projectid: string;
}

const useSapFlowViewUpdate = (userId: string, transactionId: string) => {
  const axios = useAxiosInstance();
  const queryClient = useQueryClient();
  return useMutation(
    async (params: SapFlowViewConfig) => {
      const response = await axios.post<SapFlowViewConfig>(endpoint, params, {
        params: {
          transaction_id: transactionId,
          user_id: userId,
        },
      });
      return response.data;
    },
    {
      onSuccess: (_data, variables) => {
        queryClient.invalidateQueries(['sapFlowView', userId, transactionId], { refetchInactive: true });
      },
    }
  );
};

export default useSapFlowViewUpdate;
