import { useQuery } from 'react-query';
import { LogException } from '../../types';
import { useAxiosInstance } from '../common/';

const endpoint = '/logging/getLogExceptions';

const useLogExceptions = () => {
  const axios = useAxiosInstance();
  return useQuery('logExceptions', async () => {
    const result = await axios.get<LogException[]>(endpoint);
    return result.data;
  });
};

export default useLogExceptions;
