import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Typography } from '@progress/kendo-react-common';
import { Skeleton } from '@progress/kendo-react-indicators';
import { SapFlowInfo } from '../../types';
import { toSapFlowInfo } from '../../converters/sapFlowInfo';
import { useTransaction, useTransactionFileDetails } from '../../hooks/sapflow';
import { LinkButton } from '../../components/layout';
import Summary from './Summary';
import Files from './Files';

const SapFlowDetails: React.FC = () => {
  const [sapFlowInfo, setSapFlowInfo] = useState<SapFlowInfo>(null);
  const { transactionId } = useParams();
  const transactionQuery = useTransaction(transactionId);
  const { getInputFileDetails, getOutputFileDetails } = useTransactionFileDetails();
  const inputFileQuery = getInputFileDetails(transactionId);
  const outputFileQuery = getOutputFileDetails(transactionId);

  useEffect(() => {
    if (transactionQuery.isSuccess) {
      setSapFlowInfo(toSapFlowInfo(transactionQuery.data));
    }
  }, [transactionQuery.isSuccess]);

  return (
    <div className="container-fluid p-2 p-lg-5">
      <div className="row">
        <div className="col pb-5">
          <div className="row gx-3 gy-2">
            <div className="col col-sm-auto d-flex justify-content-center">
              <LinkButton label="Run SAPFlow" to={`/sapflow/123`} />
            </div>
            <div className="col col-sm-auto d-flex justify-content-center">
              <LinkButton label="New Project" to="/sapflow/new" />
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <Typography.h3 className="text-uppercase">
            {transactionQuery.isLoading && <Skeleton shape="text" />}
            {transactionQuery.isSuccess && sapFlowInfo && (
              <>
                <Link to="/dashboard" style={{ color: 'var(--geosap-body-text)' }}>
                  Projects
                </Link>
                {' > '}
                <Link to={`/project/${sapFlowInfo.projectId}`} style={{ color: 'var(--geosap-body-text)' }}>
                  {sapFlowInfo.projectName}
                </Link>
                {' > '}
                <span style={{ color: 'var(--geosap-k-button-md)' }}>{sapFlowInfo.sapFlow.name}</span>
              </>
            )}
          </Typography.h3>
        </div>
      </div>
      <div className="row pt-5 gx-5">
        <div className="col-12 col-lg-4">
          <div className="row gy-4">
            <div className="col-12">
              <Typography.h4>Sap Flow Details</Typography.h4>
              {transactionQuery.isLoading && (
                <Skeleton shape="rectangle" className="w-100" style={{ height: '600px' }} />
              )}
              {transactionQuery.isSuccess && sapFlowInfo && <Summary sapFlowInfo={sapFlowInfo} />}
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-8">
          <Files
            isLoading={transactionQuery.isLoading || inputFileQuery.isLoading || outputFileQuery.isLoading}
            isSuccess={transactionQuery.isSuccess && inputFileQuery.isSuccess && outputFileQuery.isSuccess}
            inputFileData={inputFileQuery.data}
            outputFileData={outputFileQuery.data}
          />
        </div>
      </div>
    </div>
  );
};

export default SapFlowDetails;
