import React from 'react';
import { Typography } from '@progress/kendo-react-common';
import { SapFlowInfo } from '../../types';

const SummaryDeliverables: React.FC<SapFlowInfo> = ({ sapFlow, deliverables }) => {
  return (
    <div style={{ fontSize: '0.8rem' }}>
      <p className="px-4">The following deliverables are output through the {sapFlow.name} SAPFlow:</p>
      <ul className="overflow-auto" style={{ maxHeight: '500px' }}>
        {deliverables.map(({ id, title, description, outputExtension }, index) => (
          <li key={id} className={`border border-dark px-4 py-3 rounded-3 ${index ? 'mt-2' : ''}`}>
            <Typography.h6>{title}</Typography.h6>
            <p className="mb-0">
              <strong>Description: </strong>
              {description}
              <br />
              <strong>Output format: </strong>
              {outputExtension}
              <br />
            </p>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SummaryDeliverables;
