import React, { useEffect, useState } from 'react';
import { Notification } from '@progress/kendo-react-notification';
import { Fade } from '@progress/kendo-react-animation';
import { useAppContext } from '../../context/app';
import {useWebsocketClient} from "../../hooks/messaging";

const ServerStatus: React.FC = () => {
    const [connected, setConnected] = useState<boolean>();
    const { isConnected } = useWebsocketClient();


    useEffect(() => {
        console.log("Status connected: " + isConnected());
        setConnected(isConnected());
    }, [isConnected()]);

    const renderServerStatus = () => {
        if (connected) {
            return <div>🟢</div>;
        } else {
            return <div>🔴</div>;
        }
    }

    return (
            <div>
                {renderServerStatus()}
            </div>
    )

};



export default ServerStatus;