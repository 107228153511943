import React, { Fragment, useEffect } from 'react';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import { Typography } from '@progress/kendo-react-common';
import { Reveal } from '@progress/kendo-react-animation';
import { Skeleton } from '@progress/kendo-react-indicators';
import { ExpansionPanel, ExpansionPanelActionEvent, ExpansionPanelContent } from '@progress/kendo-react-layout';
import { SapFlowConfig } from '../../types';
import { truncateText } from '../../common/stringHelper';
import { useConsumeSapFlowWizardState } from '../../context/sapFlowWizard';
import { useSapFlow, useConfigs, useCreateTransaction, useCreateProject } from '../../hooks/sapflow';
import { useUser } from '../../hooks/authentication';
import { SubmitButton } from '../../components/form';
import TransitionAnimation from './TransitionAnimation';

declare const window: any;

interface FormValues {
  sapFlowConfig: SapFlowConfig | null;
}

const StyledExpansionPanel = styled(ExpansionPanel)`
  background: var(--geosap-card-bg-color);
  border: 1px solid var(--geosap-card-bg-color);
  border-radius: 0.6rem;
  margin: 0.75rem 0;

  .k-expander {
    &-title .k-h3 {
      color: var(--bs-body-color);
    }

    &-content {
      padding: 0;
    }

    &-indicator {
      background: var(--geosap-light);
      border-radius: 50%;

      & .k-icon {
        font-size: 40px;
        color: var(--geosap-primary-color);
      }
    }
  }

  &.k-expanded {
    & .k-expander-indicator {
      background: none;
      border-radius: 0%;
    }
  }

  &.k-state-focus {
    box-shadow: none;
  }
`;

const StyledListItem = styled.li`
  border-top: 1px solid var(--geosap-sapflow-card-border-color);

  &:last-child {
    border-bottom: 1px solid var(--geosap-sapflow-card-border-color);
  }
`;

const StyledNumberDiv = styled.div`
  width: 3rem;
  background: var(--geosap-process-number-background);
`;

const SelectSapFlowStep: React.FC = () => {
  const { handleSubmit, setValue, watch, reset } = useForm<FormValues>({
    defaultValues: { sapFlowConfig: null },
  });
  const { category, sapFlowType, project, projectName, sapFlowConfig, dispatch } = useConsumeSapFlowWizardState();
  const configsQuery = useConfigs(category.name, sapFlowType.name);
  const { sortConfigProcessNode } = useSapFlow();
  const transactionMutation = useCreateTransaction();
  const { getUser } = useUser();
  const projectMutation = useCreateProject();

  const selectSapFlow = async ({ sapFlowConfig: config }: FormValues) => {
    if (sapFlowConfig && config.id === sapFlowConfig.id) {
      window.dataLayer.push({
        event: 'Run SAPflow',
        step: '4',
        stepName: 'SAPFlow Selection',
        projectName: projectName,
        workflowType: category.name,
        processType: sapFlowType.name,
        sapFlowType: config.name,
      });
      dispatch({ type: 'SELECT_SAPFLOW', payload: { sapFlowConfig: config } });
      return;
    }
    const userId = getUser().id;
    const newProject = await projectMutation.mutateAsync({
      user_id: userId,
      project_id: project ? project.id : '',
      project_name: projectName,
    });
    const sapFlow = await transactionMutation.mutateAsync({
      configid: config.id,
      userid: getUser().id,
      projectid: newProject.id,
    });
    window.dataLayer.push({
      event: 'Run SAPflow',
      step: '4',
      stepName: 'SAPFlow Selection',
      projectName: projectName,
      workflowType: category.name,
      processType: sapFlowType.name,
      sapFlowType: config.name,
    });
    dispatch({
      type: 'SELECT_SAPFLOW',
      payload: { sapFlowConfig: config, sapFlow, project: newProject },
    });
  };

  const handleExpansionPanelAction = (selected: SapFlowConfig | null, event: ExpansionPanelActionEvent) => {
    setValue('sapFlowConfig', event.expanded ? null : selected, { shouldDirty: true });
  };

  const sapFlowConfigValue = watch('sapFlowConfig');

  useEffect(() => {
    if (sapFlowConfig) {
      reset({ sapFlowConfig });
    }
  }, [sapFlowConfig]);

  return (
    <TransitionAnimation>
      <>
        <Typography.h2 fontWeight="light" textAlign="center">
          Select a SAPflow to Classify LiDAR
        </Typography.h2>
        <form onSubmit={handleSubmit(selectSapFlow)}>
          <div className="m-auto mt-4 w-75">
            {configsQuery.isLoading && (
              <div>
                {new Array(5)
                  .fill(<Skeleton shape="rectangle" style={{ width: '100%', height: '74px' }} className="my-3" />)
                  .map((skeleton, i) => (
                    <Fragment key={i}>{skeleton}</Fragment>
                  ))}
              </div>
            )}
            {configsQuery.isSuccess &&
              configsQuery.data.map((item) => (
                <StyledExpansionPanel
                  key={item.id}
                  title={
                    <>
                      <Typography.h3 className="flex-grow-1 text-capitalize">
                        {truncateText(item.name, 46)}
                      </Typography.h3>
                    </>
                  }
                  expanded={sapFlowConfigValue?.id === item.id}
                  tabIndex={0}
                  onAction={handleExpansionPanelAction.bind(undefined, item)}
                  expandIcon="k-icon k-i-plus"
                  collapseIcon="k-icon k-i-plus-circle"
                >
                  <Reveal>
                    {sapFlowConfigValue?.id === item.id && (
                      <ExpansionPanelContent>
                        <Typography.h4 fontWeight="light" className="px-4 pb-2">
                          Processes within
                        </Typography.h4>
                        <ul style={{ background: 'var(--geosap-light)' }}>
                          {sortConfigProcessNode(item).map((stepDesc, index) => (
                            <StyledListItem key={stepDesc.id}>
                              <div className="d-flex">
                                <StyledNumberDiv className="d-flex justify-content-center py-2">
                                  <Typography.p fontSize="medium" className="m-0">
                                    {index + 1}
                                  </Typography.p>
                                </StyledNumberDiv>
                                <div className="p-2">
                                  <Typography.p fontSize="medium" className="text-break m-0">
                                    {stepDesc.displayname}
                                  </Typography.p>
                                </div>
                              </div>
                            </StyledListItem>
                          ))}
                        </ul>
                        <div className="d-flex justify-content-end my-4 me-4">
                          <SubmitButton
                            label="Select SAPFlow"
                            uppercase={false}
                            full={false}
                            loading={transactionMutation.isLoading}
                          />
                        </div>
                      </ExpansionPanelContent>
                    )}
                  </Reveal>
                </StyledExpansionPanel>
              ))}
          </div>
        </form>
      </>
    </TransitionAnimation>
  );
};

export default SelectSapFlowStep;
