import React, { Component } from 'react';

type Props = {
  children: JSX.Element;
};

type State = {
  hasError: boolean;
};

class ErrorBoundary extends Component<Props, State> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: any) {
    // Update state to trigger fallback UI
    return { hasError: true };
  }

  componentDidCatch(error: any, errorInfo: any) {
    console.log('ERROR HAPPENED!!!!!  ' + error);
    console.log('ERROR INFO!!!!!  ' + errorInfo);
    // Log error or send logging data to log management tool
  }

  render() {
    if (this.state.hasError) {
      // Render fallback UI

      return <h1>Something went wrong.</h1>;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
