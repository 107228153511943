import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Typography } from '@progress/kendo-react-common';
import { REQUIRED_FIELD, VALID_EMAIL_FIELD } from '../common/constants';
import { useSignup, useUser } from '../hooks/authentication';
import { CentralizedContent } from '../components/layout';
import { Input, SubmitButton } from '../components/form';

interface RegisterFormValues {
  email: string;
  password: string;
  passwordConfirmation: string;
}

const validationSchema = yup.object({
  email: yup.string().required(REQUIRED_FIELD).email(VALID_EMAIL_FIELD),
  password: yup.string().required(REQUIRED_FIELD).min(6, 'Password should have at least 6 characters'),
  passwordConfirmation: yup
    .string()
    .required(REQUIRED_FIELD)
    .test('passwords-match', "Password confirmation doesn't match", (value, { parent }) => value === parent.password),
});

const Register: React.FC = () => {
  const { isAuthenticated } = useUser();
  const navigate = useNavigate();
  const { handleSubmit, control } = useForm<RegisterFormValues>({
    resolver: yupResolver(validationSchema),
  });

  const signupMutation = useSignup(() => navigate('/login', { replace: true }));

  const signup = async (data: RegisterFormValues) => {
    signupMutation.reset();
    signupMutation.mutate(data);
  };

  useEffect(() => {
    if (isAuthenticated()) {
      navigate('/', { replace: true });
    }
  }, []);

  return (
    <CentralizedContent>
      <div className="col-xs-12 col-lg-6 col-xl-4">
        <Typography.h2 textAlign="center" fontWeight="light">
          Get Started with GeoSAP
        </Typography.h2>
        <Typography.p textAlign="center" className="py-2">
          To create an account provide the information below:
        </Typography.p>
        <form onSubmit={handleSubmit(signup)}>
          <Controller
            name="email"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <Input {...field} type="text" placeholder="Email Address" maxLength={50} error={error?.message} />
            )}
          />
          <Controller
            name="password"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <Input {...field} type="password" placeholder="Password" maxLength={40} error={error?.message} />
            )}
          />
          <Controller
            name="passwordConfirmation"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <Input {...field} type="password" placeholder="Confirm Password" maxLength={40} error={error?.message} />
            )}
          />
          <div className="pt-3">
            <SubmitButton label="Start" loading={signupMutation.isLoading} />
          </div>
        </form>
      </div>
    </CentralizedContent>
  );
};

export default Register;
