import React from 'react';
import { Typography } from '@progress/kendo-react-common';
import { SapFlowInfo } from '../../types';

const SummaryProcesses: React.FC<SapFlowInfo> = ({ processes }) => {
  return (
    <div style={{ fontSize: '0.8rem' }}>
      <ul className="overflow-auto" style={{ maxHeight: '500px' }}>
        {processes.map(({ id, title, description }, index) => (
          <li key={id}>
            <div className="px-4 py-3 rounded-3" style={{ background: 'var(--geosap-kendo-button-bg)' }}>
              <Typography.h6>
                {index + 1}. {title}
              </Typography.h6>
              <p className="mb-0">{description}</p>
            </div>
            <div className="py-2 d-flex justify-content-center">
              <svg height="22.0" width="50" id="canvas">
                <polygon points="0,0 50,0 25.0,22.0" style={{ fill: 'var(--geosap-grid-header-bg' }}></polygon>
              </svg>
            </div>
          </li>
        ))}
      </ul>
      <div
        style={{ background: 'var(--geosap-grid-header-bg)', color: 'var(--geosap-primary-color)' }}
        className="text-center py-2 fw-bold rounded-3 mt-2"
      >
        SAPFlow Complete
      </div>
    </div>
  );
};

export default SummaryProcesses;
