import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { CaretRightFill, CaretLeftFill } from 'react-bootstrap-icons';
import { Typography } from '@progress/kendo-react-common';
import { Skeleton } from '@progress/kendo-react-indicators';
import { SapFlowProject } from '../../types';

interface Props {
  isLoading: boolean;
  isSuccess: boolean;
  data: SapFlowProject[];
}

const PAGE_SIZE = 5;

const StyledLink = styled(Link)`
  text-decoration: none;
  background: linear-gradient(#fff, #828282);
  width: 200px;
  height: 120px;
  &:hover {
    color: unset;
    text-decoration: underline;
  }
`;

const StyledPagesBox = styled.span`
  width: 9.5rem;
  display: inline-block;
`;

const StyledPageBtnBox = styled.span<{ active: boolean }>`
  color: ${({ active }) => (active ? 'var(--geosap-secondary-color)' : 'unset')};
  cursor: ${({ active }) => (active ? 'unset' : 'pointer')};
`;

const projectSorter = (projectA: SapFlowProject, projectB: SapFlowProject) =>
  new Date(projectB.lastModifiedDate).getTime() - new Date(projectA.lastModifiedDate).getTime();

const ProjectList: React.FC<Props> = ({ isLoading, isSuccess, data }) => {
  const [currentPage, setCurrentPage] = useState(0);

  const getLastPage = () => Math.ceil(data.length / PAGE_SIZE) - 1;

  const getStartIndex = () => currentPage * PAGE_SIZE;

  const padNumber = (n: number, length: number) => n.toString(10).padStart(length, '0');

  const getPages = (total: number): string => {
    const maxLength = data.length.toString(10).length;
    const start = padNumber(currentPage + 1, maxLength);
    const end = padNumber(getStartIndex() + PAGE_SIZE, maxLength);
    return `Viewing ${start} - ${end} of ${total}`;
  };

  const handleBackBtnClick = () =>
    setCurrentPage((currentState) => (currentState > 0 ? currentState - 1 : currentState));

  const handleNextBtnClick = () =>
    setCurrentPage((currentState) => (currentState < getLastPage() ? currentState + 1 : currentState));

  return (
    <div className="container-fluid px-0 pt-3">
      <div className="row g-3">
        {isLoading && (
          <div className="col-auto">
            <Skeleton shape="rectangle" style={{ height: 120, width: 200 }} />
          </div>
        )}
        {isSuccess &&
          data
            .sort(projectSorter)
            .slice(getStartIndex(), getStartIndex() + PAGE_SIZE)
            .map((item) => (
              <div className="col-auto" key={item.id}>
                <StyledLink
                  to={`/project/${item.id}`}
                  className="d-flex justify-content-center align-items-center rounded-3 text-reset p-1"
                >
                  <span className="pb-4 text-center">{item.name}</span>
                </StyledLink>
              </div>
            ))}
      </div>
      {isSuccess && data.length > PAGE_SIZE && (
        <div className="row-12 pt-2">
          <Typography.p className="pe-2" fontSize="small" fontWeight="bold" themeColor="primary">
            <StyledPagesBox>{getPages(data.length)}</StyledPagesBox>
            <span className="fs-5 mb-1 ms-2">
              <StyledPageBtnBox active={currentPage === 0}>
                <CaretLeftFill onClick={handleBackBtnClick} />
              </StyledPageBtnBox>
              <StyledPageBtnBox active={currentPage === getLastPage()}>
                <CaretRightFill onClick={handleNextBtnClick} />
              </StyledPageBtnBox>
            </span>
          </Typography.p>
        </div>
      )}
    </div>
  );
};

export default ProjectList;
