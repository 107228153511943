import React, { useEffect, useState, useRef } from 'react';
//import * as proj4 from 'proj4';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { useQueries } from 'react-query';
import throttle from 'lodash.throttle';
import { SapFlowViewConfig, LayerType, ViewLayer } from '../../types';
import { useUser } from '../../hooks/authentication';
import useGeolocation from '../../hooks/common/useGeolocation';
import { useSapFlowView, useSapFlowViewUpdate } from '../../hooks/sapflow';
import { isIOS } from '../../common/device';
import {
  SUPPORTED_ATTRIBUTES,
  IGNORED_ATTRIBUTES,
  ELEVATION,
  CLASSIFICATION,
  HEIGHT,
  DEFAULT_ATTRIBUTE_VALUES,
} from './SupportedAttributes';
import AttributeConfig from './AttributeConfigWindow';
import ViewerToolbar from './ViewerToolbar';
import LayersToolbar from './LayersToolbar';

const TabDrawer = styled.div`
  background: white;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  position: absolute;
  left: 0px;
  width: 40px;
  top: 80px;
  z-index: 10;
`;

// To avoid transpiler error on window.Potree  https://stackoverflow.com/questions/56457935/typescript-error-property-x-does-not-exist-on-type-window
declare const window: any;
declare const proj4: any;
//declare const Cesium: any;
//declare const THREE: any;

const Potree = window.Potree;
const Cesium = window.Cesium;
const THREE = window.THREE;
console.log('Potree initialized: ' + Potree ? true : false);

let potreeViewer: any = null;
let cesiumViewer: any = null;

const PotreeViewer: React.FC = () => {
  const [stateViewConfig, setStateViewConfig] = useState<any>(null);
  const [selectedLayer, setSelectedLayer] = useState<any>(null);
  const [sceneLayers, setSceneLayers] = useState<any[]>([]);
  const [currentPointcloudAttributes, setCurrentPointcloudAttributes] = useState<any[]>([]);
  const [selectedPointcloudAttribute, setSelectedPointcloudAttribute] = useState<any>(null);
  const [attributeDialogVisible, setAttributeDialogVisible] = useState<boolean>(false);
  const [viewer, setViewer] = useState<any>(null);
  const [cameraPosition, setCameraPosition] = useState<any>(null);
  const [cameraTarget, setCameraTarget] = useState<any>(null);
  const [layersOpened, setLayersOpened] = useState<boolean>(true);
  const [displayCesium, setDisplayCesium] = useState<boolean>(true);
  const { transactionId } = useParams();
  const { getUser } = useUser();
  const potreeContainerDiv = useRef(null);
  const requestRef = useRef(null);
  const { geolocationAvailable, getCurrentPosition } = useGeolocation();
  const [statePotreeViewer, setStatePotreeViewer] = useState<any>(null);
  const [stateCesiumViewer, setStateCesiumViewer] = useState<any>(null);

  const sapViewQuery = useSapFlowView(getUser().id, transactionId);
  const useSapFlowViewUpdateMutation = useSapFlowViewUpdate(getUser().id, transactionId);

  useEffect(() => {
    if (sapViewQuery.isSuccess) {
      if (sapViewQuery.data && sapViewQuery.data.layers && sapViewQuery.data.layers.length > 0) {
        setStateViewConfig(sapViewQuery.data);
      }
    }
  }, [sapViewQuery.isSuccess, sapViewQuery.data]);

  useEffect(() => {
    if (stateViewConfig === null) {
      return;
    }
    // TODO: This is a patch to deep copy the viewconfig so that the object doesn't get modified with THREEjs Object3D objects. Making the stringification impossible later on.
    // Caused problems in the handleRenameLayer function
    const viewConfig = JSON.parse(JSON.stringify(stateViewConfig));

    // initialize Potree viewer
    const viewerElem = potreeContainerDiv.current;
    if (statePotreeViewer === null) {
      potreeViewer = new Potree.Viewer(viewerElem, {
        useDefaultRenderLoop: false,
      });
    } else {
      potreeViewer = statePotreeViewer;
      potreeViewer.scene.clear();
    }

    potreeViewer.setEDLEnabled(true);
    potreeViewer.setFOV(65);
    potreeViewer.setPointBudget(10 * 1000 * 1000);
    //potreeViewer.setBackground('#444444');
    potreeViewer.setBackground('gradient');
    //viewer.setClipTask(Potree.ClipTask.SHOW_INSIDE);
    if (!isIOS) {
      potreeViewer.useHQ = true;
    }
    // potreeViewer.loadSettingsFromURL();

    potreeViewer.setDescription(`<h3>${viewConfig.pageName}</h3> ${viewConfig.pageDescription}`);

    potreeViewer.setControls(potreeViewer.orbitControls);

    console.log({ potreeViewer });

    potreeViewer.loadGUI(() => {
      potreeViewer.setLanguage('en');
      //document.getElementById('menu_appearance').next().show();
      potreeViewer.toggleSidebar();
      potreeViewer.profileWindow.show();
      potreeViewer.profileWindowController.setProfile(potreeViewer.scene.profiles[0]);
    });

    const loadLayers = async (layersConfig: any) => {
      const loader = new Potree.ShapefileLoader();
      const tempSceneLayers: any[] = [];
      let i = 0;
      for (i = 0; i < layersConfig.length; i++) {
        const layer = layersConfig[i];
        if (layer.layerType === LayerType.OctreeBin || layer.layerType === LayerType.UNKNOWN.valueOf()) {
          console.log('Loading pointcloud');
          let e = null;
          try {
            e = await Potree.loadPointCloud(layer.uri);
          } catch (e: any) {
            console.log('ERROR: ', e);
            return;
          }
          const pointcloud = e.pointcloud;
          const material = pointcloud.material;

          material.activeAttributeName = 'TreeID';
          material.size = 0.5; //layer.materialSize;
          material.pointSizeType = Potree.PointSizeType.ADAPTIVE;
          material.shape = Potree.PointShape.SQUARE;

          material.gradient = Potree.Gradients.RAINBOW;

          pointcloud.visible = layer.active;
          layer.node = pointcloud;
          tempSceneLayers.push(layer);

          potreeViewer.scene.addPointCloud(pointcloud);

          if (i === 0) {
            potreeViewer.fitToScreen();
            potreeViewer.setBackView();
          }
        } else if (layer.layerType === LayerType.Shapefile) {
          try {
            const shpPoints = await loader.load(layer.uri);
            layer.layerType = LayerType.Shapefile;
            layer.node = shpPoints.node;
            layer.node.visible = layer.active;

            tempSceneLayers.push(layer);

            potreeViewer.scene.addShapefile(shpPoints.node);
          } catch (e) {
            console.error(e);
          }
        }
      }

      //if (layersConfig.layers) {
      const layer2: ViewLayer = {
        id: '' + Math.floor(Math.random() * 100000000),
        uri: '',
        displayName: 'Base Map',
        active: false,
        layerType: LayerType.CesiumViewer,
      };
      tempSceneLayers.push(layer2);

      return tempSceneLayers;
    };

    loadLayers(viewConfig.layers).then((layers) => {
      if (layers.length > 0) {
        if (layers[0] && layers[0].node) {
          layers[0].node.visible = true;
        }
        handleLayerElementClick(layers[0], 0);
      }
      setSceneLayers(layers);

      setTimeout(() => {
        if (viewConfig.cameraPosition && viewConfig.cameraPosition.length === 3)
          potreeViewer.scene.view.position.set(
            viewConfig.cameraPosition[0],
            viewConfig.cameraPosition[1],
            viewConfig.cameraPosition[2]
          );
        if (viewConfig.cameraTarget && viewConfig.cameraTarget.length === 3)
          potreeViewer.scene.view.lookAt(
            viewConfig.cameraTarget[0],
            viewConfig.cameraTarget[1],
            viewConfig.cameraTarget[2]
          );
      }, 200);
      setViewer(potreeViewer);
    });

    const initializeCesium = () => {
      cesiumViewer = new Cesium.Viewer('cesiumContainer', {
        useDefaultRenderLoop: false,
        animation: false,
        baseLayerPicker: false,
        //terrainProvider: Cesium.createWorldTerrain(),
        fullscreenButton: false,
        geocoder: false,
        homeButton: false,
        infoBox: false,
        sceneModePicker: false,
        selectionIndicator: false,
        timeline: false,
        navigationHelpButton: false,
        imageryProvider: null, //Cesium.createOpenStreetMapImageryProvider({ url: 'https://a.tile.openstreetmap.org/' }),
        terrainShadows: Cesium.ShadowMode.DISABLED,
      });

      window.cesiumViewer = cesiumViewer;

      const cp = new Cesium.Cartesian3(1496000.8666, 884142.8473, 209.3778);
      cesiumViewer.camera.setView({
        destination: cp,
        orientation: {
          heading: 10,
          pitch: -Cesium.Math.PI_OVER_TWO * 0.5,
          roll: 0.0,
        },
      });
    };

    if (stateCesiumViewer === null) {
      initializeCesium();
      setTimeout(() => {
        setDisplayCesium(false);
      }, 500);
    }

    // Retrieve attribute values from ViewConfig
    if (viewConfig.classificationSchemas) {
      viewConfig.classificationSchemas.forEach((attr: any) => {
        const attrName = attr.name === CLASSIFICATION ? 'DEFAULT' : attr.name;
        if (!DEFAULT_ATTRIBUTE_VALUES[attrName]) {
          DEFAULT_ATTRIBUTE_VALUES[attrName] = {};
        }
        if (!potreeViewer.classifications[attrName]) {
          potreeViewer.classifications[attrName] = {};
        }
        attr.classifications.forEach((attrValue: any) => {
          DEFAULT_ATTRIBUTE_VALUES[attrName][attrValue.code] = attrValue;
          potreeViewer.classifications[attrName][attrValue.code] = {
            visible: true,
            name: attrValue.name,
            color: attrValue.color ? attrValue.color : [0.5, 0.5, 0.5, 1.0],
          };
        });
      });
    }

    if (geolocationAvailable()) {
      getCurrentPosition().then((coords: any) => {
        const source = proj4.defs('EPSG:4326');
        const dest =
          '+proj=tmerc +lat_0=42.5 +lon_0=-72.5 +k=0.999964286 +x_0=500000.00001016 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs';
        const toSceneTransform = proj4(source, dest);
        const newCoord = toSceneTransform.forward([coords.longitude, coords.latitude]);

        potreeViewer.markerTool.createMarker({
          position: [...newCoord, 100],
          description: 'You are here',
        });
      });
    } else {
      console.log('geo not available');
    }

    setStatePotreeViewer(potreeViewer);
    setStateCesiumViewer(cesiumViewer);

    requestRef.current = requestAnimationFrame(loop);
    return () => cancelAnimationFrame(requestRef.current);
  }, [stateViewConfig, setViewer]);

  const attributeChange = (event: any) => {
    const attributeValue = event.target.value;
    const currentPointcloud = selectedLayer.node; //scenePointclouds[selectedLayerIndex];

    if (selectedLayer.layerType === LayerType.OctreeBin) {
      currentPointcloud.material.activeAttributeName = attributeValue.id;
      setAttributeDialogVisible(true);

      setSelectedPointcloudAttribute(attributeValue);
    } else {
      setAttributeDialogVisible(true);

      setSelectedPointcloudAttribute(attributeValue);
    }
  };

  const handleLayerToggle = (layer: any, visible: boolean) => {
    if (layer.layerType === LayerType.CesiumViewer) {
      layer.active = visible;
      setDisplayCesium(visible);
      if (visible) {
        viewer.setBackground(null);
        // Replace the imagery layer to start fetching imagery
        const layers = cesiumViewer.imageryLayers;
        const baseLayer = layers.get(0);
        layers.remove(baseLayer);
        layers.addImageryProvider(
          Cesium.createOpenStreetMapImageryProvider({
            url: 'https://a.tile.openstreetmap.org/',
          })
        );
      } else {
        viewer.setBackground('gradient');
        // Replace the imagery layer to stop fetching imagery
        const layers = cesiumViewer.imageryLayers;
        const baseLayer = layers.get(0);
        layers.remove(baseLayer);
      }
    } else {
      if (layer.node) {
        layer.node.visible = visible;
      }
    }
  };

  const handleLayerElementClick = (layer: any, index: number) => {
    if (layer !== selectedLayer) {
      if (layer.layerType === LayerType.OctreeBin) {
        const attributes = layer.node.pcoGeometry.pointAttributes.attributes;
        const newAttributesOptions: any[] = [];

        let defaultSelected: any = '';
        attributes.forEach((attribute: any) => {
          if (!IGNORED_ATTRIBUTES.includes(attribute.name)) {
            let attrName = attribute.name;
            attrName = attrName[0].toLowerCase() + attrName.slice(1);
            // attrName = attrName.replace(/(?<==)([A-Z])/g, ' $1');
            attrName = attrName.replace(/([^ ][a-z]|[^a-z]|^)\.|\.(?=[^ ])/g, '$1 ');

            attrName = attrName.replace(/\b\w/g, function (c: any) {
              return c.toUpperCase();
            });
            newAttributesOptions.push({ text: attrName, id: attribute.name });
            if (attribute.name === 'TreeID') {
              defaultSelected = { text: attrName, id: attribute.name };
            }
          }
        });
        newAttributesOptions.push({ text: ELEVATION, id: ELEVATION });
        setCurrentPointcloudAttributes(newAttributesOptions);
        setSelectedPointcloudAttribute(defaultSelected);
      } else if (layer.layerType === LayerType.Shapefile) {
        setCurrentPointcloudAttributes([{ text: HEIGHT, id: HEIGHT }]);
        setSelectedPointcloudAttribute({ text: HEIGHT, id: HEIGHT });
      } else {
        setCurrentPointcloudAttributes([]);
        setSelectedPointcloudAttribute(null);
      }

      setSelectedLayer(layer);
    }
  };

  const handleLayerRename = (layer: any, newName: string) => {
    const newViewConfig = JSON.parse(JSON.stringify(stateViewConfig));
    newViewConfig.layers.forEach((viewConfigLayer: any) => {
      if (viewConfigLayer.id === layer.id) {
        viewConfigLayer.displayName = newName;
      }
    });
    useSapFlowViewUpdateMutation.mutate(newViewConfig);
  };

  const assignCameraPosAnTarget = () => {
    setCameraPosition(potreeViewer.scene.view.position);
    setCameraTarget(
      potreeViewer.scene.view.direction
        .clone()
        .multiplyScalar(potreeViewer.scene.view.radius)
        .add(potreeViewer.scene.view.position)
    );
  };

  const throttledAssignment = throttle(assignCameraPosAnTarget, 200);

  const prevPos: any = null;
  const loop = (timestamp: any) => {
    requestRef.current = requestAnimationFrame(loop);
    if (!potreeViewer) return;

    potreeViewer.update(potreeViewer.clock.getDelta(), timestamp);

    throttledAssignment();

    potreeViewer.render();

    if (cesiumViewer && displayCesium /*window.toMap !== undefined*/) {
      const camera = potreeViewer.scene.getActiveCamera();

      const pPos = new THREE.Vector3(0, 0, 0).applyMatrix4(camera.matrixWorld);
      const pRight = new THREE.Vector3(600, 0, 0).applyMatrix4(camera.matrixWorld);
      const pUp = new THREE.Vector3(0, 600, 0).applyMatrix4(camera.matrixWorld);
      const pTarget = potreeViewer.scene.view.getPivot();

      const toCes = (pos: any) => {
        const xy = [pos.x, pos.y];
        const height = pos.z / 3.28084; // TODO: This is a very simple feet to meter conversion. Should probably validate in which case it is necesary
        const mapProjection = proj4.defs('WGS84');
        //proj4(pointcloudProjection, mapProjection);
        const deg = proj4(
          '+proj=tmerc +lat_0=42.5 +lon_0=-72.5 +k=0.999964286 +x_0=500000.00001016 +y_0=0 +ellps=GRS80 +units=ft +no_defs',
          mapProjection
        ).forward(xy);
        const cPos = Cesium.Cartesian3.fromDegrees(...deg, height);

        return cPos;
      };

      const cPos = toCes(pPos);
      const cUpTarget = toCes(pUp);
      const cTarget = toCes(pTarget);

      let cDir = Cesium.Cartesian3.subtract(cTarget, cPos, new Cesium.Cartesian3());
      let cUp = Cesium.Cartesian3.subtract(cUpTarget, cPos, new Cesium.Cartesian3());

      cDir = Cesium.Cartesian3.normalize(cDir, new Cesium.Cartesian3());
      cUp = Cesium.Cartesian3.normalize(cUp, new Cesium.Cartesian3());

      cesiumViewer.camera.setView({
        destination: cPos,
        orientation: {
          direction: cDir,
          up: cUp,
        },
      });

      const aspect = potreeViewer.scene.getActiveCamera().aspect;
      if (aspect < 1) {
        const fovy = Math.PI * (potreeViewer.scene.getActiveCamera().fov / 180);
        cesiumViewer.camera.frustum.fov = fovy;
      } else {
        const fovy = Math.PI * (potreeViewer.scene.getActiveCamera().fov / 180);
        const fovx = Math.atan(Math.tan(0.5 * fovy) * aspect) * 2;
        cesiumViewer.camera.frustum.fov = fovx;
      }
      cesiumViewer.render();
    }
  };

  return (
    <div
      id="potree-root"
      style={{
        height: '100vh',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          height: '100%',
          position: 'relative',
        }}
      >
        {/*MC EDIT HERE. YANICK TO FIX*/}
        {/*projectName={setViewConfig?.name}*/}
        <ViewerToolbar
          viewer={viewer}
          projectName={stateViewConfig?.name}
          currentPointcloudAttributes={currentPointcloudAttributes}
          currentAttributeValue={selectedPointcloudAttribute}
          onAttributeChange={attributeChange}
        />
        <div
          className="potree_container"
          style={{
            display: 'flex',
            width: '100%',
            height: '100%',
            position: 'relative',
          }}
        >
          <AttributeConfig
            visible={attributeDialogVisible}
            attribute={selectedPointcloudAttribute?.id}
            pointcloud={selectedLayer?.node}
            onClose={() => {
              setAttributeDialogVisible(false);
            }}
            viewer={viewer}
          />
          <LayersToolbar
            selectedLayer={selectedLayer}
            onLayerToggle={handleLayerToggle}
            onLayerChange={handleLayerElementClick}
            onRenameLayer={handleLayerRename}
            projectId={stateViewConfig?.project.id}
            projectTitle={stateViewConfig?.pageName}
            transactionId={transactionId}
            layers={sceneLayers}
            layersOpened={layersOpened}
          />
          <div
            id="potree_render_area"
            style={{ left: layersOpened ? 'var(--geosap-viewer-layers-width)' : '0', zIndex: 0 }}
            ref={potreeContainerDiv}
          >
            <div
              id="cesiumContainer"
              style={{
                position: 'absolute',
                width: '100%',
                height: '100%',
                backgroundColor: 'green',
                opacity: displayCesium ? 1 : 0,
              }}
            ></div>
            <TabDrawer
              className="cursor-pointer"
              onClick={() => {
                setLayersOpened(!layersOpened);
              }}
            >
              <span
                className={`animated-transform k-icon k-icon-md ${
                  layersOpened ? 'k-i-arrow-chevron-left' : 'k-i-arrow-chevron-left'
                } ml-auto mr-auto`}
                style={{ transform: layersOpened ? 'rotate(0deg)' : 'rotate(180deg)' }}
              ></span>
            </TabDrawer>
          </div>
        </div>
        <div
          className=""
          style={{
            position: 'absolute',
            zIndex: '1000',
            display: 'flex',
            flexDirection: 'column',
            bottom: 0,
            left: '50%',
          }}
        >
          {cameraPosition && (
            <span>{`Camera position: ${cameraPosition.x.toFixed(4)}, ${cameraPosition.y.toFixed(
              4
            )}, ${cameraPosition.z.toFixed(4)}`}</span>
          )}
          {cameraTarget && (
            <span>{`Camera target: ${cameraTarget.x.toFixed(4)}, ${cameraTarget.y.toFixed(4)}, ${cameraTarget.z.toFixed(
              4
            )}`}</span>
          )}
        </div>
        {/*<div id="potree_sidebar_container"> </div>*/}
      </div>
    </div>
  );
};

export default PotreeViewer;
