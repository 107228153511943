import { FileDetails } from '../../types';
import { useQuery } from 'react-query';
import { useAxiosInstance } from '../common/';

const useProjectFileDetails = () => {
  const endpoint = 'file_manager/proj_input_details';
  const getInputFileDetails = (projectId: string) => {
    const axios = useAxiosInstance();
    return useQuery(
      ['projectInputFileDetails', projectId],
      async () => {
        const params = { proj_id: projectId };
        const result = await axios.get<FileDetails[]>(endpoint, { params });
        return result.data;
      },
      { retry: false }
    );
  };

  const getOutputFileDetails = (projectId: string) => {
    const endpoint = 'file_manager/proj_output_details';
    const axios = useAxiosInstance();
    return useQuery(
      ['projectOutputFileDetails', projectId],
      async () => {
        const params = { proj_id: projectId };
        const result = await axios.get<FileDetails[]>(endpoint, { params });
        return result.data;
      },
      { retry: false }
    );
  };

  return { getInputFileDetails, getOutputFileDetails };
};

export default useProjectFileDetails;
