import React, { MouseEventHandler } from 'react';
import { Loader } from '@progress/kendo-react-indicators';
import { Button } from '@progress/kendo-react-buttons';

interface Props {
  type?: 'submit' | 'button';
  label: string;
  loading?: boolean;
  uppercase?: boolean;
  full?: boolean;
  disabled?: boolean;
  className?: string;
  onClick?: MouseEventHandler;
}

const SubmitButton: React.FC<Props> = ({
  type = 'submit',
  label,
  loading = false,
  uppercase = true,
  full = true,
  disabled = false,
  className,
  onClick,
}) => {
  const buttonLabel = uppercase ? label.toUpperCase() : label;

  return (
    <Button
      disabled={disabled || loading}
      type={type}
      onClick={onClick}
      themeColor="primary"
      className={`${className}${full ? ' w-100' : ''}`}
    >
      <div className="position-relative">
        <span style={{ visibility: loading ? 'hidden' : 'visible' }}>{buttonLabel}</span>
        {loading && (
          <span className="position-absolute top-0 start-0 text-center w-100">
            <Loader size="medium" type="pulsing" themeColor="light" />
          </span>
        )}
      </div>
    </Button>
  );
};

export default SubmitButton;
