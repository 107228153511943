import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Typography } from '@progress/kendo-react-common';
import { Skeleton } from '@progress/kendo-react-indicators';
import { SapFlowItemNode, RequiredFileStatus } from '../../types';
import { useAppContext } from '../../context/app';
import { useConsumeSapFlowWizardState } from '../../context/sapFlowWizard';
import { useSapFlow, useRunSapFlow, useFileFormat, useReuseItem } from '../../hooks/sapflow';
import { useUser } from '../../hooks/authentication';
import { SubmitButton } from '../../components/form';
import TransitionAnimation from './TransitionAnimation';
import InputDetailSelection from './InputDetailSelection';

declare const window: any;

const UploadStep = () => {
  const { dispatch } = useAppContext();
  const { getUser } = useUser();
  const { sapFlow, category, sapFlowType, project, projectName, sapFlowConfig } = useConsumeSapFlowWizardState();
  const { getSuppliedItems, getFileFormatNames } = useSapFlow();
  const { mutate } = useRunSapFlow();
  const { mutateAsync } = useReuseItem();
  const [isSubmitting, setSubmitting] = useState<boolean>(false);
  const [items, setItems] = useState<SapFlowItemNode[]>([]);
  const [fileUploadStatus, setFileUploadStatus] = useState({} as Record<string, RequiredFileStatus>);
  const navigate = useNavigate();
  const { projectId } = useParams();
  const fileFormatQuery = useFileFormat(getFileFormatNames(sapFlow));

  useEffect(() => {
    setItems(getSuppliedItems(sapFlow));
  }, [sapFlow]);

  const handleStatusChange = (newFileStatus: RequiredFileStatus, itemId: string) => {
    setFileUploadStatus((prevState) => ({
      ...prevState,
      [itemId]: newFileStatus,
    }));
  };

  const handleSubmitClick = () => {
    setSubmitting(true);
    // Handle file reusing
    const reusePromises = items.map((item, index) => {
      if (fileUploadStatus[item.id] && fileUploadStatus[item.id].FileToReuse) {
        return mutateAsync({ item_id_old: fileUploadStatus[item.id].FileToReuse.id, item_id_new: item.id });
      }
    });

    Promise.all(reusePromises).then(() => {
      mutate(
        { userid: getUser().id, sapflowid: sapFlow.id, projectid: project.id },
        {
          onSuccess: () => {
            let nbUploads = 0;
            for (const key in fileUploadStatus) {
              if (fileUploadStatus[key] && fileUploadStatus[key].Uploaded) {
                nbUploads = nbUploads + 1;
              }
            }
            window.dataLayer.push({
              event: 'Run SAPflow',
              step: '6',
              stepName: 'SAPFlow Details',
              projectName: projectName,
              workflowType: category?.name,
              processType: sapFlowType?.name,
              sapFlowType: sapFlowConfig?.name,
              numberOfUploads: nbUploads,
              sapFlowId: sapFlow.id,
            });
            const content = 'SAPFlow successfully started';
            dispatch({ type: 'SHOW_NOTIFICATION', payload: { notification: { content, type: 'success' } } });
            navigate(projectId ? `/project/${projectId}` : '/dashboard');
          },
        }
      );
    });
  };

  const isSubmitDisabled = (): boolean => {
    const allSubmitted = items.reduce(
      (submitted, item) =>
        submitted &&
        fileUploadStatus[item.id] &&
        (fileUploadStatus[item.id].Uploaded || fileUploadStatus[item.id].FileToReuse),
      true
    );
    return !allSubmitted;
  };

  return (
    <TransitionAnimation className="col-xs-12 col-lg-8 col-xl-6">
      <>
        <Typography.h2 fontWeight="light" textAlign="center">
          Upload required files
        </Typography.h2>
        <div>
          {fileFormatQuery.isLoading && (
            <Skeleton shape="rectangle" style={{ width: '100%', height: '74px' }} className="my-3" />
          )}
          {fileFormatQuery.isSuccess &&
            items.map((item, index) => {
              return <InputDetailSelection key={index} itemnode={item} handleStatusChange={handleStatusChange} />;
            })}
          <div className="pt-3">
            <SubmitButton
              type="button"
              label="Submit details"
              uppercase={false}
              full={false}
              disabled={isSubmitDisabled()}
              onClick={handleSubmitClick}
              loading={isSubmitting}
            />
          </div>
        </div>
      </>
    </TransitionAnimation>
  );
};

export default UploadStep;
