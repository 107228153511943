import {v4 as uuidv4} from "uuid";

export interface SapFlowInfo {
  credits: number;
  processingTime: number;
  sapFlow: {
    name: string;
    description: string;
    executed: string;
    updated: string;
    files: {
      inputCount: number;
      inputSize: string;
      inputTypes: string[];
      outputSize: string;
    };
    processTypes: string[];
    sapCount: number;
  };
  deliverables: {
    id: string;
    title: string;
    description: string;
    outputExtension: string;
  }[];
  processes: {
    id: string;
    title: string;
    description: string;
  }[];
  projectId: string;
  projectName: string;
}

export const sapFlowInfoMock = {
  credits: 5,
  processingTime: 143,
  sapFlow: {
    name: 'Hydro Flatten Digital Elevation Model (DEM)',
    description:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Eaque dignissimos perferendis distinctio minus laboriosam ipsa veritatis maxime, quia neque. Eligendi eveniet quam laboriosam minus nam dolor corrupti assumenda saepe illo.',
    executed: '08/21/22',
    updated: '08/21/22',
    files: {
      inputCount: 2,
      inputSize: '2.3GB',
      inputTypes: ['.las', '.laz'],
      outputSize: '3GB',
    },
    processTypes: ['Classification', 'Base Processing'],
    sapCount: 4,
  },
  deliverables: [
    {
      id: uuidv4(),
      title: 'Digital Elevation Model (DEM)',
      description: 'Output as a raster image, a GeoTIFF.',
      outputExtension: '.tif',
    },
    {
      id: uuidv4(),
      title: 'Hydro Flatten Digital Elevation Model (DEM)',
      description: 'Output as a raster image, a GeoTIFF.',
      outputExtension: '.tif',
    },
    {
      id: uuidv4(),
      title: 'RSME Report',
      description: 'Text documented outlining the accuracy of the data produced through the SAPFlow.',
      outputExtension: '.txt',
    },
  ],
  processes: [
    {
      id: uuidv4(),
      title: 'Generate Digital Elevation Model (DEM)',
      description:
        'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Quod soluta, repellat eum, optio vero ipsa provident voluptates distinctio tempora incidunt neque repudiandae quibusdam mollitia porro perspiciatis animi omnis, placeat quia.',
    },
    {
      id: uuidv4(),
      title: 'Classify Water Surfaces',
      description:
        'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Quod soluta, repellat eum, optio vero ipsa provident voluptates distinctio tempora incidunt neque repudiandae quibusdam mollitia porro perspiciatis animi omnis, placeat quia.',
    },
    {
      id: uuidv4(),
      title: 'Hydro Flatten Digital Elevation Model (DEM)',
      description:
        'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Quod soluta, repellat eum, optio vero ipsa provident voluptates distinctio tempora incidunt neque repudiandae quibusdam mollitia porro perspiciatis animi omnis, placeat quia.',
    },
  ],
  projectName: '',
};
