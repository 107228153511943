import { SapFlowTransaction, DashboardTransaction, SapFlow } from '../types';

export const toDashboardTransaction = (sapFlowTrans: SapFlowTransaction): DashboardTransaction => ({
  id: sapFlowTrans.id,
  name: sapFlowTrans.sapflowname,
  created: new Date(sapFlowTrans.createdDate),
  status: sapFlowTrans.status,
  projectId: sapFlowTrans.project.id,
  projectName: sapFlowTrans.project.name,
  expanded: false,
  succeeded: !sapFlowTrans.status.toLowerCase().includes('error'),
  progress:
    sapFlowTrans.statusHistory && sapFlowTrans.statusHistory.length > 0
      ? sapFlowTrans.statusHistory[sapFlowTrans.statusHistory.length - 1].transactionPercentComplete
      : 0,
  suppliedFiles: sapFlowTrans.itemnodes.filter((itemNode: any) => {
    return itemNode && itemNode.type === 'SUPPLIED';
  }),
  outputFiles: sapFlowTrans.itemnodes.filter((itemNode: any) => {
    return itemNode && itemNode.properties && itemNode.properties.includes('OUTPUT');
  }),
  visualizableFiles: sapFlowTrans.itemnodes.filter((itemNode: any) => {
    return itemNode && itemNode.properties && itemNode.properties.includes('VISUALIZE');
  }),
});

export const toDashboardTransactionSet = (sapFlowTransSet: SapFlowTransaction[]) =>
  sapFlowTransSet.map((t) => toDashboardTransaction(t));

export const toSapFlow = (dashboardTrans: DashboardTransaction): SapFlow => ({
  id: dashboardTrans.id,
  sapflowname: dashboardTrans.name,
  status: 'CREATED',
  configid: null,
  itemnodes: dashboardTrans.suppliedFiles,
  project: {
    id: dashboardTrans.projectId,
    name: dashboardTrans.projectName,
    description: '',
    user: null,
    lastModifiedDate: '',
  },
});
