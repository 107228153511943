import React, { useEffect, useRef } from 'react';
import { Dialog, Window } from '@progress/kendo-react-dialogs';
import { Zoom } from '@progress/kendo-react-animation';

interface DialogProps {
  show: boolean;
  children: any;
  onClose: any;
  title: any;
  style?: any;
}

const ItemNodeUploadModal: React.FC<DialogProps> = (props: DialogProps) => {
  const [displayed, setDisplayed] = React.useState(false);
  const [appearing, setAppearing] = React.useState(false);
  const prevShowRef = useRef<boolean>();

  useEffect(() => {
    if (prevShowRef.current !== props.show) {
      if (props.show) {
        setDisplayed(true);
        setTimeout(() => {
          setAppearing(true);
        }, 20);
      } else {
        setAppearing(false);
        setTimeout(() => {
          setDisplayed(false);
        }, 200);
      }
    }
    prevShowRef.current = props.show;
  }, [props.show, setDisplayed]);
  //console.log('Show: ' + props.show + ', Appearing: ' + appearing);

  const children = (
    <Window
      className="animated-opacity"
      appendTo={null}
      title={props.title}
      onClose={() => {
        props.onClose();
      }}
      style={{
        maxWidth: '80%',
        minWidth: 'unset',
        height: 'unset',
        left: 'unset',
        top: 'unset',
        opacity: props.show && appearing ? 1 : 0,
        ...props.style,
      }}
      draggable={false}
      resizable={false}
      minimizeButton={() => null}
      maximizeButton={() => null}
    >
      {props.children}
    </Window>
  );

  //const children = props.show ? (
  //  <div
  //    style={{position: "relative", borderRadius: "20px", maxWidth: "600px", padding: 0, color: "#7f7f7f", backgroundColor: "#ffffff", border: "1px solid #dee2e6", }}
  //  >
  //    {props.children}
  //  </div>
  //) : null;

  return (
    <div
      className="animated-background"
      style={{
        display: displayed ? 'flex' : 'none',
        position: 'fixed',
        width: '100vw',
        height: '100vh',
        backgroundColor: `rgba(0,0,0,${props.show && appearing ? 0.5 : 0})`,
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {children}
    </div>
  );
};

export default ItemNodeUploadModal;
