import React from 'react';

interface Props {
  progress: number;
}

const ProgressBar: React.FC<Props> = ({ progress }) => {
  return (
    <>
      <div
        className="progress"
        style={{ position: 'relative', width: '100%', lineHeight: '23px', height: '1.4rem', fontWeight: 'bold' }}
      >
        <div
          className={`progress-bar ${progress === 100 ? '' : 'progress-bar-striped progress-bar-animated'}`}
          style={{ width: `${progress}%` }}
          role="progressbar"
          aria-valuenow={progress}
          aria-valuemin={0}
          aria-valuemax={100}
        ></div>
        <span style={{ position: 'absolute', right: 8, color: progress === 100 ? 'white' : 'unset' }}>
          {progress === 100 ? ' Completed' : progress + '%'}
        </span>
      </div>
    </>
  );
};

export default ProgressBar;
