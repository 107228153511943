import { useQuery } from 'react-query';
import { SapFlowProject } from '../../types';
import { useAxiosInstance } from '../common/';

const endpoint = 'project/projects_by_user';

const useProjects = (userId: string) => {
  const axios = useAxiosInstance();
  return useQuery(
    ['projects', userId],
    async () => {
      const params = { user_id: userId };
      const result = await axios.get<SapFlowProject[]>(endpoint, { params });
      return result.data;
    },
    { retry: false }
  );
};

export default useProjects;
