import React, { useState, useRef } from 'react';
import {
  Upload,
  UploadOnAddEvent,
  UploadOnBeforeUploadEvent,
  UploadOnStatusChangeEvent,
  UploadFileStatus,
  UploadFileInfo,
} from '@progress/kendo-react-upload';
import Dialog from '../../components/Dialog';
import { Typography } from '@progress/kendo-react-common';
import { useQueryClient } from 'react-query';
import styled from 'styled-components';
import { useAuthorization, useAxiosInstance } from '../../hooks/common';
import { useUser } from '../../hooks/authentication';
import { useSapFlow, useCreateTransactionItemNode } from '../../hooks/sapflow';

const StyledTable = styled.table`
  border: 1px solid black;
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 25px;
  background-color: rgb(244, 244, 244);
`;
const StyledTr = styled.tr`
  border: 1px solid black;
  border-collapse: collapse;
`;
const StyledTd = styled.td`
  border: 1px solid black;
  border-collapse: collapse;
  text-align: center;
`;

interface ItemNodeUploadModalProps {
  show: boolean;
  handleClose: any;
  projectId: string;
  transactionId: string;
}

const ItemNodeUploadModal: React.FC<ItemNodeUploadModalProps> = (props: ItemNodeUploadModalProps) => {
  const [uploadItems, setUploadItems] = useState<any>({});
  const { getUser } = useUser();
  const { getUploadUrl } = useSapFlow();
  const { addAuthorizationHeader } = useAuthorization();
  const [fileUploadStatus, setFileUploadStatus] = useState({} as Record<string, boolean>);
  const transactionItemMutation = useCreateTransactionItemNode();
  const uploadRef = useRef<Upload>(null);
  const axios = useAxiosInstance();
  const queryClient = useQueryClient();

  const handleStatusChange = (event: UploadOnStatusChangeEvent) => {
    setFileUploadStatus((prevState) => ({
      ...prevState,
      [0]: event.newState[0].status === UploadFileStatus.Uploaded,
    }));
  };

  const saveFiles = async (
    files: UploadFileInfo[],
    options: { formData: FormData; requestOptions: any },
    onProgress: (uid: string, event: ProgressEvent<EventTarget>) => void
  ) => {
    // First, parse the added files and identify the file types
    let i = 0;
    let fileName = 'Testfile';
    let fileExtension = null;
    for (i = 0; i < files.length; i++) {
      const file = files[i];
      if (file.extension === '.shp') {
        fileName = file.name;
        fileExtension = '.shp';
      }
    }

    // TODO: Then, validate provided files...

    // Create the transaction node for the specified files.
    if (fileExtension !== null) {
      const transactionItem = await transactionItemMutation.mutateAsync({
        sapflowid: props.transactionId,
        name: fileName,
        fileformat: fileExtension,
        visualize: true,
        userid: getUser().id,
        pc_visualize: true,
      });

      i = 0;
      for (i = 0; i < files.length; i++) {
        const formData = new FormData();
        const file = files[i];
        const rawFile = file.getRawFile();
        formData.append('files', rawFile);
        await axios.post(
          getUploadUrl(getUser().id, props.projectId, props.transactionId, transactionItem.id),
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
            onUploadProgress: (progressEvent) => {
              onProgress(file.uid, progressEvent);
              const percentCompleted = Math.round((progressEvent.loaded / progressEvent.total) * 100);
              if (percentCompleted === 100) {
                uploadRef.current.onUploadSuccess(file.uid);
              }
            },
          }
        );
      }
      queryClient.invalidateQueries(['sapFlowView', getUser().id, props.transactionId], {
        refetchInactive: true,
      });
      setTimeout(() => props.handleClose(), 1000);
    } else {
      for (i = 0; i < files.length; i++) {
        const file = files[i];
        onProgress(file.uid, new ProgressEvent('abort'));
      }
      return;
    }

    return { uid: '' };
  };

  return (
    <Dialog
      show={props.show}
      title={<Typography.h3>Add visualization layer</Typography.h3>}
      onClose={() => {
        props.handleClose();
      }}
    >
      <Typography.h5
        style={{
          margin: '25px',
        }}
      >
        The following file types may be uploaded to view in this GeoSAP project:
      </Typography.h5>
      <div style={{ margin: '25px' }}>
        <StyledTable className="ml-auto">
          <tbody>
            <StyledTr>
              <StyledTd>Raster</StyledTd>
              <StyledTd>.tif, .jpg, .asc</StyledTd>
            </StyledTr>
            <StyledTr>
              <StyledTd>Vector</StyledTd>
              <StyledTd>.shp, .dbf, .kmz</StyledTd>
            </StyledTr>
            <StyledTr>
              <StyledTd>Point Cloud</StyledTd>
              <StyledTd>.las, .laz, .pc</StyledTd>
            </StyledTr>
          </tbody>
        </StyledTable>
      </div>

      <div style={{ margin: '25px' }}>
        <Upload
          ref={uploadRef}
          autoUpload={false}
          batch={true}
          defaultFiles={[]}
          withCredentials={false}
          saveUrl={saveFiles}
          accept={'.shp, .dbf'}
          restrictions={{
            allowedExtensions: ['.shp', '.dbf'],
          }}
          onStatusChange={(e) => handleStatusChange(e)}
          selectMessageUI={() => <span>Upload visualization files</span>}
        />
      </div>
    </Dialog>
  );
};

export default ItemNodeUploadModal;
