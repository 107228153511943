import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { CaretDownFill, CaretRightFill } from 'react-bootstrap-icons';
import { Typography } from '@progress/kendo-react-common';
import { GridColumn, GridDetailRowProps, GridCellProps } from '@progress/kendo-react-grid';
import { FileDetails } from '../../types';
import { useTable } from '../../hooks/common';
import { StyledGrid } from '../../components/styled';
import { formatSizeUnits } from '../../converters/formatSizeUnits';
import { v4 as uuidv4 } from 'uuid';

interface RowModel {
  id: string;
  name: string;
  format: string;
  fileSize: string;
  date: string;
  status: string;
  expanded: boolean;
  items: RowModelItem[];
}

type RowModelItem = Omit<RowModel, 'items' | 'expanded'>;

const StyledFilesGrid = styled(StyledGrid)`
  .k-detail-row {
    background: var(--geosap-light);
  }
`;

const mockData: RowModel[] = [
  {
    id: uuidv4(),
    name: 'Uploads',
    format: '',
    fileSize: '0MB',
    date: '-',
    status: '-',
    expanded: false,
    items: [],
  },
  {
    id: uuidv4(),
    name: 'Outputs',
    format: '',
    fileSize: '0MB',
    date: '-',
    status: '-',
    expanded: false,
    items: [],
  },
];

interface Props {
  isLoading?: boolean;
  isSuccess?: boolean;
  inputFileData: FileDetails[];
  outputFileData: FileDetails[];
}

const Files: React.FC<Props> = ({ isLoading, isSuccess, inputFileData, outputFileData }) => {
  const [data, setData] = useState<RowModel[]>(mockData);
  const tableProps = useTable({
    pageSize: 10,
    data,
    sortDescriptor: { field: 'created', dir: 'desc' },
  });

  useEffect(() => {
    if (isSuccess) {
      const newTreeData: any = [
        {
          id: uuidv4(),
          name: 'Uploads',
          fileSize: formatSizeUnits(
            inputFileData.reduce((accumulator, currentValue) => accumulator + currentValue.size, 0)
          ),
          items: inputFileData.map((fileData) => {
            return {
              id: uuidv4(),
              name: fileData.name,
              format: fileData.formats.toString(),
              fileSize: formatSizeUnits(fileData.size),
              date: fileData.createdDate,
            };
          }),
        },
        {
          id: uuidv4(),
          name: 'Outputs',
          fileSize: formatSizeUnits(
            outputFileData.reduce((accumulator, currentValue) => accumulator + currentValue.size, 0)
          ),
          items: outputFileData.map((fileData) => {
            return {
              id: uuidv4(),
              name: fileData.name,
              fileSize: formatSizeUnits(fileData.size),
              date: fileData.createdDate,
            };
          }),
        },
      ];
      setData(newTreeData);
    }
  }, [isSuccess, inputFileData, outputFileData]);

  const SubFiles: React.FC<GridDetailRowProps> = (props) => {
    useEffect(() => {
      let tdElCopy: HTMLTableCellElement = null;
      let colSpanCopy = 0;
      if (divRef.current?.parentElement?.tagName === 'TD') {
        const tdEl = divRef.current.parentElement as HTMLTableCellElement;
        tdElCopy = tdEl;
        colSpanCopy = tdEl.colSpan;
        tdEl.colSpan = tdEl.colSpan + 1;
        tdEl.ariaColIndex = '1';
      }
      return () => {
        if (tdElCopy && colSpanCopy) {
          tdElCopy.colSpan = colSpanCopy;
        }
      };
    }, []);
    const divRef = useRef<HTMLDivElement>();

    return (
      <div ref={divRef}>
        <table>
          <tbody>
            {props.dataItem.items.map((item: RowModelItem) => (
              <tr key={item.id}>
                <td className="ps-5">{item.name}</td>
                <td>{item.format}</td>
                <td>{item.fileSize}</td>
                <td>{item.date}</td>
                <td>{item.status}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  return (
    <div>
      <Typography.h4>Sap Flow Files</Typography.h4>
      <StyledFilesGrid {...tableProps} pageable sortable expandField="expanded" detail={SubFiles}>
        <GridColumn
          field="name"
          title="Name"
          cell={(props: GridCellProps) => {
            return (
              <td>
                <span
                  onClick={() =>
                    setData(data.map((r) => (r.id === props.dataItem.id ? { ...r, expanded: !r.expanded } : { ...r })))
                  }
                  style={{ cursor: 'pointer' }}
                >
                  <span className="pe-2">{props.dataItem.expanded ? <CaretRightFill /> : <CaretDownFill />}</span>
                  {props.dataItem.name}
                </span>
              </td>
            );
          }}
        />
        <GridColumn field="format" title="Format" />
        <GridColumn field="fileSize" title="File Size" />
        <GridColumn field="date" title="Date" />
        <GridColumn field="status" title="Status" />
      </StyledFilesGrid>
    </div>
  );
};

export default Files;
