import React, { FocusEventHandler, ForwardRefRenderFunction } from 'react';
import styled from 'styled-components';
import { FieldWrapper } from '@progress/kendo-react-form';
import { Label, Error } from '@progress/kendo-react-labels';
import { Input as KendoInput, InputChangeEvent } from '@progress/kendo-react-inputs';

interface Props {
  type: 'text' | 'password';
  name: string;
  label?: string;
  placeholder?: string;
  value?: string;
  error?: string;
  maxLength?: number;
  autoFocus?: boolean;
  onChange?: (e: InputChangeEvent) => void;
  onBlur?: FocusEventHandler;
}

const StyledKendoInput = styled(KendoInput)`
  border: 2px solid var(--geosap-input-border-color);
  color: var(--geosap-primary-color);
  &:focus {
    color: var(--geosap-primary-color);
  }
  &::placeholder {
    color: var(--geosap-primary-color);
  }
  &::-webkit-input-placeholder {
    color: var(--geosap-primary-color);
  }
  &::-moz-placeholder {
    color: var(--geosap-primary-color);
    opacity: 1;
  }
`;

const Input: ForwardRefRenderFunction<HTMLInputElement, Props> = (
  { type, name, label, placeholder, value, error, maxLength, autoFocus, onChange, onBlur },
  ref
) => {
  const isValid = !error;

  return (
    <FieldWrapper className="py-2">
      {label && (
        <Label editorId={name} editorValid={isValid}>
          {label}
        </Label>
      )}
      <div className={'k-form-field-wrap'}>
        <StyledKendoInput
          type={type}
          name={name}
          id={name}
          ref={ref}
          onChange={onChange}
          onBlur={onBlur}
          valid={isValid}
          maxLength={maxLength}
          value={value}
          placeholder={placeholder}
          className="p-3"
          autoFocus={autoFocus}
        />
        {!isValid && <Error>{error}</Error>}
      </div>
    </FieldWrapper>
  );
};

export default React.forwardRef(Input);
