import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Typography } from '@progress/kendo-react-common';
import { SapFlowType } from '../../types';
import { useConsumeSapFlowWizardState } from '../../context/sapFlowWizard';
import { useTypes } from '../../hooks/sapflow';
import { SubmitButton } from '../../components/form';
import CardSelector from './CardSelector';
import TransitionAnimation from './TransitionAnimation';

declare const window: any;

interface FormValues {
  processType: SapFlowType | null;
}

const SelectProcessTypeStep: React.FC = () => {
  const { control, setValue, handleSubmit, formState, reset } = useForm<FormValues>({
    defaultValues: {
      processType: null,
    },
  });
  const { category, sapFlowType, projectName, dispatch } = useConsumeSapFlowWizardState();
  const { data, isLoading } = useTypes(category.name);

  const selectProcessType = ({ processType }: FormValues) => {
    window.dataLayer.push({
      event: 'Run SAPflow',
      step: '3',
      stepName: 'Process Selection',
      projectName: projectName,
      workflowType: category.name,
      processType: processType.name,
    });
    dispatch({ type: 'SELECT_PROCESS_TYPE', payload: { sapFlowType: processType } });
  };

  useEffect(() => {
    reset({ processType: sapFlowType });
  }, [sapFlowType]);

  return (
    <TransitionAnimation>
      <>
        <Typography.h2 fontWeight="light" textAlign="center">
          What do you want to do?
        </Typography.h2>
        <form onSubmit={handleSubmit(selectProcessType)}>
          <Controller
            control={control}
            name="processType"
            render={({ field: { value } }) => (
              <CardSelector
                data={data}
                dataItemKey="id"
                textField="name"
                value={value}
                loading={isLoading}
                onChange={(selected) => setValue('processType', selected, { shouldValidate: true, shouldDirty: true })}
              />
            )}
          />
          <div className="pt-3">
            <p className="m-0">Not find a process that works for you?</p>
            <a className="text-decoration-none" href="https://whiteoutsolutions.com/contact-us/">
              Talk to us about custom SAPs.
            </a>
          </div>
          <div className="pt-3 text-center">
            <SubmitButton
              label="Select process type"
              uppercase={false}
              full={false}
              disabled={!formState.isDirty && !sapFlowType}
            />
          </div>
        </form>
      </>
    </TransitionAnimation>
  );
};

export default SelectProcessTypeStep;
