import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Typography } from '@progress/kendo-react-common';
import { REQUIRED_FIELD } from '../../common/constants';
import { SapFlowProject } from '../../types';
import { Input, SubmitButton, Select } from '../../components/form';
import { StyledTextButton } from '../../components/styled';
import { useConsumeSapFlowWizardState } from '../../context/sapFlowWizard';
import { useUser } from '../../hooks/authentication';
import { useProjects } from '../../hooks/sapflow';
import TransitionAnimation from './TransitionAnimation';

declare const window: any;

interface FormValues {
  project: SapFlowProject;
  projectName: string;
}

interface Props {
  newProject: boolean;
  hasProjects: boolean;
}

const REPEATED_PROJECT = 'There is already a project with the same name, please enter another one';

const getValidationSchema = (isCreatingNewProject: boolean, projects: SapFlowProject[] = []) =>
  yup.object({
    project: !isCreatingNewProject ? yup.object().required(REQUIRED_FIELD) : undefined,
    projectName: isCreatingNewProject
      ? yup
          .string()
          .notOneOf(
            projects.map((p) => p.name),
            REPEATED_PROJECT
          )
          .required(REQUIRED_FIELD)
      : undefined,
  });

const CreateProjectStep: React.FC<Props> = ({ newProject, hasProjects }) => {
  const { dispatch, projectName, project } = useConsumeSapFlowWizardState();
  const [isCreatingNewProject, setIsCreatingNewProject] = useState(newProject && !project);
  const [validationSchema, setValidationSchema] = useState(getValidationSchema(isCreatingNewProject));
  const { handleSubmit, control, formState, reset } = useForm<FormValues>({
    defaultValues: {
      projectName: '',
      project: null,
    },
    resolver: yupResolver(validationSchema),
  });
  const { getUser } = useUser();
  const projectQuery = useProjects(getUser().id);

  const submitProject = ({ project, projectName }: FormValues) => {
    if (isCreatingNewProject) {
      window.dataLayer.push({
        event: 'Run SAPflow',
        step: '1',
        stepName: 'Select New Project',
        projectName: projectName,
      });
      dispatch({ type: 'CREATE_PROJECT', payload: { projectName } });
    } else {
      window.dataLayer.push({
        event: 'Run SAPflow',
        step: '1',
        stepName: 'Select Existing Project',
        projectName: project.name,
      });
      dispatch({ type: 'SELECT_PROJECT', payload: { project, projectName: project.name } });
    }
    reset({ projectName: '', project: null });
  };

  const shouldDisplaySelectProject = isCreatingNewProject && hasProjects;
  const shouldDisplayCreateNewProject = !isCreatingNewProject;
  const formHasValue = !!projectName || !!project;

  useEffect(() => {
    setValidationSchema(getValidationSchema(isCreatingNewProject, projectQuery.data));
    if (projectName || project) {
      reset({ projectName: projectName || '', project });
    }
  }, [isCreatingNewProject, projectName, project]);

  useEffect(() => {
    setValidationSchema(getValidationSchema(isCreatingNewProject, projectQuery.data));
  }, [projectQuery.isSuccess]);

  return (
    <TransitionAnimation className="col-xs-12 col-lg-6 col-xl-4">
      <>
        <Typography.h2 fontWeight="light">
          {isCreatingNewProject ? 'Create a new project' : 'Select existing project'}
        </Typography.h2>
        <form onSubmit={handleSubmit(submitProject)}>
          {isCreatingNewProject ? (
            <Controller
              control={control}
              name="projectName"
              render={({ field, fieldState: { error } }) => (
                <Input {...field} error={error?.message} type="text" placeholder="Project Name" autoFocus />
              )}
            />
          ) : (
            <Controller
              control={control}
              name="project"
              render={({ field, fieldState: { error } }) => (
                <Select
                  name={field.name}
                  value={field.value}
                  error={error?.message}
                  data={projectQuery.data}
                  dataItemKey="id"
                  textField="name"
                  placeholder="Existing projects"
                  loading={projectQuery.isLoading}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  autoFocus
                />
              )}
            />
          )}

          <div className="pt-3">
            <SubmitButton
              label={isCreatingNewProject ? 'Create project' : 'Select project'}
              uppercase={false}
              full={false}
              disabled={(!formState.isDirty && !formHasValue) || projectQuery.isLoading}
            />
          </div>

          {(shouldDisplayCreateNewProject || shouldDisplaySelectProject) && (
            <div className="pt-4">
              <StyledTextButton type="button" onClick={() => setIsCreatingNewProject((prevState) => !prevState)}>
                {isCreatingNewProject ? 'Or select new project' : 'Or create new project'}
              </StyledTextButton>
            </div>
          )}
        </form>
      </>
    </TransitionAnimation>
  );
};

export default CreateProjectStep;
