import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { Skeleton } from '@progress/kendo-react-indicators';
import { Typography } from '@progress/kendo-react-common';
import { useProject, useProjectFileDetails } from '../../hooks/sapflow';
import { LinkButton } from '../../components/layout';
import ProjectInfoSection from './ProjectInfoSection';
import ProjectActivitySection from './ProjectActivitySection';
import ProjectFilesSection from './ProjectFilesSection';

const ProjectDetails: React.FC = () => {
  const { projectId } = useParams();
  const projectQuery = useProject(projectId);
  const { getInputFileDetails, getOutputFileDetails } = useProjectFileDetails();
  const inputFileQuery = getInputFileDetails(projectId);
  const outputFileQuery = getOutputFileDetails(projectId);

  return (
    <div className="container-fluid p-2 p-lg-5">
      <div className="row">
        <div className="col pb-5">
          <div className="row gx-3 gy-2">
            <div className="col col-sm-auto d-flex justify-content-center">
              <LinkButton label="Run SAPFlow" to={`/sapflow/${projectId}`} />
            </div>
            <div className="col col-sm-auto d-flex justify-content-center">
              <LinkButton label="New Project" to="/sapflow/new" />
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <Typography.h3 className="text-uppercase">
            {(projectQuery.isLoading || inputFileQuery.isLoading || outputFileQuery.isLoading) && (
              <Skeleton shape="text" />
            )}
            {projectQuery.isSuccess && inputFileQuery.isSuccess && outputFileQuery.isSuccess && (
              <>
                <Link to="/dashboard" style={{ color: 'var(--geosap-body-text)' }}>
                  Projects
                </Link>
                {' > '}
                <span style={{ color: 'var(--geosap-k-button-md)' }}>{projectQuery.data.name}</span>
              </>
            )}
          </Typography.h3>
        </div>
      </div>
      <div className="row pt-5 gx-5">
        <div className="col-12 col-lg-4">
          <div className="row gy-4">
            <div className="col-12">
              <ProjectInfoSection
                isLoading={projectQuery.isLoading || inputFileQuery.isLoading || outputFileQuery.isLoading}
                isSuccess={projectQuery.isSuccess && inputFileQuery.isSuccess && outputFileQuery.isSuccess}
                projectData={projectQuery.data}
                inputFileData={inputFileQuery.data?.filter((file) => file)}
                outputFileData={outputFileQuery.data?.filter((file) => file)}
              />
            </div>
            <div className="col-12">
              <ProjectFilesSection
                isLoading={inputFileQuery.isLoading || outputFileQuery.isLoading}
                isSuccess={inputFileQuery.isSuccess && outputFileQuery.isSuccess}
                inputFileData={inputFileQuery.data?.filter((file) => file)}
                outputFileData={outputFileQuery.data?.filter((file) => file)}
              />
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-8">
          <ProjectActivitySection projectId={projectId} />
        </div>
      </div>
    </div>
  );
};

export default ProjectDetails;
