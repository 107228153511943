import { SapFlowTransaction, SapFlowInfo, sapFlowInfoMock } from '../types';

export const toSapFlowInfo = (transaction: SapFlowTransaction): SapFlowInfo => ({
  ...sapFlowInfoMock,
  sapFlow: {
    ...sapFlowInfoMock.sapFlow,
    name: transaction.sapflowname,
  },
  projectId: transaction.project.id,
  projectName: transaction.project.name,
  processes: transaction.processnodes.map((p) => ({
    id: p.id,
    title: p.displayname,
    description: sapFlowInfoMock.processes[0].description,
  })),
  deliverables: transaction.itemnodes
    .filter((i) => i.type === 'GENERATED')
    .map((i) => ({
      id: i.id,
      title: i.name,
      description: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Quod soluta, repellat eum.',
      outputExtension: i.fileformat,
    })),
});
