import React, { MouseEventHandler, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { Avatar } from '@progress/kendo-react-layout';
import { Typography } from '@progress/kendo-react-common';
import { Activity, PlusSquare } from 'react-bootstrap-icons';
import { useUser, useSignout } from '../../hooks/authentication';
import { Icon } from '../../components/layout';
import { ServerStatus } from '../../components/feedback';

import { ReactComponent as HomeLogo } from '../../assets/icons/navbar-home.svg';
import { ReactComponent as SapFlowLogo } from '../../assets/icons/navbar-sapflow.svg';

const Container = styled.div`
  background: var(--geosap-navbar-color);
`;

const BrandImage = styled.img`
  width: var(--geosap-navbar-width);
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: var(--geosap-navbar-icon-color);
  font-size: 2rem;
  &.selected,
  &:hover {
    background: var(--geosap-navbar-selected-item-color);
    color: var(--geosap-navbar-icon-color);
  }
`;

const LogoutBtn = styled(Typography.p)`
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

const menuOptions = [
  {
    path: 'dashboard',
    icon: <HomeLogo style={{ width: '32px', height: '32px' }} />,
  },
  {
    path: 'sapflow',
    icon: <SapFlowLogo style={{ width: '48px', height: '48px' }} />,
  },
];

const Navbar: React.FC = () => {
  const location = useLocation();
  const [username, setUsername] = useState('');

  const { getUser } = useUser();
  const signoutMutation = useSignout();

  const logoutClickHandler: MouseEventHandler = () => {
    const { email, accessToken: bearerToken } = getUser();
    signoutMutation.mutate({ email, bearerToken });
  };

  const isOptionSelected = (path: string) => location.pathname.slice(1, location.pathname.length).startsWith(path);

  useEffect(() => {
    const user = getUser();
    if (user?.email) {
      const emailName = user.email.split('@').shift();
      setUsername(emailName.length > 10 ? `${emailName.slice(0, 10)}...` : emailName);
    }
  }, []);

  return (
    <Container className="d-flex flex-lg-column">
      <div>
        <Link to="/">
          <BrandImage src="/assets/brand-white-v2.png" alt="GeoSAP brand" className="p-3 pb-4" />
        </Link>
      </div>
      <ul className="flex-grow-1 d-flex flex-lg-column">
        {menuOptions.map(({ path, icon }) => (
          <li key={path}>
            <StyledLink to={`/${path}`} className={`d-block p-4 text-center ${isOptionSelected(path) && 'selected'}`}>
              {icon}
            </StyledLink>
          </li>
        ))}
      </ul>
      <div className="p-3 flex-column d-flex align-items-center">
        <Typography.p fontSize="xsmall" themeColor="light" className="mb-2">
          {username}
        </Typography.p>
        <Avatar rounded="full" size="large" themeColor="light">
          <Icon iconClass="k-i-user" size="56px" color="var(--geosap-profile-icon-color)"></Icon>
        </Avatar>
        <LogoutBtn fontSize="xsmall" themeColor="light" className="m-0 mt-2" onClick={logoutClickHandler}>
          Log out
        </LogoutBtn>
        <ServerStatus></ServerStatus>
      </div>
    </Container>
  );
};

export default Navbar;
