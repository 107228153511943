import { useQuery } from 'react-query';
import { Category } from '../../types';
import { useAxiosInstance } from '../common/';

const endpoint = '/sap_config/get_all_categories';

const useCategories = () => {
  const axios = useAxiosInstance();
  return useQuery('categories', async () => {
    const result = await axios.get<Category[]>(endpoint);
    return result.data;
  });
};

export default useCategories;
