import React, { useEffect } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { useNotification } from '../../hooks/common';
import { useWebsocketClient } from '../../hooks/messaging';
import { useUser } from '../../hooks/authentication';
import { Login, Register, OrganizationSelection } from '../../pages';
import { AppNotification } from '../../components/feedback';
import Page from './Page';
import PrivateRoutes from './PrivateRoutes';
import '../../style/app.scss';

const App: React.FC = () => {
  const location = useLocation();
  const { clearOnRouteChange } = useNotification();
  const { isAuthenticated, organizationSelected, getCurrentOrganization } = useUser();
  const { initWebsocketClient, closeConnection, isConnected, isSubscribedTo, subscribe } = useWebsocketClient();

  useEffect(() => clearOnRouteChange(), [location]);

  useEffect(() => {
    if (isAuthenticated() && !isConnected()) {
      initWebsocketClient(); // Initialize the Websocket connection.
    } else {
      closeConnection();
    }
  }, [isAuthenticated()]);

  useEffect(() => {
    // If Websocket connected
    if (isConnected()) {
      // And an Organization has been selected
      if (organizationSelected()) {
        // And we aren't already subscribed to the organization topic
        if (!isSubscribedTo('/topic/ORGANIZATION/' + getCurrentOrganization().id)) {
          subscribe('/topic/ORGANIZATION/' + getCurrentOrganization().id);
        }
      }
    }
  }, [isConnected(), organizationSelected()]);

  return (
    <div className="App">
      <Routes>
        <Route
          key="login"
          path="/login"
          element={
            <Page title="Signin">
              <Login />
            </Page>
          }
        />
        <Route
          key="register"
          path="/register"
          element={
            <Page title="Signup">
              <Register />
            </Page>
          }
        />
        <Route
          path="/selectorganization"
          element={
            <Page title="Organization Selection">
              <OrganizationSelection />
            </Page>
          }
        />
        <Route key="private" path="*" element={<PrivateRoutes />} />
      </Routes>
      <AppNotification />
    </div>
  );
};

export default App;
