import { useQuery } from 'react-query';
import { FileFormat } from '../../types';
import { useAxiosInstance } from '../common';

const endpoint = 'fileformat/fileformat';

const useFileFormat = (names: string[]) => {
  const axios = useAxiosInstance();
  const keys = [...names];
  keys.sort();
  return useQuery(['fileformat', ...keys], async () => {
    const fileFormatPromises = names.map((name) => {
      const params = { name };
      return axios.get<FileFormat>(endpoint, { params }).then((result) => result.data);
    });
    return Promise.all(fileFormatPromises);
  });
};

export default useFileFormat;
