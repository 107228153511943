import { useQuery } from 'react-query';
import { useAxiosInstance } from '../common/';

const endpoint = 'sapflow/get_sapflow_transactions_by_user';

const useTransactions = (userId: string, refetchInterval: number | false = false) => {
  const axios = useAxiosInstance();

  return useQuery(
    ['transactions', userId],
    async () => {
      const params = { user_id: userId };
      const result = await axios.get(endpoint, { params, data: {} });
      return result.data;
    },
    { refetchInterval }
  );
};

export default useTransactions;
