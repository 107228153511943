import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Typography } from '@progress/kendo-react-common';
import { Skeleton } from '@progress/kendo-react-indicators';
import { REFETCH_INTERVAL } from '../../common/constants';
import { DashboardTransaction } from '../../types';
import { toDashboardTransactionSet } from '../../converters/dashboardTransaction';
import { useUser } from '../../hooks/authentication';
import { useTransactions, useProjects, useDeleteTransaction } from '../../hooks/sapflow';
import { LinkButton } from '../../components/layout';
import { FetchIndicator } from '../../components/feedback';
import ConfirmationDialog from '../../components/ConfirmationDialog';
import TransactionGrid from './TransactionGrid';
import ProjectList from './ProjectList';

const Dashboard: React.FC = () => {
  const [data, setData] = useState<DashboardTransaction[]>([]);
  const navigate = useNavigate();
  const { getUser } = useUser();
  const [deletingTransaction, setDeletingTransaction] = useState<any>(null);

  const transactionsQuery = useTransactions(getUser().id, false /*REFETCH_INTERVAL*/); // Pooling shouldn't be necessary anymoreé Websocket messages controls the refetch.
  const projectsQuery = useProjects(getUser().id);
  const deleteTransactionMutation = useDeleteTransaction();

  const handleDelete = async (transid: string) => {
    setDeletingTransaction(transid);
  };

  const deleteTransaction = async (transid: string) => {
    await deleteTransactionMutation.mutateAsync({
      transid: transid,
    });
    setDeletingTransaction(null);
  };

  const handleExpand = (dataItem: DashboardTransaction) => {
    setData(
      data.map((item) => ({
        ...item,
        expanded: dataItem.id === item.id ? !item.expanded : item.expanded,
      }))
    );
  };

  useEffect(() => {
    if (transactionsQuery.isSuccess) {
      // User has no project/transaction
      if (!transactionsQuery.data.length) {
        navigate('/sapflow/new');
        return;
      }
      setData(toDashboardTransactionSet(transactionsQuery.data));
    }
  }, [transactionsQuery.isSuccess, transactionsQuery.data]);

  return (
    <div className="container-fluid p-5">
      {deletingTransaction && (
        <ConfirmationDialog
          show={true}
          onConfirm={() => {
            deleteTransaction(deletingTransaction);
          }}
          onClose={() => {
            setDeletingTransaction(null);
          }}
          title="Please confirm"
          text="Are you sure you wish to delete this Transaction ?"
        />
      )}
      <div className="row pb-5">
        <div className="col">
          <LinkButton label="Run SAPFlow" to="/sapflow" className="me-3" />
          <LinkButton label="New Project" to="/sapflow/new" />
        </div>
      </div>
      <div className="row pb-5">
        <div className="col">
          <Typography.h4 fontWeight="normal" className="text-uppercase">
            Projects
          </Typography.h4>
          <ProjectList {...projectsQuery} />
        </div>
      </div>
      <div className="row">
        <div className="col">
          <div className="row pb-3 g-3">
            <div className="col-12 col-md-auto">
              <Typography.h4 fontWeight="normal">SAP FLOW ACTIVITY</Typography.h4>
            </div>
            <div className="col-12 col-md-auto">
              <FetchIndicator isFetching={transactionsQuery.isFetching} />
            </div>
          </div>
          {transactionsQuery.isLoading && deleteTransactionMutation.isLoading && (
            <div>
              <Skeleton shape="rectangle" className="w-100" style={{ height: '600px' }} />
            </div>
          )}
          {transactionsQuery.isSuccess && (
            <TransactionGrid data={data} onExpand={handleExpand} onDelete={handleDelete} />
          )}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
