import { useMutation } from 'react-query';
import { LOCAL_STORAGE_AUTH_KEY, LOCAL_STORAGE_CURRENT_ORG } from '../../common/constants';
import { SignoutRequest } from '../../types';
import { useAppContext } from '../../context/app';
import { useAxiosInstance } from '../common';

const endpoint = '/authentication/signout';

const useSignout = () => {
  const { dispatch } = useAppContext();
  const axios = useAxiosInstance();

  return useMutation((user: SignoutRequest) => axios.post(endpoint, user), {
    onSuccess: () => {
      localStorage.removeItem(LOCAL_STORAGE_AUTH_KEY);
      localStorage.removeItem(LOCAL_STORAGE_CURRENT_ORG);
      dispatch({ type: 'LOGOUT' });
    },
  });
};

export default useSignout;
