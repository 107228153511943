import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { GridColumn } from '@progress/kendo-react-grid';
import { Typography } from '@progress/kendo-react-common';
import { Skeleton } from '@progress/kendo-react-indicators';
import { REFETCH_INTERVAL } from '../../common/constants';
import { DashboardTransaction, SapFlowTransactionItemStatus } from '../../types';
import { toDashboardTransactionSet, toSapFlow } from '../../converters/dashboardTransaction';
import { StyledGrid } from '../../components/styled';
import { ProgressBar } from '../../components/feedback';
import { useUser } from '../../hooks/authentication';
import { useTable } from '../../hooks/common';
import { useTransactions } from '../../hooks/sapflow';
import { FetchIndicator } from '../../components/feedback';

interface Props {
  projectId: string;
}

const ProjectActivitySection: React.FC<Props> = ({ projectId }) => {
  const navigate = useNavigate();
  const [data, setData] = useState<DashboardTransaction[]>([]);
  const { getUser } = useUser();
  const transactionsQuery = useTransactions(getUser().id, REFETCH_INTERVAL);
  const tableProps = useTable({
    pageSize: 10,
    data,
    sortDescriptor: { field: 'created', dir: 'desc' },
  });

  const filterByProject = (trans: DashboardTransaction) => trans.projectId === projectId;

  useEffect(() => {
    if (transactionsQuery.isSuccess) {
      setData(toDashboardTransactionSet(transactionsQuery.data).filter(filterByProject));
    }
  }, [transactionsQuery.isSuccess, transactionsQuery.data]);

  return (
    <div>
      <div className="row g-3">
        <div className="col-12 col-md-auto">
          <Typography.h4 fontWeight="normal">SAP FLOW ACTIVITY</Typography.h4>
        </div>
        <div className="col-12 col-md-auto">
          <FetchIndicator isFetching={transactionsQuery.isFetching} />
        </div>
      </div>
      <div className="mt-3">
        {transactionsQuery.isLoading && <Skeleton shape="rectangle" style={{ height: '330px' }} />}
        {transactionsQuery.isSuccess && data && (
          <StyledGrid
            {...tableProps}
            pageable
            sortable
            className="mt-3"
            selectable={{ enabled: true }}
            onRowClick={({ dataItem }) => {
              if (dataItem.status === SapFlowTransactionItemStatus.TRANSACTION_CREATED) {
                // Redirect to sapflow wizard step 5
                navigate(`/sapflow`, { state: { sapflow: toSapFlow(dataItem) } });
              } else {
                navigate(`/details/${dataItem.id}`);
              }
            }}
            rowRender={(trElement, props) => {
              const trProps: any = {
                style: { cursor: 'pointer' },
              };
              return React.cloneElement(
                trElement,
                {
                  ...trProps,
                },
                trElement.props.children
              );
            }}
          >
            <GridColumn
              field="name"
              title="SAP Flow"
              cell={({ dataItem }) => <td className="fw-bold">{dataItem.name}</td>}
            />
            {/* 
                todo: we need to understand what data we want to bring in this column
                <GridColumn field="type" title="SAP Type" /> 
            */}
            <GridColumn field="projectName" title="Project Name" />
            <GridColumn field="created" title="Date" format="{0:h:mm a - M/d/yy}" />
            <GridColumn
              field="status"
              title="Processing Status"
              cell={({ dataItem }) => {
                if (dataItem.status === SapFlowTransactionItemStatus.TRANSACTION_CREATED) {
                  return (
                    <td>
                      <div className="d-flex gap-3" style={{ paddingRight: '1rem' }}>
                        <span>Upload Required</span>
                      </div>
                    </td>
                  );
                } else if (dataItem.succeeded) {
                  return (
                    <td>
                      <div className="d-flex gap-3" style={{ paddingRight: '1rem' }}>
                        <ProgressBar progress={dataItem.progress} />
                      </div>
                    </td>
                  );
                }
              }}
            />
          </StyledGrid>
        )}
      </div>
    </div>
  );
};

export default ProjectActivitySection;
