import jwtDecode, { JwtPayload } from 'jwt-decode';
import { User, Organization } from '../../types';
import { useAppContext } from '../../context/app';

const useUser = () => {
  const { user, selectedOrganization } = useAppContext();

  const getUser = (): User => user;
  const getCurrentOrganization = (): Organization => selectedOrganization;

  const isAuthenticated = (): boolean => {
    if (user?.accessToken) {
      const { exp } = jwtDecode<JwtPayload>(user.accessToken);
      return new Date(exp * 1000) > new Date();
    }
    return false;
  };

  const organizationSelected = (): boolean => {
    return selectedOrganization !== null;
  };

  return {
    getUser,
    isAuthenticated,
    organizationSelected,
    getCurrentOrganization,
  };
};

export default useUser;
