import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { Typography } from '@progress/kendo-react-common';
import { Checkbox } from '@progress/kendo-react-inputs';
import { Popup } from '@progress/kendo-react-popup';
import { Menu, MenuItem } from '@progress/kendo-react-layout';
import { SubmitButton } from '../../components/form';
import { LayerType } from '../../types';
import ItemNodeUploadModal from './ItemNodeUploadModal';
import RenameLayerModal from './RenameLayerModal';

const LayersContainer = styled.div`
  background: white;
  width: var(--geosap-viewer-layers-width);
`;

const LayerElement = styled.div`
  position: relative;
  border-radius: 4px;
  border: 1px solid var(--geosap-steps-background);
  color: var(--geosap-steps-background);
  background: white;
  padding: 8px;
  display: flex;
  flex-direction: column;
  margin-top: 8px;
  font-size: 0.85rem;
  cursor: pointer;
`;

interface LayersToolbarProps {
  projectTitle: string;
  projectId: string;
  layers: any;
  selectedLayer: any;
  layersOpened: boolean;
  transactionId: string;
  //selectedLayerIndex: number;
  onLayerChange(layer: any, index: number): void;
  onLayerToggle(layer: any, visible: boolean): void;
  onRenameLayer(layer: any, newName: string): void;
}

const LayersToolbar: React.FC<LayersToolbarProps> = (props: LayersToolbarProps) => {
  const [pointcloudLayers, setPointcloudLayers] = React.useState([]);
  const [cesiumLayer, setCesiumLayer] = React.useState(null);
  const [shapefileLayers, setShapefileLayers] = React.useState([]);
  const [addingLayer, setAddingLayer] = React.useState(false);
  const [contextMenuLayer, setContextMenuLayer] = React.useState(null);
  const [contextOffset, setContextOffset] = React.useState(null);
  const [renamingLayer, setRenamingLayer] = React.useState(null);
  const [isForeground, setIsForeground] = React.useState<boolean>(props.layersOpened);
  const prevLayersOpenedRef = useRef<boolean>();

  useEffect(() => {
    if (prevLayersOpenedRef.current !== props.layersOpened) {
      if (props.layersOpened) {
        setTimeout(() => {
          setIsForeground(true);
        }, 200);
      } else {
        setIsForeground(false);
      }
    }
    prevLayersOpenedRef.current = props.layersOpened;
  }, [props.layersOpened]);

  useEffect(() => {
    if (props.layers) {
      const pointclouds: any[] = [];
      const shapefiles: any[] = [];
      props.layers.forEach((layer: any, index: number) => {
        if (layer.layerType === LayerType.OctreeBin) {
          pointclouds.push(layer);
        } else if (layer.layerType === LayerType.Shapefile) {
          console.log('Adding shapefile layer');
          shapefiles.push(layer);
        } else if (layer.layerType === LayerType.CesiumViewer) {
          setCesiumLayer(layer);
        }
      });
      setPointcloudLayers(pointclouds);
      setShapefileLayers(shapefiles);
    }
  }, [props.layers, setPointcloudLayers, setShapefileLayers]);

  useEffect(() => {
    document.addEventListener('click', () => {
      if (contextMenuLayer) {
        setContextMenuLayer(null);
      }
    });
  }, [contextMenuLayer]);

  const renderContextMenu = (layer: any, index: number) => {
    return (
      <Popup
        key={index}
        show={contextOffset !== null && contextMenuLayer === layer.id}
        offset={contextOffset}
        popupClass={'popup-content'}
      >
        <Menu
          key={index}
          vertical={true}
          style={{ display: 'inline-block' }}
          onSelect={({ item, itemId, nativeEvent, target }) => {
            if (itemId === '0') {
              setRenamingLayer(layer);
            }
          }}
        >
          <MenuItem key={1} text="Rename" />
          <MenuItem key={2} text="Delete" />
        </Menu>
      </Popup>
    );
  };

  const renderLayerElement = (layer: any, index: number) => {
    const defaultActive = layer.layerType === LayerType.CesiumViewer ? layer.active : layer.node && layer.node.visible;
    return (
      <LayerElement
        key={index}
        style={
          layer === props.selectedLayer
            ? { background: 'var(--geosap-selected)', color: 'white' }
            : { background: 'white', color: 'var(--geosap-steps-background)' }
        }
        onClick={() => {
          props.onLayerChange(layer, index);
        }}
      >
        {renderContextMenu(layer, index)}
        <div
          style={{ position: 'absolute', right: '0px', width: '12px', height: '12px', marginTop: '-8px' }}
          onClick={(e) => {
            e.stopPropagation();
            setContextOffset({ left: e.clientX, top: e.clientY });
            setContextMenuLayer(layer.id);
            return true;
          }}
        >
          <span className="k-icon k-i-more-vertical" style={{ width: '100%', height: '100%' }}></span>
        </div>
        <div className="d-flex">
          <Checkbox
            className="layers-checkbox"
            label={''}
            value={layer.layerType === LayerType.CesiumViewer ? layer.active : layer.node.visible}
            onChange={(e) => {
              props.onLayerToggle(layer, e.value);
            }}
          />

          <span
            style={{
              fontWeight: 'bold',
              paddingRight: '0.5rem',
              paddingLeft: '1rem',
              width: '85%',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
            }}
          >
            {layer.displayName}
          </span>
        </div>
        {layer.layerType !== LayerType.CesiumViewer && <span className="">Processed: 05/10/2022</span>}
        {layer.layerType !== LayerType.CesiumViewer && <span className="">Last edit: 05/10/2022</span>}
      </LayerElement>
    );
  };

  return (
    <LayersContainer className="d-flex flex-column position-relative" style={{ zIndex: isForeground ? 1 : 0 }}>
      <div
        className="d-flex px-3 py-2"
        style={{ color: 'var(--geosap-navbar-selected-item-color)', background: 'var(--geosap-steps-background)' }}
      >
        <Typography.p fontSize="small" className="mb-0">
          {props.projectTitle}
        </Typography.p>
      </div>

      <SubmitButton
        type="button"
        label="Add layer"
        uppercase={false}
        full={false}
        disabled={false}
        onClick={() => {
          setAddingLayer(true);
        }}
        loading={false}
        className="mx-3 mt-2"
      />
      <div className="d-flex flex-column px-3 py-2">
        <Typography.p fontSize="small" className="mb-0" style={{ color: 'var(--geosap-steps-background)' }}>
          Pointclouds
        </Typography.p>
        {pointcloudLayers
          ? pointcloudLayers.map((layer: any, index: number) => renderLayerElement(layer, index))
          : null}
      </div>

      {cesiumLayer && (
        <div className="d-flex flex-column px-3 py-2">
          <Typography.p fontSize="small" className="mb-0" style={{ color: 'var(--geosap-steps-background)' }}>
            Cesium
          </Typography.p>
          {renderLayerElement(cesiumLayer, 0)}
        </div>
      )}
      {shapefileLayers && shapefileLayers.length > 0 && (
        <div className="d-flex flex-column px-3 py-2">
          <Typography.p fontSize="small" className="mb-0" style={{ color: 'var(--geosap-steps-background)' }}>
            Shapefiles
          </Typography.p>
          {shapefileLayers
            ? shapefileLayers.map((layer: any, index: number) => renderLayerElement(layer, index))
            : null}
        </div>
      )}

      <ItemNodeUploadModal
        show={addingLayer}
        handleClose={() => {
          setAddingLayer(false);
        }}
        transactionId={props.transactionId}
        projectId={props.projectId}
      />
      <RenameLayerModal
        show={renamingLayer !== null}
        handleClose={() => {
          setRenamingLayer(null);
        }}
        defaultValue={renamingLayer?.displayName}
        onConfirm={(value: string) => {
          props.onRenameLayer(renamingLayer, value);
          setRenamingLayer(null);
        }}
      />
    </LayersContainer>
  );
};

export default LayersToolbar;
