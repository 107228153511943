import React, { useEffect, useState, useRef } from 'react';
import { useDraggable, Icon } from '@progress/kendo-react-common';
import { RangeSlider, Slider, SliderLabel, Checkbox } from '@progress/kendo-react-inputs';
import {
  RGBA,
  INTENSITY,
  INTENSITY_GRADIENT,
  CLASSIFICATION,
  USER_DATA,
  ELEVATION,
  HEIGHT,
  DEFAULT_ATTRIBUTE_VALUES,
  NUMBER_RETURNS,
  RETURN_NUMBER,
  SOURCE_ID,
  POINT_SOURCE_ID,
} from './SupportedAttributes';
import ClassificationToggle from './ClassificationToggle';

// To avoid transpiler error on window.Potree  https://stackoverflow.com/questions/56457935/typescript-error-property-x-does-not-exist-on-type-window
declare const window: any;
const Potree = window.Potree;

export enum PotreePanel {
  APPEARANCE,
  TOOLS,
  SCENE,
  FILTERS,
  ABOUT,
}

interface AttributeConfigProps {
  visible: boolean;
  attribute: string;
  pointcloud: any;
  onClose: any;
  viewer: any;
}

const AttributeConfigWindow: React.FC<AttributeConfigProps> = (props: AttributeConfigProps) => {
  const [position, setPosition] = React.useState({
    x: 240,
    y: 480,
  });
  const [visible, setVisible] = React.useState(false);
  const [pressed, setPressed] = React.useState(false);
  const [dragged, setDragged] = React.useState(false);
  const [initial, setInitial] = React.useState(null);
  const titleBar = React.useRef(null);
  const handlePress = React.useCallback(() => {
    setPressed(true);
  }, []);
  const handleDragStart = React.useCallback(
    (event) => {
      setDragged(true);
      setInitial({
        x: event.clientX - position.x,
        y: event.clientY - position.y,
      });
    },
    [position.x, position.y]
  );
  const handleDrag = React.useCallback(
    (event) => {
      if (!initial) {
        return;
      }

      setPosition({
        x: event.clientX - initial.x,
        y: event.clientY - initial.y,
      });
    },
    [initial]
  );
  const handleDragEnd = React.useCallback(() => {
    setDragged(false);
    setInitial(null);
  }, []);
  const handleRelease = React.useCallback(() => {
    setPressed(false);
  }, []);

  useEffect(() => {
    setVisible(props.visible && props.attribute !== null && props.attribute !== undefined);
  }, [props.visible, props.attribute]);

  useDraggable(titleBar, {
    onPress: handlePress,
    onDragStart: handleDragStart,
    onDrag: handleDrag,
    onDragEnd: handleDragEnd,
    onRelease: handleRelease,
  });

  const renderAttributeSettings = () => {
    if (!props.attribute || !props.pointcloud || (!props.pointcloud.material && props.attribute !== HEIGHT)) {
      return null;
    }
    /*****RGBA*****/
    if (props.attribute === RGBA) {
      const gamma = props.pointcloud.material.rgbGamma;
      const brightness = props.pointcloud.material.rgbBrightness;
      const contrast = props.pointcloud.material.rgbContrast;
      return (
        <div className="flex-column d-flex m-2">
          <span>Gamma</span>
          <Slider
            min={0}
            max={4}
            step={0.01}
            defaultValue={gamma}
            onChange={(e) => {
              props.pointcloud.material.rgbGamma = e.value;
            }}
          ></Slider>
          <span>Brightness</span>
          <Slider
            min={-1}
            max={1}
            step={0.01}
            defaultValue={brightness}
            onChange={(e) => {
              props.pointcloud.material.rgbBrightness = e.value;
            }}
          ></Slider>
          <span>Contrast</span>
          <Slider
            min={-1}
            max={1}
            step={0.01}
            defaultValue={contrast}
            onChange={(e) => {
              props.pointcloud.material.rgbContrast = e.value;
            }}
          ></Slider>
        </div>
      );
    } else if (props.attribute === INTENSITY) {
      /*****INTENSITY*****/
      const intensityAttribute = props.pointcloud.getAttribute('intensity');
      const [min, max] = intensityAttribute.range;
      const [rangeStart, rangeEnd] = props.pointcloud.material.intensityRange;
      const gamma = props.pointcloud.material.intensityGamma;
      const brightness = props.pointcloud.material.intensityBrightness;
      const contrast = props.pointcloud.material.intensityContrast;
      return (
        <div className="flex-column d-flex m-2">
          <span>
            Range: {min.toFixed(2)} to {max.toFixed(2)}
          </span>
          <RangeSlider
            key={props.attribute} //https://stackoverflow.com/questions/35792275/how-to-force-remounting-on-react-components
            min={min}
            max={max}
            step={0.01}
            defaultValue={{ start: rangeStart, end: rangeEnd }}
            onChange={(e: any) => {
              props.pointcloud.material.intensityRange = [e.value.start, e.value.end];
            }}
          ></RangeSlider>
          <span>Gamma</span>
          <Slider
            min={0}
            max={4}
            step={0.01}
            defaultValue={gamma}
            onChange={(e) => {
              props.pointcloud.material.intensityGamma = e.value;
            }}
          ></Slider>
          <span>Brightness</span>
          <Slider
            min={-1}
            max={1}
            step={0.01}
            defaultValue={brightness}
            onChange={(e) => {
              props.pointcloud.material.intensityBrightness = e.value;
            }}
          ></Slider>
          <span>Contrast</span>
          <Slider
            min={-1}
            max={1}
            step={0.01}
            defaultValue={contrast}
            onChange={(e) => {
              props.pointcloud.material.intensityContrast = e.value;
            }}
          ></Slider>
        </div>
      );
    } else if (props.attribute === ELEVATION) {
      /*****ELEVATION*****/
      const aPosition = props.pointcloud.getAttribute('position');

      let bMin, bMax;

      if (aPosition) {
        // for new format 2.0 and loader that contain precomputed min/max of attributes
        const min = aPosition.range[0][2];
        const max = aPosition.range[1][2];
        const width = max - min;

        bMin = min - 0.2 * width;
        bMax = max + 0.2 * width;
      } else {
        // for format up until exlusive 2.0
        let box = [props.pointcloud.pcoGeometry.tightBoundingBox, props.pointcloud.getBoundingBoxWorld()].find(
          (v) => v !== undefined
        );

        props.pointcloud.updateMatrixWorld(true);
        box = Potree.Utils.computeTransformedBoundingBox(box, props.pointcloud.matrixWorld);

        const bWidth = box.max.z - box.min.z;
        bMin = box.min.z - 0.2 * bWidth;
        bMax = box.max.z + 0.2 * bWidth;
      }
      const [min, max] = props.pointcloud.material.elevationRange;
      return (
        <div className="flex-column d-flex m-2">
          <span>
            Range: {min.toFixed(2)} to {max.toFixed(2)}
          </span>
          <RangeSlider
            key={props.attribute} //https://stackoverflow.com/questions/35792275/how-to-force-remounting-on-react-components
            min={bMin}
            max={bMax}
            step={0.01}
            defaultValue={{ start: props.pointcloud.material.heightMin, end: props.pointcloud.material.heightMax }}
            onChange={(e: any) => {
              props.pointcloud.material.heightMin = e.value.start;
              props.pointcloud.material.heightMax = e.value.end;
            }}
          ></RangeSlider>
        </div>
      );
    } else if (props.attribute === HEIGHT) {
      return (
        <div className="flex-column d-flex m-2">
          <span>
            Range: {0} to {100}
          </span>
          <Slider
            min={-200}
            max={300}
            step={0.01}
            defaultValue={0}
            onChange={(e: any) => {
              props.pointcloud.position.set(props.pointcloud.position.x, props.pointcloud.position.y, e.value);
            }}
          ></Slider>
        </div>
      );
    } else if (props.attribute === CLASSIFICATION) {
      const classificationAttribute = props.pointcloud.getAttribute('classification');
      let classificationIds = [2, 3, 4, 5, 6];
      if (classificationAttribute.distinctValues && classificationAttribute.distinctValues.length > 0) {
        classificationIds = classificationAttribute.distinctValues;
      }
      return (
        <div className="d-flex flex-column m-2">
          {classificationIds.map((id, index) => {
            const classificationData = DEFAULT_ATTRIBUTE_VALUES['classification'][id];
            return (
              <ClassificationToggle
                key={id}
                index={id}
                classificationData={classificationData}
                onVisibilityChange={(visible: boolean) => {
                  props.viewer.setClassificationVisibility(id, visible);
                }}
                onColorChange={(color: any) => {
                  if (!Potree.ClassificationScheme.DEFAULT[id]) {
                    Potree.ClassificationScheme.DEFAULT[id] = {
                      visible: true,
                      name: classificationData.name,
                      color: [color.r / 255, color.g / 255, color.b / 255, 1],
                    };
                    props.viewer.classifications.DEFAULT[id] = {
                      visible: true,
                      name: classificationData.name,
                      color: [color.r / 255, color.g / 255, color.b / 255, 1],
                    };
                  } else {
                    Potree.ClassificationScheme.DEFAULT[id].color = [color.r / 255, color.g / 255, color.b / 255, 1];
                    props.viewer.classifications.DEFAULT[id].color = [color.r / 255, color.g / 255, color.b / 255, 1];
                  }
                }}
              />
            );
          })}
        </div>
      );
    } else if (props.attribute === NUMBER_RETURNS) {
      return null;
    } else if (props.attribute === RETURN_NUMBER) {
      return null;
    } else if ([SOURCE_ID, POINT_SOURCE_ID].includes(props.attribute)) {
      return null;
    } else {
      const customAttribute = props.pointcloud.getAttribute(props.attribute);
      if (customAttribute.distinctValues && customAttribute.distinctValues.length > 0) {
        return (
          <div className="d-flex flex-column m-2">
            {customAttribute.distinctValues.map((id: any, index: any) => {
              let classificationData: any = null;
              try {
                classificationData = DEFAULT_ATTRIBUTE_VALUES[props.attribute][id];
              } catch (e) {
                classificationData = { name: 'UNKNOWN' };
              }
              if (!classificationData) {
                classificationData = { name: 'UNKNOWN' };
              }
              return (
                <ClassificationToggle
                  key={id}
                  index={id}
                  classificationData={classificationData}
                  onVisibilityChange={(visible: boolean) => {
                    props.viewer.setClassificationVisibility(id, visible, props.attribute);
                  }}
                  onColorChange={(color: any) => {
                    if (!Potree.ClassificationScheme.DEFAULT[id]) {
                      props.viewer.classifications[props.attribute][id] = {
                        visible: true,
                        name: classificationData.name,
                        color: [color.r / 255, color.g / 255, color.b / 255, 1],
                      };
                    } else {
                      props.viewer.classifications[props.attribute][id].color = [
                        color.r / 255,
                        color.g / 255,
                        color.b / 255,
                        1,
                      ];
                    }
                  }}
                />
              );
            })}
          </div>
        );
      } else {
        const [min, max] = customAttribute.range;

        let selectedRange = props.pointcloud.material.getRange(customAttribute.name);

        if (!selectedRange) {
          selectedRange = [...customAttribute.range];
        }
        const [rangeStart, rangeEnd] = selectedRange;

        const minMaxAreNumbers = typeof min === 'number' && typeof max === 'number';
        if (minMaxAreNumbers) {
          return (
            <div className="d-flex flex-column m-2">
              <span>
                Range: {Math.floor(rangeStart)} to {Math.floor(rangeEnd)}
              </span>
              <RangeSlider
                key={props.attribute}
                min={min}
                max={max}
                step={1}
                defaultValue={{ start: rangeStart, end: rangeEnd }}
                onChange={(e: any) => {
                  props.pointcloud.material.setRange(props.attribute, [e.value.start, e.value.end]);
                }}
              ></RangeSlider>
            </div>
          );
        } else {
          return null;
        }
      }
    }
  };

  return (
    <div
      id="profile_window"
      style={{
        position: 'absolute',
        width: '300px',
        left: position.x,
        top: position.y,
        margin: '5px',
        border: '1px solid black',
        boxSizing: 'border-box',
        zIndex: 10000,
        display: visible ? 'block' : 'none',
      }}
    >
      <div
        ref={titleBar}
        id="profile_titlebar"
        className="pv-titlebar px-2"
        style={{
          display: 'flex',
          height: '30px',
          width: '100%',
          boxSizing: 'border-box',
          cursor: 'grab',
          position: 'relative',
        }}
      >
        <span style={{ paddingRight: '10px' }}>Attribute configuration</span>
        <span
          className="k-icon k-i-close"
          style={{ position: 'absolute', top: '0.25rem', right: '0.25rem', fontSize: '1.2rem', cursor: 'pointer' }}
          onClick={props.onClose}
        ></span>
      </div>
      <div
        style={{
          width: '100%',
          boxSizing: 'border-box',
        }}
      >
        {renderAttributeSettings()}
      </div>
    </div>
  );
};

export default AttributeConfigWindow;
