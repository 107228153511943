import { useQuery } from 'react-query';
import { SapFlowConfig } from '../../types';
import { useAxiosInstance } from '../common/';

const endpoint = '/sap_config/get_configs_by_category_and_type';

const sorter = (configA: SapFlowConfig, configB: SapFlowConfig): number => {
  if (configA.name < configB.name) {
    return -1;
  }
  if (configB.name < configA.name) {
    return 1;
  }
  return 0;
};

const useCategories = (categoryName: string, typeName: string) => {
  const axios = useAxiosInstance();
  const params = { catname: categoryName, typename: typeName };
  return useQuery(['sapFlowConfigs', categoryName, typeName], async () => {
    const result = await axios.get<SapFlowConfig[]>(endpoint, { params });
    return result.data.sort(sorter);
  });
};

export default useCategories;
