import React, { useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Typography } from '@progress/kendo-react-common';
import { Category } from '../../types';
import { REQUIRED_FIELD } from '../../common/constants';
import { Select, SubmitButton } from '../../components/form';
import { useConsumeSapFlowWizardState } from '../../context/sapFlowWizard';
import { useCategories } from '../../hooks/sapflow';
import TransitionAnimation from './TransitionAnimation';

declare const window: any;

interface FormValues {
  projectType: Category | null;
}

const validationSchema = yup.object({
  projectType: yup.object().required(REQUIRED_FIELD),
});

const SelectProjectTypeStep: React.FC = () => {
  const { handleSubmit, control, formState, reset } = useForm<FormValues>({
    defaultValues: {
      projectType: null,
    },
    resolver: yupResolver(validationSchema),
  });
  const { dispatch, projectName, category } = useConsumeSapFlowWizardState();
  const { data, isLoading } = useCategories();

  const selectProjectType = ({ projectType }: FormValues) => {
    window.dataLayer.push({
      event: 'Run SAPflow',
      step: '2',
      stepName: 'Workflow Selection',
      projectName: projectName,
      workflowType: projectType.name,
    });
    dispatch({ type: 'SELECT_PROJECT_TYPE', payload: { category: projectType } });
  };

  useEffect(() => {
    reset({
      projectType: category,
    });
  }, [category]);

  return (
    <TransitionAnimation className="col-xs-12 col-lg-6 col-xl-4">
      <>
        <Typography.h2 fontWeight="light">What type of project is this?</Typography.h2>
        <form onSubmit={handleSubmit(selectProjectType)}>
          <Controller
            control={control}
            name="projectType"
            render={({ field, fieldState: { error } }) => (
              <Select
                name={field.name}
                value={field.value}
                error={error?.message}
                data={data}
                dataItemKey="id"
                textField="name"
                placeholder="Select Workflow Type"
                loading={isLoading}
                autoFocus={true}
                onChange={field.onChange}
                onBlur={field.onBlur}
              />
            )}
          />
          <div className="pt-3">
            <SubmitButton label="Select" uppercase={false} full={false} disabled={!formState.isDirty && !category} />
          </div>
        </form>
      </>
    </TransitionAnimation>
  );
};

export default SelectProjectTypeStep;
