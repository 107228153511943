const useGeolocation = () => {
  function geolocationAvailable() {
    return 'geolocation' in navigator;
  }

  function getCurrentPosition() {
    return new Promise((resolve, reject) => {
      try {
        navigator.geolocation.getCurrentPosition((position) => {
          resolve(position.coords);
        });
      } catch (e) {
        console.error(e);
        reject(e);
      }
    });
  }

  return {
    geolocationAvailable,
    getCurrentPosition,
  };
};

export default useGeolocation;
