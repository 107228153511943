import React, { useEffect, useState } from 'react';
import { Fade } from '@progress/kendo-react-animation';

interface Props {
  children: JSX.Element;
  className?: string;
}

const TransitionAnimation: React.FC<Props> = ({ children, className }) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    setTimeout(() => setShow(true), 100);
  }, []);

  return <Fade className={className ? className : 'w-100'}>{show && <div className="p-1">{children}</div>}</Fade>;
};

export default TransitionAnimation;
