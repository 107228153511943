import { useQuery } from 'react-query';
import { SapFlowTransaction } from '../../types';
import { useAxiosInstance } from '../common/';

const endpoint = 'sapflow/get_sapflow';

const useTransaction = (transactionId: string, refetchInterval: number | false = false) => {
  const axios = useAxiosInstance();

  return useQuery(
    ['transaction', transactionId],
    async () => {
      const params = { sapflowid: transactionId };
      const result = await axios.get<SapFlowTransaction>(endpoint, { params, data: {} });
      return result.data;
    },
    { refetchInterval }
  );
};

export default useTransaction;
