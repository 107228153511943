import { Category, SapFlow, SapFlowConfig, SapFlowType, SapFlowProject } from '../../types';
import { SapFlowWizardState, initialState, SapFlowWizardStep } from './sapFlowWizardState';

export interface SelectProjectAction {
  type: 'SELECT_PROJECT';
  payload: {
    project: SapFlowProject;
    projectName?: string;
  };
}

export interface CreateProjectAction {
  type: 'CREATE_PROJECT';
  payload: {
    projectName: string;
  };
}

export interface SelectProjectTypeAction {
  type: 'SELECT_PROJECT_TYPE';
  payload: {
    category: Category;
  };
}

export interface SelectProcessTypeAction {
  type: 'SELECT_PROCESS_TYPE';
  payload: {
    sapFlowType: SapFlowType;
  };
}

export interface SelectSapFlowAction {
  type: 'SELECT_SAPFLOW';
  payload: {
    sapFlowConfig?: SapFlowConfig;
    sapFlow?: SapFlow;
    project?: SapFlowProject;
  };
}

export interface ProvideDetailsAction {
  type: 'PROVIDE_DETAILS';
  payload: {
    processDescription?: string;
    projectTags?: string[] | null;
  };
}

export interface UploadFilesAction {
  type: 'UPLOAD_FILES';
}

export interface NavigateAction {
  type: 'NAVIGATE';
  payload: {
    step: SapFlowWizardStep;
  };
}

export type SapFlowWizardAction =
  | SelectProjectAction
  | CreateProjectAction
  | SelectProjectTypeAction
  | SelectProcessTypeAction
  | UploadFilesAction
  | SelectSapFlowAction
  | ProvideDetailsAction
  | NavigateAction;

export const sapFlowWizardReducer = (
  currentState: SapFlowWizardState,
  action: SapFlowWizardAction
): SapFlowWizardState => {
  switch (action.type) {
    case 'SELECT_PROJECT':
      return {
        ...currentState,
        project: action.payload.project,
        projectName: action.payload.projectName ? action.payload.projectName : null,
        step: SapFlowWizardStep.SELECT_PROJECT_TYPE,
      };
    case 'CREATE_PROJECT':
      return {
        ...currentState,
        project: null,
        projectName: action.payload.projectName,
        step: SapFlowWizardStep.SELECT_PROJECT_TYPE,
      };
    case 'SELECT_PROJECT_TYPE':
      return {
        ...currentState,
        category: action.payload.category,
        step: SapFlowWizardStep.SELECT_PROCESS_TYPE,
      };
    case 'SELECT_PROCESS_TYPE':
      return {
        ...currentState,
        sapFlowType: action.payload.sapFlowType,
        step: SapFlowWizardStep.SELECT_SAPFLOW,
      };
    case 'SELECT_SAPFLOW':
      return {
        ...currentState,
        sapFlowConfig: action.payload.sapFlowConfig,
        sapFlow: action.payload.sapFlow || currentState.sapFlow,
        project: action.payload.project || currentState.project,
        step: SapFlowWizardStep.PROVIDE_DETAILS,
      };
    case 'PROVIDE_DETAILS': {
      return {
        ...currentState,
        processDescription: action.payload.processDescription,
        step: SapFlowWizardStep.UPLOAD,
      };
    }
    case 'UPLOAD_FILES': {
      return {
        ...initialState,
      };
    }
    case 'NAVIGATE': {
      return {
        ...currentState,
        step: action.payload.step,
      };
    }
  }
};
