import React, { useEffect, useState } from 'react';
import { Typography } from '@progress/kendo-react-common';
import { Skeleton } from '@progress/kendo-react-indicators';
import { ProjectInfo, SapFlowProject, FileDetails } from '../../types';
import { useUser } from '../../hooks/authentication';
import { toProjectInfo } from '../../converters/projectInfo';

interface Props {
  isLoading?: boolean;
  isSuccess?: boolean;
  projectData: SapFlowProject;
  inputFileData: FileDetails[];
  outputFileData: FileDetails[];
}

const displayRows: [keyof ProjectInfo, string][] = [
  ['name', 'Project Title'],
  ['description', 'Description'],
  //['typeName', 'Project Type'], //
  ['lastEdited', 'Last Edited'],
  ['ownerName', 'Owner'],
  //['coordinateSystem', 'Coordinate System'], //
  ['numberOfFiles', 'Number of Files'],
  ['filesUploaded', 'Files Uploaded'],
  //['typesOfDeliverables', 'Types of Deliverables'], //
  ['creditsUsed', 'Credits Used'],
  ['totalProcessingTime', 'Total Processing Time'],
];

const ProjectInfoSection: React.FC<Props> = ({ isLoading, isSuccess, projectData }) => {
  const [projectInfo, setProjectInfo] = useState<ProjectInfo | undefined>();
  const { getCurrentOrganization } = useUser();

  useEffect(() => {
    setProjectInfo(isSuccess ? toProjectInfo(projectData, getCurrentOrganization()) : undefined);
  }, [isSuccess, projectData]);

  return (
    <div>
      <Typography.h4 fontWeight="normal" className="text-uppercase">
        Project Info
      </Typography.h4>
      {isLoading && new Array(11).fill(<Skeleton shape="text" />).map((item, index) => <div key={index}>{item}</div>)}
      {isSuccess && projectInfo && (
        <ul style={{ fontSize: '0.9rem' }} className="mt-3">
          {displayRows.map(([field, title]) => (
            <li className="my-1" key={field}>
              <span className="fw-bold">{title}: </span>
              <span>{projectInfo[field]}</span>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default ProjectInfoSection;
