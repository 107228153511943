import { useState } from 'react';
import { useQueryClient } from 'react-query';
import { ActivationState, CompatClient, IFrame, IMessage, Stomp } from '@stomp/stompjs';
import { useUser } from '../authentication';
import { WSMessage, WSMessageHeader, EMsgDestinationType, EMsgType } from '../../types';
import { useAppContext } from '../../context/app';


const stompURL = process.env.REACT_APP_WEBSOCKET_SERVERURL + '/messaging'
// const stompURL = 'ws://geosapserver.whiteoutsolutions.com:8080/api/messaging';

let stompClient: CompatClient;
let connected = false;
const topicSubscriptions: any = {};
const topicHandlers: any = {};

const useWebsocketClient = () => {
  const { getUser, isAuthenticated } = useUser();
  const { dispatch } = useAppContext();
  const queryClient = useQueryClient();
  const [hookIsConnected, setHookIsConnected] = useState<boolean>(false); // This is a patch to trigger component refresh upon websocket connection.

  function isConnected() {
    return connected;
  }

  function initWebsocketClient() {
    console.log('Websocket connecting ...');

    stompClient = Stomp.client(stompURL);
    /* tslint:disable:no-empty */
    stompClient.debug = () => undefined; // This disables the Stomp debug logs.

    let authToken = '';
    let headers;

    if (isAuthenticated()) {
      const { tokenType, accessToken } = getUser();
      headers = { Authorization: tokenType + ' ' + accessToken };
      authToken = String('Authorization: ' + tokenType + ': ' + accessToken);
    }

    stompClient.reconnect_delay = 5000;

    //stompClient.beforeConnect = handleBeforeConnect;
    stompClient.connect('', '', handleConnect, handleError, handleCloseEvent, headers);
  }

  function handleCloseEvent(closeEvnt: CloseEvent) {
    connected = false;
    setHookIsConnected(false);
  }

  function handleConnect(connectFrame: IFrame) {
    stompClient.onDisconnect = handleDisconnect;
    //stompClient.onreceipt = handleReceipt;
    //stompClient.onreceive = handleReceive;
    //stompClient.onChangeState = handleChangeState;

    //stompClient.subscribe('/topic', handleSecuredChat);
    connected = true;
    setHookIsConnected(true);
  }

  function handleSecuredChat(message: IMessage) {
    //console.log('Received message on topic ' + JSON.stringify(message.headers.destination));
    const messageBody = JSON.parse(message.body);
    if (messageBody.payload && messageBody.payload === 'HEARTBEAT') {
      //stompClient.publish({ destination: '/app/chat', headers: { priority: '9' }, body: 'Hello, STOMP' });
    } else if (messageBody.msgHeader.msgType === 'TRANSACTION_UPDATE') {
      queryClient.invalidateQueries(['transactions', getUser().id], { refetchInactive: true });
      //const transactionId = messageBody.payload.transactionId;
      //queryClient.invalidateQueries(['transaction', transactionId], { refetchInactive: true });

    } else if (topicHandlers[message.headers.destination] && topicHandlers[message.headers.destination].length > 0) {
      topicHandlers[message.headers.destination].forEach((handler: any, index: number) => {
        handler(messageBody);
      });
    }
  }

  //function handleBeforeConnect() {}

  //function handleChangeState(state: ActivationState) {}

  //function handleReceive(receive: IFrame) {}

  function handleDisconnect(disconnect: IFrame) {
    //console.log('Websocket Disconnected: ', disconnect);
    connected = false;
    setHookIsConnected(false);
  }

  function handleError(errorFrame: IFrame) {
    console.error('Websocket Error Occurred: ', errorFrame);
  }

  //function handleReceipt(receipt: IFrame) {}

  function closeConnection() {
    if (connected) {
      stompClient.disconnect(function () {
        //console.log('successfully disconnected websocket client from server..');
        connected = false;
      });
    }
  }

  function sendMessageToServer() {
    stompClient.publish({ destination: '/app/chat', headers: { priority: '9' }, body: 'Hello, STOMP' });
    stompClient.publish({ destination: '/topic/chat', headers: { priority: '9' }, body: 'Hello, msmma' });
  }

  function subscribe(topic: string) {
    if (!topicSubscriptions[topic]) {
      const newSubscription = stompClient.subscribe(topic, handleSecuredChat);
      topicSubscriptions[topic] = newSubscription;
    }
    return topicSubscriptions[topic];
  }

  function subscribeWithHandler(topic: string, handler: any) {
    if (!topicSubscriptions[topic]) {
      const newSubscription = stompClient.subscribe(topic, handleSecuredChat);
      topicSubscriptions[topic] = newSubscription;
    }
    if (!topicHandlers[topic]) {
      topicHandlers[topic] = [];
    }
    topicHandlers[topic].push(handler);
    return topicSubscriptions[topic];
  }

  function unsubscribe(topic: string) {
    stompClient.unsubscribe(topic);
  }

  function unsubscribeHandler(topic: string, handler: any) {
    if (topicHandlers[topic] && topicHandlers[topic].includes(handler)) {
      topicHandlers[topic] = [];
      const index = topicHandlers[topic].indexOf(handler);
      if (index > -1) {
        // only splice array when item is found
        topicHandlers[topic].splice(index, 1); // 2nd parameter means remove one item only
      }
    }
    stompClient.unsubscribe(topic);
  }

  function isSubscribedTo(topic: string) {
    return topicSubscriptions[topic] && topicSubscriptions[topic].length > 0;
  }

  function publish(topic: string, message: string) {
    //console.log('Sending message to server.');
    stompClient.publish({ destination: topic, headers: { priority: '9' }, body: message });
  }

  return {
    initWebsocketClient,
    sendMessageToServer,
    closeConnection,
    isConnected,
    subscribe,
    subscribeWithHandler,
    unsubscribe,
    unsubscribeHandler,
    isSubscribedTo,
    publish,
  };
};

export default useWebsocketClient;
