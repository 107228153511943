import { useQuery } from 'react-query';
import { SapFlowViewConfig, LayerType } from '../../types';
import { useAxiosInstance } from '../common';

const endpoint = 'parameters/sapflow_params';

const useSapFlowParams = (transactionId: string) => {
  const axios = useAxiosInstance();
  return useQuery(['sapFlowParams', transactionId], async () => {
    const params = {
      sapflowid: transactionId,
    };
    const result = await axios.get<SapFlowViewConfig>(endpoint, { params });
    return result.data;
  });
};

export default useSapFlowParams;
