import { FileDetails } from '../../types';
import { useQuery } from 'react-query';
import { useAxiosInstance } from '../common/';

const useTransactionFileDetails = () => {
  const endpoint = 'file_manager/trans_input_details';
  const getInputFileDetails = (transId: string) => {
    const axios = useAxiosInstance();
    return useQuery(
      ['sapflowInputFileDetails', transId],
      async () => {
        const params = { trans_id: transId };
        const result = await axios.get<FileDetails[]>(endpoint, { params });
        return result.data;
      },
      { retry: false }
    );
  };

  const getOutputFileDetails = (transId: string) => {
    const endpoint = 'file_manager/trans_output_details';
    const axios = useAxiosInstance();
    return useQuery(
      ['sapflowOutputFileDetails', transId],
      async () => {
        const params = { trans_id: transId };
        const result = await axios.get<FileDetails[]>(endpoint, { params });
        return result.data;
      },
      { retry: false }
    );
  };

  return { getInputFileDetails, getOutputFileDetails };
};

export default useTransactionFileDetails;
