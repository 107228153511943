import React, { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { Stepper, StepProps } from '@progress/kendo-react-layout';
import { Skeleton } from '@progress/kendo-react-indicators';
import { SapFlowWizardProvider, useCreateSapFlowWizardState, SapFlowWizardStep } from '../../context/sapFlowWizard';
import { useUser } from '../../hooks/authentication';
import { useProjects } from '../../hooks/sapflow';
import CreateProjectStep from './CreateProjectStep';
import SelectProjectTypeStep from './SelectProjectTypeStep';
import SelectProcessTypeStep from './SelectProcessTypeStep';
import SelectSapFlowStep from './SelectSapFlowStep';
import ProvideDetailsStep from './ProvideDetailsStep';
import UploadStep from './UploadStep';

interface Props {
  newProject?: boolean;
}

const SapFlowWizard: React.FC<Props> = ({ newProject = false }) => {
  const wizardState = useCreateSapFlowWizardState();
  const { state: locationState } = useLocation();
  const { step, dispatch } = wizardState;
  const { projectId } = useParams();
  const { getUser } = useUser();
  const projectQuery = useProjects(getUser().id);
  const [renderStep, setRenderStep] = useState(false);

  const getItems = (): StepProps[] => {
    return [
      { label: 'Project' },
      { label: 'Workflow type', disabled: step < 1 },
      { label: 'Process type', disabled: step < 2 },
      { label: 'SapFlow', disabled: step < 3 },
      { label: 'Details', disabled: step < 4 },
      { label: 'Upload', disabled: step < 5 },
    ];
  };

  const handleStepChange = (event: { value: number }) => {
    dispatch({ type: 'NAVIGATE', payload: { step: event.value } });
  };

  useEffect(() => {
    const navigationState: any = locationState;
    if (navigationState && navigationState.sapflow) {
      // SapFlow Wizard was provided a SapFlow to complete
      dispatch({
        type: 'SELECT_SAPFLOW',
        payload: { sapFlow: navigationState.sapflow, project: navigationState.sapflow.project },
      });
      dispatch({
        type: 'PROVIDE_DETAILS',
        payload: { processDescription: '' },
      });
    }
  }, [locationState]);

  useEffect(() => {
    if (projectQuery.isSuccess) {
      setRenderStep(true);
    }
    if (projectId && projectQuery.isSuccess) {
      const selectedProject = projectQuery.data.find((p) => p.id === projectId);
      if (selectedProject) {
        dispatch({ type: 'SELECT_PROJECT', payload: { project: selectedProject } });
      }
    }
  }, [projectId, projectQuery.isSuccess]);

  return (
    <SapFlowWizardProvider value={wizardState}>
      <div className="d-flex flex-column h-100">
        <div className="d-flex flex-column justify-content-start align-items-center" style={{ paddingTop: '8rem' }}>
          <Stepper className="w-75" value={step} items={getItems()} onChange={handleStepChange} />
        </div>
        <div className="container">
          <div className="row align-items-center justify-content-center" style={{ paddingTop: '4rem' }}>
            {projectQuery.isLoading && (
              <div className="col-xs-12 col-lg-6 col-xl-4">
                <Skeleton shape="rectangle" className="me-5" style={{ width: '85%', height: '40px' }} />
                <Skeleton shape="rectangle" className="w-100 mt-3" style={{ height: '60px' }} />
                <Skeleton shape="rectangle" className="w-50 mt-4" style={{ height: '50px' }} />
              </div>
            )}

            {renderStep &&
              {
                [SapFlowWizardStep.CREATE_PROJECT]: (
                  <CreateProjectStep newProject={newProject} hasProjects={!!projectQuery.data?.length} />
                ),
                [SapFlowWizardStep.SELECT_PROJECT_TYPE]: <SelectProjectTypeStep />,
                [SapFlowWizardStep.SELECT_PROCESS_TYPE]: <SelectProcessTypeStep />,
                [SapFlowWizardStep.SELECT_SAPFLOW]: <SelectSapFlowStep />,
                [SapFlowWizardStep.PROVIDE_DETAILS]: <ProvideDetailsStep />,
                [SapFlowWizardStep.UPLOAD]: <UploadStep />,
              }[step]}
          </div>
        </div>
      </div>
    </SapFlowWizardProvider>
  );
};

export default SapFlowWizard;
