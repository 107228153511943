import axios, { AxiosInstance } from 'axios';
import { AppNotification } from '../../types';
import { useAppContext } from '../../context/app';
import { useUser } from '../authentication';
import useAuthorization from './useAuthorization';

let axiosInstance: AxiosInstance = null;

const createInstance = () => {
  const { getUser, isAuthenticated, getCurrentOrganization } = useUser();
  const { dispatch } = useAppContext();
  const { addAuthorizationHeader } = useAuthorization();

  const newAxiosInstance = axios.create({
    baseURL: process.env.REACT_APP_GEOSAP_SERVERURL,
    headers: {
      'Content-Type': 'application/json',
    },
  });

  newAxiosInstance.interceptors.request.use((config) => {
    dispatch({ type: 'CLEAR_NOTIFICATION' });

    if (isAuthenticated()) {
      addAuthorizationHeader(config.headers, getUser());
    }

    // TODO: When we change axios for a real singleton, this will need rework.
    if (getCurrentOrganization() !== null && getCurrentOrganization() !== undefined) {
      config.headers.Organization = `${getCurrentOrganization().id}`;
    }
    return config;
  });

  newAxiosInstance.interceptors.response.use(undefined, (error) => {
    let errorMessage = 'An unexpected error happened';
    if (!navigator.onLine) {
      errorMessage = "Couldn't detect network connection. Please check your internet.";
    } else if (!error.response) {
      errorMessage = "Couldn't reach GeoSAP servers. Please try again later.";
    } else if (error.response.data) {
      errorMessage = error.response.data.message;
    }
    const notification: AppNotification = { type: 'error', content: errorMessage };
    dispatch({ type: 'SHOW_NOTIFICATION', payload: { notification } });
    return Promise.reject(error);
  });

  return newAxiosInstance;
};

const useAxiosInstance = () => {
  //if (axiosInstance === null) {
  axiosInstance = createInstance();
  //}
  return axiosInstance;
};

export default useAxiosInstance;
