import { User, AppNotification, Organization } from '../../types';
import { AppState } from './appState';

export interface LoginAction {
  type: 'LOGIN';
  payload: {
    user: User;
    selectedOrganization: Organization;
  };
}

export interface LogoutAction {
  type: 'LOGOUT';
}

export interface SignupAction {
  type: 'SIGNUP';
  payload: {
    email: string;
  };
}

export interface ShowNotification {
  type: 'SHOW_NOTIFICATION';
  payload: {
    notification: AppNotification;
  };
}

export interface ClearNotification {
  type: 'CLEAR_NOTIFICATION';
}

export interface SelectOrganizationNotification {
  type: 'SELECT_ORGANIZATION';
  payload: {
    organization: Organization;
  };
}

export type AppAction =
  | LoginAction
  | LogoutAction
  | SignupAction
  | ShowNotification
  | ClearNotification
  | SelectOrganizationNotification;

export const appReducer = (currentState: AppState, action: AppAction): AppState => {
  switch (action.type) {
    case 'LOGIN':
      return {
        ...currentState,
        user: action.payload.user,
        availableOrganizations: action.payload.user.organizations,
        selectedOrganization: action.payload.selectedOrganization,
        recentlyCreatedUser: null,
      };
    case 'LOGOUT':
      return {
        ...currentState,
        user: null,
        selectedOrganization: null,
        availableOrganizations: [],
      };
    case 'SIGNUP':
      return {
        ...currentState,
        recentlyCreatedUser: action.payload.email,
      };
    case 'SHOW_NOTIFICATION':
      return {
        ...currentState,
        notification: action.payload.notification,
      };
    case 'CLEAR_NOTIFICATION':
      return {
        ...currentState,
        notification: null,
      };
    case 'SELECT_ORGANIZATION':
      return {
        ...currentState,
        selectedOrganization: action.payload.organization,
      };
  }
};
