import { SapFlow, SapFlowConfig, SapFlowConfigProcessNode, SapFlowItemNode, FileFormat } from '../../types';

const useSapFlow = () => {
  const sortConfigProcessNode = (sapFlowConfig: SapFlowConfig): SapFlowConfigProcessNode[] => {
    const processnodes = sapFlowConfig.sapflow.processnodes.map((node) => ({ ...node }));
    processnodes.sort((a, b) => a.order - b.order);
    return processnodes;
  };

  const getSuppliedItems = (sapFlow: SapFlow): SapFlowItemNode[] => {
    return sapFlow.itemnodes.filter((item) => item.type === 'SUPPLIED');
  };

  const getUploadUrl = (userId: string, projectId: string, transactionId: string, itemId: string): string => {
    let params = `?userID=${userId}`;
    params += `&projectID=${projectId}`;
    params += `&TransactionID=${transactionId}`;
    params += `&itemID=${itemId}`;
    return `${process.env.REACT_APP_GEOSAP_SERVERURL}/file_manager/upload${params}`;
  };

  const getFileFormatNames = (sapFlow: SapFlow): string[] => {
    const namesMap = getSuppliedItems(sapFlow).reduce(
      (map, itemNode) => ({ ...map, [itemNode.fileformat]: itemNode.fileformat }),
      {}
    );
    return Object.keys(namesMap);
  };

  const getExtensions = (formatName: string, fileFormats: FileFormat[]) => {
    return fileFormats.find((f) => f.name === formatName).acceptedFileExtensions;
  };

  return { sortConfigProcessNode, getSuppliedItems, getUploadUrl, getFileFormatNames, getExtensions };
};

export default useSapFlow;
