import { useMutation, useQueryClient } from 'react-query';
import { SapFlow } from '../../types';
import { useAxiosInstance } from '../common/';

const endpoint = '/sapflow/create_sapflow_trans';

interface Params {
  configid: string;
  userid: string;
  projectid: string;
}

const useCreateTransaction = () => {
  const axios = useAxiosInstance();
  const queryClient = useQueryClient();
  return useMutation(
    async (params: Params) => {
      const response = await axios.post<SapFlow>(endpoint, {}, { params });
      return response.data;
    },
    {
      onSuccess: (_data, variables) => {
        queryClient.invalidateQueries(['transactions', variables.userid], { refetchInactive: true });
      },
    }
  );
};

export default useCreateTransaction;
