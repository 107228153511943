import { useMutation, useQueryClient } from 'react-query';
import { SapFlowTransactionItem } from '../../types';
import { useAxiosInstance } from '../common/';

const endpoint = '/sapflow/item';

interface Params {
  sapflowid: string;
  name: string;
  fileformat: string;
  visualize: boolean;
  userid: string;
  pc_visualize: boolean;
}

const useCreateTransactionItemNode = () => {
  const axios = useAxiosInstance();
  const queryClient = useQueryClient();
  return useMutation(
    async (params: Params) => {
      const response = await axios.post<SapFlowTransactionItem>(endpoint, {}, { params });
      return response.data;
    },
    {
      onSuccess: (_data, variables) => {
        queryClient.invalidateQueries(['transactions', variables.userid], { refetchInactive: true });
      },
    }
  );
};

export default useCreateTransactionItemNode;
