import { useQuery } from 'react-query';
import { SapFlowType } from '../../types';
import { useAxiosInstance } from '../common/';

const endpoint = '/sap_config/get_available_types_by_category';

const sorter = (sapFlowA: SapFlowType, sapFlowB: SapFlowType): number => {
  if (sapFlowA.name < sapFlowB.name) {
    return -1;
  }
  if (sapFlowB.name < sapFlowA.name) {
    return 1;
  }
  return 0;
};

const useCategories = (categoryName: string) => {
  const axios = useAxiosInstance();
  const params = { catname: categoryName };
  return useQuery(['sapFlowTypes', categoryName], async () => {
    const result = await axios.get<SapFlowType[]>(endpoint, { params });
    return result.data.sort(sorter);
  });
};

export default useCategories;
