import { useQuery, useMutation, useQueryClient } from 'react-query';
import { SapFlowViewConfig, LayerType } from '../../types';
import { useAxiosInstance } from '../common';

const endpoint = 'parameters/updated_params';

const useSapFlowParamsUpdate = () => {
  const axios = useAxiosInstance();
  const queryClient = useQueryClient();
  return useMutation(
    async (params: any) => {
      const response = await axios.post<any>(endpoint, params);
      return response.data;
    },
    {
      onSuccess: (_data, variables) => {
        queryClient.invalidateQueries(['sapFlowParams', variables.sapflowid], { refetchInactive: true });
      },
    }
  );
};

export default useSapFlowParamsUpdate;
