import {useMutation, useQuery} from 'react-query';
import { useAxiosInstance } from '../common/';
import {SapFlowTransactionItem} from "../../types/SapFlowTransaction";

const endpoint = 'sapflow/item_list';



const useListTransactionItems = (userId: string, transactionId: string) => {
    const axios = useAxiosInstance();
    console.log("TEST LOG: UserID: " + userId + " transactionId: " + transactionId);
    return useQuery(
        ['listTransaction', transactionId],
        async () => {
            const params = { user_id: userId, transaction_id: transactionId };
            const result = await axios.get<SapFlowTransactionItem[]>(endpoint, { params });
            return result.data;
        },
        { retry: false }
    );


};

export default useListTransactionItems;
