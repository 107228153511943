import { useMutation } from 'react-query';
import { SignupRequest } from '../../types';
import { useAppContext } from '../../context/app';
import { useAxiosInstance } from '../common';

const endpoint = '/authentication/signup';

const useSignup = (onSuccess: VoidFunction) => {
  const { dispatch } = useAppContext();
  const axios = useAxiosInstance();

  return useMutation((newUser: SignupRequest) => axios.post(endpoint, newUser), {
    onSuccess: (_data, variables) => {
      dispatch({ type: 'SIGNUP', payload: variables });
      dispatch({
        type: 'SHOW_NOTIFICATION',
        payload: { notification: { type: 'success', content: 'User created succcessfully' } },
      });
      onSuccess();
    },
  });
};

export default useSignup;
