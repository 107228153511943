import { Category, SapFlow, SapFlowConfig, SapFlowProject, SapFlowType } from '../../types';

export enum SapFlowWizardStep {
  CREATE_PROJECT = 0,
  SELECT_PROJECT_TYPE = 1,
  SELECT_PROCESS_TYPE = 2,
  SELECT_SAPFLOW = 3,
  PROVIDE_DETAILS = 4,
  UPLOAD = 5,
}

export interface SapFlowWizardState {
  step: SapFlowWizardStep;
  projectName: string | null;
  project: SapFlowProject | null;
  category: Category | null;
  sapFlowType: SapFlowType | null;
  sapFlowConfig: SapFlowConfig;
  sapFlow: SapFlow | null;
  processDescription: string | null;
}

export const initialState: SapFlowWizardState = {
  step: SapFlowWizardStep.CREATE_PROJECT,
  projectName: null,
  project: null,
  category: null,
  sapFlowType: null,
  sapFlowConfig: null,
  sapFlow: null,
  processDescription: null,
};
