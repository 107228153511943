import { useMutation, useQueryClient } from 'react-query';
import { SapFlowProject } from '../../types';
import { useAxiosInstance } from '../common/';

const endpoint = '/project/create_project';

interface Params {
  user_id: string;
  project_id: string;
  project_name: string;
}

const useCreateProject = () => {
  const axios = useAxiosInstance();
  const queryClient = useQueryClient();
  return useMutation(
    async (params: Params) => {
      const response = await axios.post<SapFlowProject>(endpoint, {}, { params });
      return response.data;
    },
    {
      onSuccess: (_data, variables) => {
        queryClient.invalidateQueries(['projects', variables.user_id], { refetchInactive: true });
      },
    }
  );
};

export default useCreateProject;
