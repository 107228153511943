import React, { Component } from 'react';
import { Navigate } from 'react-router-dom';

type Props = Record<string, never>;

type State = {
  content: string;
};

export default class Home extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      content: '',
    };
  }

  render() {
    return <Navigate to="/dashboard" />;
  }
}
