import { useMutation } from 'react-query';
import { useAxiosInstance } from '../common/';

const updateStatusEndpoint = 'sapflow/update_status';
const runSapFlowEndpoint = 'sapflow/run_sapflow_trans_no_wait';

interface Params {
  sapflowid: string;
  userid: string;
  projectid: string;
}

const useRunSapFlow = () => {
  const axios = useAxiosInstance();

  return useMutation(async (params: Params) => {
    await axios.put(updateStatusEndpoint, {}, { params: { sapflowid: params.sapflowid, newstatus: 'TRANSACTION_READY' } });
    return axios.post(runSapFlowEndpoint, {}, { params });
  });
};

export default useRunSapFlow;
