import React, { useEffect, useRef } from 'react';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Zoom } from '@progress/kendo-react-animation';

interface DialogProps {
  show: boolean;
  onConfirm: any;
  onClose: any;
  title: any;
  text: any;
}

const ConfirmationDialog: React.FC<DialogProps> = (props: DialogProps) => {
  return (
    <Dialog title={props.title} onClose={props.onClose}>
      <p
        style={{
          margin: '25px',
          textAlign: 'center',
        }}
      >
        {props.text}
      </p>
      <DialogActionsBar>
        <button
          className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
          onClick={props.onClose}
        >
          No
        </button>
        <button
          className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
          onClick={props.onConfirm}
        >
          Yes
        </button>
      </DialogActionsBar>
    </Dialog>
  );
};

export default ConfirmationDialog;
