import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Typography } from '@progress/kendo-react-common';
import { Skeleton } from '@progress/kendo-react-indicators';
import { useForm, Controller } from 'react-hook-form';
import Collapsible from 'react-collapsible';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { JsonEditor as Editor } from 'jsoneditor-react';
import 'jsoneditor-react/es/editor.min.css';
import { REQUIRED_FIELD } from '../../common/constants';
import { SapFlowItemNode } from '../../types';
import { useAppContext } from '../../context/app';
import { useConsumeSapFlowWizardState } from '../../context/sapFlowWizard';
import { useSapFlowParams, useSapFlowParamsUpdate } from '../../hooks/sapflow';
import { useUser } from '../../hooks/authentication';
import { Input, SubmitButton } from '../../components/form';
import TransitionAnimation from './TransitionAnimation';

declare const window: any;

interface FormValues {
  processDescription: string;
  projectTags: string[] | null;
}

const getValidationSchema = () =>
  yup.object({
    processDescription: undefined, //yup.object().required(REQUIRED_FIELD),
    projectTags: undefined,
  });

const ProvideDetailsStep = () => {
  const { getUser } = useUser();
  const { sapFlow, category, sapFlowType, project, projectName, sapFlowConfig, processDescription, dispatch } =
    useConsumeSapFlowWizardState();
  const { mutate, isLoading } = useSapFlowParamsUpdate(); //sapFlow.id);
  const navigate = useNavigate();
  const { projectId } = useParams();
  const [originalJsonConfig, setOriginialJsonConfig] = useState<any>(null);
  const [jsonConfig, setJsonConfig] = useState<any>(null);
  const sapFlowParamsQuery = useSapFlowParams(sapFlow.id);
  const [validationSchema, setValidationSchema] = useState(getValidationSchema());
  const { handleSubmit, control, formState, reset } = useForm<FormValues>({
    defaultValues: {
      processDescription: '',
      projectTags: null,
    },
    resolver: yupResolver(validationSchema),
  });
  const formHasValue = !!processDescription;

  useEffect(() => {
    setJsonConfig(sapFlowParamsQuery.data);
    setOriginialJsonConfig(sapFlowParamsQuery.data);
  }, [sapFlowParamsQuery.isLoading, sapFlowParamsQuery.isSuccess]);

  const handleSubmitClick = () => {
    mutate(jsonConfig, {
      onSuccess: () => {
        window.dataLayer.push({
          event: 'Run SAPflow',
          step: '5',
          stepName: 'SAPFlow Details',
          projectName: projectName,
          workflowType: category.name,
          processType: sapFlowType.name,
          sapFlowType: sapFlowConfig.name,
          paramsChanged: jsonConfig !== originalJsonConfig,
          sapFlowId: sapFlow.id,
        });
        dispatch({
          type: 'PROVIDE_DETAILS',
          payload: { processDescription: processDescription },
        });
      },
    });
  };

  const handleJSONChange = (newJson: string): void => {
    console.log('JSON: ' + JSON.stringify(newJson));
    setJsonConfig(newJson);
    return;
  };

  return (
    <TransitionAnimation className="col-xs-12 col-lg-8 col-xl-6">
      <>
        <Typography.h2 fontWeight="light" textAlign="center">
          Provide us with some details
        </Typography.h2>
        <div>
          {/*<Controller
            control={control}
            name="processDescription"
            render={({ field, fieldState: { error } }) => (
              <Input {...field} error={error?.message} type="text" placeholder="Process Description" autoFocus />
            )}
          />*/}
          <Typography.h4 className="mt-4" fontWeight="light" textAlign="center">
            Parameters
          </Typography.h4>
          <Collapsible
            trigger={<Typography.p>+ Advanced SAPFlow Settings</Typography.p>}
            triggerWhenOpen={<Typography.p>- Advanced SAPFlow Settings</Typography.p>}
          >
            {sapFlowParamsQuery.isLoading && (
              <Skeleton shape="rectangle" style={{ width: '100%', height: '74px' }} className="my-3" />
            )}
            {sapFlowParamsQuery.isSuccess && jsonConfig && (
              <Editor
                value={jsonConfig}
                onChange={handleJSONChange}
                schema={undefined}
                mode="text"
                allowedModes={['tree', 'text']}
              />
            )}
          </Collapsible>
          <div className="pt-3">
            <SubmitButton
              type="button"
              label="Submit details"
              uppercase={false}
              full={false}
              disabled={false /*!formState.isDirty  && !formHasValue*/}
              onClick={handleSubmitClick}
              loading={isLoading}
            />
          </div>
        </div>
      </>
    </TransitionAnimation>
  );
};

export default ProvideDetailsStep;
